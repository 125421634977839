import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  devices: [],
  loading: false,
  error: null,
}

export const wsDevicesSlice = createSlice({
  name: 'devices',
  initialState,
  reducers: {
    resetDevices: () => initialState,
    requestDevices: (state) => {
      state.loading = true
    },
    receiveDevices: (state, action) => {
      state.loading = false
      state.devices = action.payload
      state.error = null
    },
    updateDevices: (state) => {
      state.loading = true
    },
    devicesUpdated: (state, action) => {
      state.loading = false
      state.devices = action.payload
      state.error = null
    },
  },
})

export const {
  resetDevices,
  requestDevices,
  receiveDevices,
  updateDevices,
  devicesUpdated,
} = wsDevicesSlice.actions

export default wsDevicesSlice.reducer
