import React, { useEffect } from 'react'
import { Box, Typography, CircularProgress } from '@mui/material'

const LiveIndicator = ({ live }) => {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress variant={live ? 'indeterminate' : 'determinate'} />
      <Box
        sx={{
          top: 2,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant="caption" component="div" color="text.secondary">
          {live ? 'Live' : 'Stale'}
        </Typography>
      </Box>
    </Box>
  )
}

export default LiveIndicator
