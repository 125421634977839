import React, { useState, useContext } from 'react'
import { useSelector } from 'react-redux'
import { useAppContext } from '../../../context/AppProvider'
import {
  Button,
  TextField,
  Autocomplete,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import { useGetSites } from '../../../hooks'

const MoveDialog = ({ device, setMove, move, siteId }) => {
  useGetSites()

  const { ws } = useAppContext()
  const { sites } = useSelector((state) => state.wsSites)
  const [value, setValue] = useState(sites[0])
  const [inputValue, setInputValue] = useState('')

  const assignToSite = () => {
    ws.assignDevicesToSite([device], value._id, siteId)
    setMove(false)
  }

  return (
    <Dialog open={move} onClose={() => setMove(false)}>
      <DialogTitle>Move {device.name}...</DialogTitle>
      <DialogContent>
        {
          sites.length
            ? <Autocomplete
              value={value}
              onChange={(event, newValue) => {
                setValue(newValue)
              }}
              inputValue={inputValue}
              onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue)
              }}
              options={sites}
              getOptionLabel={(option) => option.name}
              sx={{ width: 300 }}
              renderInput={(params) => (
                <TextField {...params} label='Pick new site..' />
              )}
            />
            : null
        }
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setMove(false)}>Cancel</Button>
        <Button onClick={assignToSite}>Assign</Button>
      </DialogActions>
    </Dialog>
  )
}

export default MoveDialog