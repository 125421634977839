import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import {
  Box,
  Tab
} from '@mui/material'

import {
  TabPanel,
  TabList,
  TabContext
} from '@mui/lab'
import BidirectionalTopology from '../Components/Maps/Topology/BidirectionalTopology'
import HierarchicalTopology from '../Components/Maps/Topology/HierarchicalTopology'
import { useAppContext } from '../context/AppProvider'





const TopologyScreen = () => {
  const { ws } = useAppContext()
  const { topo } = useSelector((state) => state.wsTopo)
  const [value, setValue] = React.useState('1')

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  useEffect(() => {
    ws.fetchTopo()
    return () => ws.leaveTopo()
  }, [])

  return (
    <TabContext value={value}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <TabList onChange={handleChange} aria-label="lab API tabs example">
          <Tab label="Bidirectional" value="1" />
          <Tab label="Hierarchical" value="2" />
        </TabList>
      </Box>
      <TabPanel value="1">
        <BidirectionalTopology topo={topo} />
      </TabPanel>
      <TabPanel value="2">
        <HierarchicalTopology topo={topo} />
      </TabPanel>
    </TabContext>


  )
}

export default TopologyScreen