import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  data: null,
  loading: false,
  error: null,
}

export const wsHistoricalDataSlice = createSlice({
  name: 'data',
  initialState,
  reducers: {
    resetHistoricalData: () => initialState,
    requestHistoricalData: state => {
      state.loading = true
    },
    receiveHistoricalData: (state, action) => {
      state.loading = false
      state.data = action.payload
      state.error = null
    }
  }
})

export const { resetHistoricalData, requestHistoricalData, receiveHistoricalData } = wsHistoricalDataSlice.actions

export default wsHistoricalDataSlice.reducer