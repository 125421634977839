import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import {
  AppBar,
  Box,
  Toolbar,
  Typography,
  Breadcrumbs,
  Button,
  CircularProgress,
} from '@mui/material'
import LiveIndicator from '../../Core/LiveIndicator'

const WirelessBar = ({ ap, sta, site, live }) => {
  useEffect(() => {}, [ap, sta, site])

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar variant="dense">
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              style={{ textDecoration: 'inherit', color: 'inherit' }}
              to={'/'}
            >
              Home
            </Link>
            <Link
              style={{ textDecoration: 'inherit', color: 'inherit' }}
              to={'/network/sites'}
            >
              Sites
            </Link>
            <Link
              style={{ textDecoration: 'inherit', color: 'inherit' }}
              to={`/network/site/${site[0]?._id}`}
            >
              <Typography>{site[0]?.name}</Typography>
            </Link>
            <Link
              style={{ textDecoration: 'inherit', color: 'inherit' }}
              to={`/network/device/${ap?._id}`}
            >
              <Typography>{ap?.name}</Typography>
            </Link>
          </Breadcrumbs>
          <Box sx={{ flexGrow: 1 }}></Box>
          {sta?.location?.lat && (
            <Link
              style={{ textDecoration: 'inherit', color: 'inherit' }}
              to={`/tools/apfinder/${sta.location.lat}/${sta.location.long}`}
            >
              <Button variant="contained">Verify LOS</Button>
            </Link>
          )}
          <LiveIndicator live={live} />
        </Toolbar>
      </AppBar>
    </Box>
  )
}

export default WirelessBar
