import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  subscriber: {},
  loading: false,
  error: null,
}

export const subscriberSlice = createSlice({
  name: 'subscriber',
  initialState,
  reducers: {
    resetSubscriber: () => initialState,
    requestSubscriber: (state) => {
      state.loading = true
    },
    receiveSubscriber: (state, action) => {
      state.loading = false
      state.subscriber = action.payload
      state.error = null
    }
  },
})

export const { resetSubscriber, requestSubscriber, receiveSubscriber } =
  subscriberSlice.actions

export default subscriberSlice.reducer
