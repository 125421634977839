import { Avatar, Divider, List, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useGetDispatchables } from '../hooks'
import Moment from 'react-moment'
import { generateUrl } from '../utils/generateUrl'
import { Link } from 'react-router-dom'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'


const DispatchablesScreen = () => {

  useGetDispatchables()

  const { dispatchables } = useSelector((state) => state.dispatchables)


  const dispatchableColumns = [
    {
      headerName: 'Site',
      field: 'site.name',
      minWidth: 150,
      flex: 1,
      valueGetter: (params) => (params.row?.site.name),
      renderCell: (params) => {
        return (
          <Link
            style={{ textDecoration: 'inherit', color: 'inherit' }}
            to={`/network/site/${params.row.site._id}`}>
            {params.row?.site.name}
          </Link>
        )
      }
    },
    {
      headerName: 'Name',
      field: 'name',
      minWidth: 150,
      flex: 1,
      renderCell: (params) => {
        return (
          <a
            style={{ textDecoration: 'inherit', color: 'inherit' }}
            target='_blank'
            rel="noreferrer"
            href={`https://ideatek.sonar.software/app#/accounts/show/${params.row?.subscriber?.sonarId}`}>
            {params.row?.subscriber?.name}
          </a>
        )
      }
    },
    {
      headerName: 'Service',
      field: 'services',
      minWidth: 100,
      flex: 1,
      valueGetter: (params) => (params.row?.subscriber?.services?.find((s) => s.serviceType === 'DATA')?.name),
      renderCell: (params) => {
        const dataService = params.row?.subscriber?.services?.find((s) => s.serviceType === 'DATA')
        return (
          <a
            style={{ textDecoration: 'inherit', color: 'inherit' }}
            target='_blank'
            rel="noreferrer"
            href={`https://ideatek.sonar.software/app#/accounts/show/${params.row?.subscriber?.sonarId}`}>
            {dataService?.name || 'No Data Service'}
          </a>
        )
      }
    },
    {
      headerName: 'CPE',
      field: 'cpe',
      minWidth: 75,
      flex: .5,
      valueGetter: (params) => (params.row?.model),
      renderCell: (params) => {
        return (
          <Link
            style={{ textDecoration: 'inherit', color: 'inherit' }}
            to={generateUrl(params.row)}>
            {params.row?.model}
          </Link>
        )
      },
    },
    {
      headerName: 'Signal',
      field: 'wireless.signal',
      minWidth: 75,
      flex: .5,
      valueGetter: (params) => (params.row?.wireless?.signal),
    },

    {
      headerName: 'Signal Delta',
      field: 'wireless.signalDelta',
      minWidth: 75,
      flex: .5,
      valueGetter: (params) => (params.row?.wireless?.expectedSignal - params.row?.wireless?.signal),
    },
    {
      headerName: 'Revenue',
      field: 'mrr',
      type: "number",
      minWidth: 100,
      flex: .25,
      valueGetter: (params) => {
        return (params.row?.subscriber?.mrr / 100).toFixed(2)
      },
    },
    {
      headerName: 'Activation Date',
      field: 'activationDate',
      minWidth: 100,
      flex: .25,
      valueGetter: (params) => (params.row?.subscriber?.activationDate),
      renderCell: (params) => {
        return <Moment format='YYYY/MM/DD'>{params.row?.subscriber?.activationDate}</Moment>
      }
    },
  ]



  return (
    <>
      {dispatchables.length > 0 && (
        <DataGrid
          components={{
            Toolbar: GridToolbar,
          }}
          initialState={{
            sorting: {
              sortModel: [{ field: 'site.name', sort: 'asc' }],
            }
          }}
          autoHeight
          rowHeight={30}
          getRowId={(row) => row._id}
          rows={dispatchables}
          columns={dispatchableColumns}
          pageSize={50}
        />
      )}
    </>
  )
}

export default DispatchablesScreen