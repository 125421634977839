import { saveAs } from 'file-saver'
import { receiveEvents } from '../../reduxSlices/wsEventsSlice'
import { receiveSonar } from '../../reduxSlices/wsSonarSlice'
import { receiveSites } from '../../reduxSlices/wsSitesSlice'
import { devicesUpdated } from '../../reduxSlices/wsDevicesSlice'
import { receiveDevices } from '../../reduxSlices/wsDevicesSlice'
import { receiveDevice } from '../../reduxSlices/wsDeviceSlice'
import { receiveDataLinks } from '../../reduxSlices/dataLinkSlice'
import { receiveNoiseData } from '../../reduxSlices/wsNoiseDataSlice'
import { receiveHistoricalData } from '../../reduxSlices/wsHistoricalDataSlice'
import { receiveStaTunnel } from '../../reduxSlices/wsStaTunnelSlice'
import { receiveApTunnel } from '../../reduxSlices/wsApTunnelSlice'
import { receiveUsers } from '../../reduxSlices/wsUsersSlice'
import { receiveSite } from '../../reduxSlices/wsSiteSlice'
import { receiveTopo, resetTopo } from '../../reduxSlices/wsTopoSlice'
import { receiveSearch } from '../../reduxSlices/wsSearchSlice'
import { receiveBestAps } from '../../reduxSlices/wsBestApsSlice'
import { receiveNotification } from '../../reduxSlices/wsNotifySlice'
import {
  receiveRoomInfo,
  resetRoomInfo,
} from '../../reduxSlices/wsRoomInfoSlice'
import { receiveActiveSockets } from '../../reduxSlices/wsActiveSocketsSlice'
import { receiveServerStatus } from '../../reduxSlices/wsServerStatusSlice'
import { receiveUser } from '../../reduxSlices/userSlice'
import { receiveSubscriber } from '../../reduxSlices/subscriberSlice'
import { receiveDispatchables } from '../../reduxSlices/dispatchablesSlice'
import { receiveMbsSearch } from '../../reduxSlices/wsMbsSearchSlice'

export const setupSocketEventListeners = (socket, dispatch, ws) => {
  socket.on('connect_error', (error) => {
    console.log(`connect_error: ${JSON.stringify(error.message)}`)
    if (error.message === 'updateClient') {
      setTimeout(() => window.location.reload(true), 6000)
      dispatch(
        receiveNotification({
          severity: 'error',
          message: 'Client is outdated. All active SG tabs will reload.',
        })
      )
    }
  })

  socket.on('disconnect', (reason) => {
    console.log(`disconnect: ${reason}`)
    if (reason === 'io server disconnect') {
      // the disconnection was initiated by the server, you need to reconnect manually
      socket.connect()
    }
    // else the socket will automatically try to reconnect
  })

  socket.on('event://receive-users', (data) => {
    console.log('receive-users')
    dispatch(receiveUsers(data))
  })
  socket.on('event://receive-user', (data) => {
    dispatch(receiveUser(data))
  })
  socket.on('event://receive-customer-info', (data) => {
    dispatch(receiveSonar(data))
  })
  socket.on('event://receive-mbs-search', (data) => {
    dispatch(receiveMbsSearch(data))
  })
  socket.on('event://router-added', (data) => {
    ws.fetchSite(data)
  })
  socket.on('event://devices-assigned', (data, siteId) => {
    dispatch(devicesUpdated(data))
    ws.fetchSite(siteId)
  })
  socket.on('event://devices-removed', (data, siteId) => {
    dispatch(devicesUpdated(data))
    ws.fetchSite(siteId)
  })
  socket.on('event://receive-sites', (data) => {
    dispatch(receiveSites(JSON.parse(new TextDecoder().decode(data))))
  })
  socket.on('event://receive-site', (data) => {
    dispatch(receiveSite(data))
  })
  socket.on('event://receive-devices', (data) => {
    dispatch(receiveDevices(data))
  })
  socket.on('event://receive-search', (data) => {
    dispatch(receiveSearch(data))
  })
  // socket.on('event://receive-device', (data) => {
  //   dispatch(receiveDevice(data))
  // })
  socket.on('event://receive-device', (data) => {
    dispatch(receiveDevice(data))
  })
  socket.on('event://receive-noise-data', (data) => {
    dispatch(receiveNoiseData(data))
  })
  socket.on('event://receive-device-history', (data) => {
    dispatch(receiveHistoricalData(data))
  })
  socket.on('event://receive-sta-tunnel', (data) => {
    const payload = data
    dispatch(receiveStaTunnel(payload))
  })
  socket.on('event://receive-ap-tunnel', (data) => {
    const payload = data
    dispatch(receiveApTunnel(payload))
  })
  socket.on('event://receive-datalinks', (data) => {
    dispatch(receiveDataLinks(data))
  })
  socket.on('event://receive-sites-export', (data) => {
    const csv = new Blob([data], { type: 'text/plain;charset=utf-8' })
    const date = new Date()
    saveAs(
      csv,
      `${new Date(
        date.getTime() - date.getTimezoneOffset() * 60000
      ).toISOString()}-sites-export.csv`
    )
  })
  socket.on('event://receive-site-subscriber-export', (data) => {
    const csv = new Blob([data], { type: 'text/plain;charset=utf-8' })
    const date = new Date()
    saveAs(
      csv,
      `${new Date(
        date.getTime() - date.getTimezoneOffset() * 60000
      ).toISOString()}-site-subscriber-export.csv`
    )
  })
  socket.on('event://receive-best-aps', (data) => {
    dispatch(receiveBestAps(data))
  })
  socket.on('event://receive-network-graph-v2', (data) => {
    dispatch(receiveTopo(data))
  })
  socket.on('left://topology', () => {
    dispatch(resetTopo())
    dispatch(resetRoomInfo())
  })
  socket.on('event://notify', (data) => {
    dispatch(receiveNotification(data))
  })
  socket.on('event://update-room-info', (data) => {
    dispatch(receiveRoomInfo({ users: data }))
  })
  socket.on('event://update-active-sockets', (data) => {
    dispatch(receiveActiveSockets(data))
  })
  socket.on('event://receive-events', (data) => {
    dispatch(receiveEvents(data))
  })
  socket.on('event://receive-server-status', (data) => {
    dispatch(receiveServerStatus(data))
  })
  socket.on('event://receive-subscriber', (data) => {
    dispatch(receiveSubscriber(data))
  })
  socket.on('event://receive-dispatchables', (data) => {
    dispatch(receiveDispatchables(data))
  })
}
