import React, { useEffect, useState, useMemo } from 'react'
import { Button, Grid, Box } from '@mui/material'
import VisGraph from 'react-vis-graph-wrapper'

const setEdgeColor = (direction, active, expired) => {
  if (expired) return active ? '#EF4B56' : 'purple'
  if (active) return direction === 'in' ? '#23C766' : 'cyan'
  return 'grey'
}

const HierarchicalTopology = ({ topo }) => {
  const [layout, setLayout] = useState(true)

  const { nodes, edges } = useMemo(() => {
    const newNodes = topo.nodes.map(({ id, name, active }) => ({
      id,
      label: name,
      color: active ? '#23C766' : '#EF4B56',
    })).concat({ id: 'WWW', label: 'Fiber', color: 'cyan', active: true, fixed: true })

    const newEdges = topo.edges
      .filter(edge => edge.direction === 'in' && !edge.expired && edge.active)
      .map(({ active, expired, direction, to, from, throughput, capacity }) => ({
        active,
        expired,
        direction,
        to,
        from,
        label: Math.round((throughput / (capacity || 1)) * 100),
        value: Math.round((throughput / (capacity || 1)) * 100),
        color: setEdgeColor(direction, active, expired),
      }))

    return { nodes: newNodes, edges: newEdges }
  }, [topo])

  const options = useMemo(() => ({
    physics: false,
    layout: {
      hierarchical: {
        direction: layout ? 'UD' : 'LR',
        sortMethod: 'directed',
        shakeTowards: 'roots',
        edgeMinimization: false,
        parentCentralization: false,
      },
    },
    nodes: {
      shape: 'dot',
      font: { color: 'white' },
    },
    edges: {
      smooth: true,
    },
  }), [layout])

  return (
    <Grid container direction="column">
      <Grid item xs>
        <Button variant="contained" onClick={() => setLayout(!layout)}>Rotate</Button>
        <Box sx={{ position: 'fixed', p: 1, height: '100vh' }}>
          {nodes.length > 0 && (
            <VisGraph
              graph={{ nodes, edges }}
              options={options}
            />
          )}
        </Box>
      </Grid>
    </Grid>
  )
}

export default HierarchicalTopology