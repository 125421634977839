import React, { useState } from 'react'
import {
  Box,
  Stepper,
  Step,
  StepLabel,
  Button,
  TextField,
  Typography,
  Switch,
  ListItemText,
  ListItem,
  List,
  ListItemButton,
} from '@mui/material'

import { useAppContext } from '../../../context/AppProvider'
import { useSelector } from 'react-redux'
import { Check, NotInterested } from '@mui/icons-material'
import SubInfoCard from '../../Subscriber/SubInfoCard'

const AssignStepper = ({ setAssign, device }) => {
  const { ws } = useAppContext()
  const [subscriber, setSubscriber] = useState(null)
  const [service, setService] = useState(null)
  const [backbone, setBackbone] = useState(
    device.backbone ? device.backbone : false
  )
  const [activeStep, setActiveStep] = useState(0)
  const [searchTerm, setSearchTerm] = useState('')

  const { results, loading } = useSelector((state) => state.wsMbsSearch)
  const { services, arps } = useSelector((state) => state.wsDevice)

  const handleSubSelection = (sub) => {
    if (
      sub.subscriberId === subscriber?.subscriberId ||
      services.find(
        (svc) =>
          svc?.subscriberDetails?.mbs_sub_id === Number(sub?.subscriberId)
      )
    ) {
      if (subscriber) {
        if (subscriber?.subscriberId === sub.subscriberId) {
          return true
        } else return false
      } else {
        return true
      }
    }
  }

  const handleAssignment = () => {
    if (backbone) {
      ws.assignDevicesToSubscriber([device], 'backbone')
      setAssign(false)
      return
    } else {
      const payload = {
        ...subscriber,
        service: service,
      }
      ws.assignDevicesToSubscriber([device], payload)
      setAssign(false)
      return
    }
  }

  const initSearch = async () => {
    ws.fetchMbsSearch(searchTerm)
  }

  const steps = [
    {
      label: 'Review Assignment',
      contents: <SubInfoCard services={services} arps={arps} />,
    },
    {
      label: 'Select Assignment',
      contents: (
        <>
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Typography variant="body1">Assign to Backbone</Typography>
            <Box sx={{ flex: '1 1 auto' }} />
            <Switch
              checked={backbone}
              onChange={(e) => setBackbone(e.target.checked)}
            />
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <TextField
              sx={{ maxWidth: '300px', mr: 2 }}
              disabled={backbone}
              variant="standard"
              id="search-terms"
              label="Name or Account Number"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <Box sx={{ flex: '1 1 auto' }} />
            <Button disabled={backbone} onClick={initSearch}>
              Search
            </Button>
          </Box>
          {loading ? (
            <Typography>Loading...</Typography>
          ) : (
            <List>
              {results.map((sub) => (
                <div key={sub.subscriberId}>
                  <ListItemButton
                    selected={handleSubSelection(sub)}
                    onClick={() => setSubscriber(sub)}
                  >
                    <ListItemText
                      primary={`${sub.customerName}`}
                      secondary={
                        <>
                          <Typography component="span" variant="body2">
                            ID: {sub.subscriberId}
                          </Typography>
                          <br />
                          <Typography component="span" variant="body2">
                            {sub.subscriberType === 'R'
                              ? 'Residential'
                              : 'Commercial'}
                          </Typography>
                        </>
                      }
                    />
                  </ListItemButton>
                </div>
              ))}
            </List>
          )}
        </>
      ),
    },
    {
      label: 'Select Service',
      contents: (
        <List component="div" disablePadding>
          {subscriber &&
            subscriber?.services.map((svc) => (
              <ListItem
                key={svc.serviceId}
                sx={{ pl: 4 }}
                button
                selected={svc.id === service?.id}
                onClick={() => setService(svc)}
                secondaryAction={
                  svc.status === 'Active' ? <Check /> : <NotInterested />
                }
              >
                <ListItemText
                  primary={svc.accountName}
                  secondary={svc.serviceTypeDescription}
                />
                <List component="div" disablePadding>
                  {svc.charges.map((charge, index) => {
                    if (charge.chargeSubType === 'SVC') {
                      return (
                        <ListItem key={index} sx={{ pl: 4 }}>
                          <ListItemText
                            primary={`${charge.description}`}
                            secondary={`Price: ${charge.price}`}
                          />
                        </ListItem>
                      )
                    }
                  })}
                </List>
              </ListItem>
            ))}
        </List>
      ),
    },
  ]

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((step) => (
          <Step key={step.label}>
            <StepLabel>{step.label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      {activeStep === steps.length ? (
        <React.Fragment>
          <Typography sx={{ mt: 2, mb: 1 }}>
            All steps completed - you&apos;re finished
          </Typography>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Typography sx={{ mt: 2, mb: 1 }}>
            {steps[activeStep].contents}
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Button
              variant={'contained'}
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              Back
            </Button>
            <Box sx={{ flex: '1 1 auto' }} />

            {activeStep === steps.length - 1 || backbone ? (
              <Button variant={'contained'} onClick={handleAssignment}>
                Assign
              </Button>
            ) : (
              <Button variant={'contained'} onClick={handleNext}>
                Next
              </Button>
            )}
          </Box>
        </React.Fragment>
      )}
    </Box>
  )
}

export default AssignStepper
