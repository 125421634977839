import { useState, useEffect } from "react"
import moment from 'moment'
import {
  AreaChart,
  Area,
  Tooltip,
  ResponsiveContainer,
  ReferenceArea,
  XAxis,
  CartesianGrid,
  Legend,
  YAxis,
  Line,
  ComposedChart,
  Bar,
} from "recharts"


const ZoomableAreaChart = ({ historicalData, graphs }) => {

  useEffect(() => {
    setData(historicalData)
  }, [historicalData])

  const [data, setData] = useState(historicalData)
  const [left, setLeft] = useState("dataMin")
  const [right, setRight] = useState("dataMax")
  const [refAreaLeft, setRefAreaLeft] = useState("")
  const [refAreaRight, setRefAreaRight] = useState("")

  const tickCounter = (tickCount) => {
    const ticks = [];
    const totalElements = () => {
      const start = left === 'dataMin' ? 0 : data.findIndex((el) => el.epoch === left);
      const end = right === 'dataMax' ? (data.length - 1) : data.findIndex((el) => el.epoch === right);
      return data.slice(start, end + 1); // Ensure the end element is included
    };
    const elements = totalElements();
    const delta = Math.max(1, Math.floor(elements.length / tickCount)); // Ensure delta is at least 1
    for (let i = 0; i < elements.length; i += delta) {
      ticks.push(elements[i].epoch);
      if (ticks.length >= tickCount) break; // Prevent adding more ticks than requested
    }
    // Ensure the last element is always included, adjusting for unique epochs
    if (elements.length > 1 && ticks[ticks.length - 1] !== elements[elements.length - 1].epoch) {
      ticks[ticks.length - 1] = elements[elements.length - 1].epoch;
    }
    return ticks;
  };

  const zoom = () => {

    if (refAreaLeft === refAreaRight || refAreaRight === "") {
      setRefAreaLeft("")
      setRefAreaRight("")
      return
    }

    // xAxis domain
    if (refAreaLeft > refAreaRight) {
      setRefAreaLeft(refAreaRight)
      setRefAreaRight(refAreaLeft)
    }

    setRefAreaLeft("")
    setRefAreaRight("")
    setData(data.slice())
    setLeft(refAreaLeft)
    setRight(refAreaRight)
  }

  const zoomOut = () => {
    setData(data.slice())
    setRefAreaLeft("")
    setRefAreaRight("")
    setLeft("dataMin")
    setRight("dataMax")
  }

  const range = (start, stop, step) =>
    Array.from(
      { length: (stop - start) / step + 1 },
      (_, i) => start + i * step
    )

  return (
    <>
      <button
        className="btn update"
        onClick={zoomOut}
      >
        Zoom Out
      </button>
      {
        graphs.map((graph) => (
          <ResponsiveContainer key={graph.title} width="100%" height={150}>
            <ComposedChart
              onMouseDown={(e) => { setRefAreaLeft(e.activeLabel) }}
              onMouseMove={(e) => { setRefAreaRight(e.activeLabel) }}
              onMouseUp={zoom}
              data={data}
              margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
            >
              <CartesianGrid stroke="#eee" strokeDasharray="1 5" />
              <Legend />
              {
                graph.title === 'signal' && <YAxis yAxisId="1" reversed={false} domain={[-90, -20]} ticks={range(-85, -35, 5)} />
              }
              {
                graph.title === 'Scores' && <YAxis yAxisId="1" domain={[0, 100]} />
              }
              {
                graph.title === 'Utilization' && <YAxis yAxisId="1" domain={[0, 100]} />
              }
              {
                graph.title === 'Download' && <YAxis yAxisId="1" domain={[0, 100]} />
              }
              {
                graph.title === 'Upload' && <YAxis yAxisId="1" domain={[0, 100]} />
              }
              <XAxis
                dataKey='epoch'
                allowDataOverflow
                domain={[left, right]}
                ticks={tickCounter(10)}
                tickFormatter={(unixTime) => moment(unixTime).format('LT')}
              />
              {
                graph?.dataPoints?.map((el, i) => {
                  switch (el.type) {
                    case 'area': return (
                      <Area
                        key={i}
                        yAxisId="1"
                        type="monotone"
                        unit={el.unit}
                        name={el.name}
                        dataKey={el.dataKey}
                        stroke={el.color}
                        fill={el.color}
                        fillOpacity={el.name === "Signal" || el.name === "Expected Signal" ? 0 : 0.4}
                      />
                    )
                    case 'line': return (
                      <Line
                        dot={false}
                        key={i}
                        yAxisId="1"
                        type="monotone"
                        unit={el.unit}
                        name={el.name}
                        dataKey={el.dataKey}
                        stroke={el.color}
                      />
                    )
                    case 'bar': return (
                      <Bar
                        key={i}
                        yAxisId="1"
                        type="monotone"
                        unit={el.unit}
                        name={el.name}
                        dataKey={el.dataKey}
                        stroke={el.color}
                      />
                    )
                  }
                })
              }
              {refAreaLeft && refAreaRight ? (
                <ReferenceArea
                  yAxisId="1"
                  x1={refAreaLeft}
                  x2={refAreaRight}
                  strokeOpacity={0.3}
                />
              ) : null}
              <Tooltip
                labelFormatter={(unixTime) => moment(unixTime).format('lll')}
                contentStyle={{
                  backgroundColor: "#121212",
                  color: "#fff",
                }}
                itemStyle={{ color: "#fff" }}
                cursor={false}
              />
            </ComposedChart>
          </ResponsiveContainer>
        ))
      }
    </>
  )
}

export default ZoomableAreaChart

