import React, { useState } from 'react'
import GoogleLogin from 'react-google-login'
import { refreshTokenSetup } from '../../utils/refreshToken'
import { useNavigate, useLocation } from 'react-router-dom'
import { useAppContext } from '../../context/AppProvider'
import axios from 'axios'
import {
  Avatar,
  Button,
  TextField,
  Box,
  Typography,
  Container,
} from '@mui/material'
import GoogleIcon from '@mui/icons-material/Google'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import { useTheme } from '@mui/system'



const Login = () => {
  const theme = useTheme()
  let navigate = useNavigate()
  let { state } = useLocation()
  const { user, findUser, isLoading, authFailure } = useAppContext()
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')

  const handleBasicLoginSubmit = async (e) => {
    e.preventDefault()
    try {
      const response = await axios.post('/api/auth/login', {
        email: username,
        password,
      })
      await findUser()
    } catch (error) {
      console.error('Login error:', error)
    }
  }

  const handleLoginSuccess = async (googleData) => {
    try {
      const response = await axios.post('/api/auth/googlelogin', {
        token: googleData.tokenId,
      })
      refreshTokenSetup(googleData)
      await findUser()
    } catch (error) {
      console.error('Google login error:', error)
    }
  }

  const handleLoginFail = (googleData) => {
    console.error('Google login failed:', googleData)
  }

  if (user) {
    navigate(state.destination, { replace: true })
  }
  if (isLoading) {
    return null
  }

  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: `calc(100vh - ${theme.mixins.toolbar.minHeight}px)`,
    },
    loginBox: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      p: 3,
      bgcolor: 'background.paper',
      boxShadow: 3,
      borderRadius: '8px',
    },
  }

  return (
    <Container component="main" maxWidth="xs" sx={styles.container}>
      <Box sx={styles.loginBox}>
        <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography variant="h4" align="center" sx={{ mt: 8, mb: 4 }}>
          Sign in
        </Typography>
        <GoogleLogin
          clientId={
            '789148250331-fhe1tv002rhv6qroo2o33t8dl3qg4qr9.apps.googleusercontent.com'
          }
          buttonText="Log in with Google"
          onSuccess={handleLoginSuccess}
          onFailure={handleLoginFail}
          cookiePolicy={'single_host_origin'}
          render={(renderProps) => (
            <Button
              startIcon={<GoogleIcon />}
              onClick={renderProps.onClick}
              disabled={renderProps.disabled}
              variant="contained"
              color="primary"
              fullWidth
              size={'large'}
              sx={{ textTransform: 'none' }}
            >  Sign in with Google
            </Button>
          )}
        />
        <Box
          component="form"
          onSubmit={handleBasicLoginSubmit}
          sx={{ width: '100%', mt: 2 }}
        >
          <TextField
            fullWidth
            margin="normal"
            label="Username"
            variant="outlined"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <TextField
            fullWidth
            margin="normal"
            label="Password"
            variant="outlined"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button
            fullWidth
            type="submit"
            variant="contained"
            color="primary"
            sx={{ mt: 2, mb: 4 }}
          >
            Log in
          </Button>
        </Box>
      </Box>
    </Container>
  )
}

export default Login