import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  staTunnel: null,
  loading: false,
  error: null,
}

export const wsStaTunnelSlice = createSlice({
  name: 'tunnel',
  initialState,
  reducers: {
    resetStaTunnel: () => initialState,
    requestStaTunnel: state => {
      state.loading = true
    },
    receiveStaTunnel: (state, action) => {
      state.loading = false
      state.staTunnel = action.payload
      state.error = null
    },
    
  }
})

export const { resetStaTunnel, requestStaTunnel, receiveStaTunnel } =  wsStaTunnelSlice.actions

export default wsStaTunnelSlice.reducer