import React, { useState } from 'react'
import { Netmask } from 'netmask'
import { FormControl, Grid, IconButton, InputLabel, Select, TextField, MenuItem } from "@mui/material"
import DeleteIcon from '@mui/icons-material/Delete'

const IpAddressForm = ({ routerAddresses, setRouterAddresses, routerInterfaces, address, addressIndex }) => {

  const [ipError, setIpError] = useState(false)

  const handleAddresses = (e, index, network) => {
    const { name, value } = e.target
    const list = [...routerAddresses]
    list[index][name] = value

    if (network) {
      console.log(network)
      list[index]['subnet'] = network
    }
    setRouterAddresses(list)
  }
  // handle remove Address
  const handleRemoveAddress = (index) => {
    const list = [...routerAddresses]
    list.splice(index, 1)
    setRouterAddresses(list)
  }

  return (
    <Grid item container justifyContent={'space-between'}>
      <Grid item xs sx={{ p: 1 }}>
        <FormControl fullWidth size="small">
          <InputLabel>Interface</InputLabel>
          <Select
            name='interface'
            value={address.interface}
            onChange={(e) => handleAddresses(e, addressIndex)}>
            {routerInterfaces?.map((i) => {
              return (
                <MenuItem key={i} value={i.name}>
                  {i.name}
                </MenuItem>
              )
            })}
          </Select>
        </FormControl>
      </Grid>
      <Grid item md sx={{ p: 1 }}>
        <FormControl fullWidth size="small">
          <InputLabel>Address Type</InputLabel>
          <Select
            name='type'
            value={address.type}
            onChange={(e) => handleAddresses(e, addressIndex)}>
            <MenuItem value={'ipv4'}>{'IPv4'}</MenuItem>
            <MenuItem value={'ipv6'}>{'IPv6'}</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs sx={{ p: 1 }}>
        <TextField
          error={ipError}
          variant="filled"
          size="small"
          autoComplete='off'
          name='ip'
          fullWidth
          label={'IP Address'}
          value={address.ip}
          onChange={((e) => {
            try {
              setIpError(false)
              let network = new Netmask(e.target.value)
              handleAddresses(e, addressIndex, network)
            } catch (err) {
              console.error(err)
              setIpError(true)
              handleAddresses(e, addressIndex)
            }
          })}
        />
      </Grid>
      <Grid item md sx={{ p: 1 }}>
        <TextField
          size="small"
          fullWidth
          autoComplete='off'
          name='comment'
          label={'Comment'}
          type='text'
          variant="filled"
          value={address.comment}
          onChange={(e) => handleAddresses(e, addressIndex)}
        />
      </Grid>
      <Grid item>
        <IconButton
          aria-label='delete'
          onClick={() => handleRemoveAddress(addressIndex)}>
          <DeleteIcon />
        </IconButton>
      </Grid>
    </Grid>
  )
}

export default IpAddressForm