import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  results: [],
  loading: false,
  error: null,
}

export const wsSearchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    resetSearch: () => initialState,
    requestSearch: (state) => {
      state.loading = true
    },
    receiveSearch: (state, action) => {
      state.loading = false
      state.results = action.payload
      state.error = null
    },
  },
})

export const { resetSearch, requestSearch, receiveSearch } =
  wsSearchSlice.actions

export default wsSearchSlice.reducer
