import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import {
  AppBar,
  Box,
  Toolbar,
  Typography,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Breadcrumbs,
  InputAdornment,
  IconButton,
} from '@mui/material'
import { useAppContext } from '../../../context/AppProvider'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import NewDeviceDialog from './NewDeviceDialog'

const SiteBar = ({ site }) => {

  const { ws } = useAppContext()

  const [device, setDevice] = useState({})

  const [open, setOpen] = useState(false)
  const [dialogOpen, setDialogOpen] = useState(false)

  const [edit, setEdit] = useState(false)
  const [name, setName] = useState(site.name)


  const [mrc, setMrc] = useState(site?.mrc)
  const [category, setCategory] = useState(site?.category)

  const [line1, setLine1] = useState(site?.address?.line1 ? site?.address?.line1 : '')
  const [line2, setLine2] = useState(site?.address?.line2 ? site?.address?.line2 : '')
  const [city, setCity] = useState(site?.address?.city ? site?.address?.city : '')
  const [zip, setZip] = useState(site?.address?.zip ? site?.address?.zip : '')
  const [state, setState] = useState(site?.address?.state ? site?.address?.state : '')

  const [electricName, setElectricName] = useState(site?.electricCompany?.name)
  const [electricPhoneNumber, setElectricPhoneNumber] = useState(site?.electricCompany?.phoneNumber)
  const [meter, setMeter] = useState(site?.electricCompany?.meter)

  const [escortName, setEscortName] = useState(site?.escort?.name)
  const [escortPhoneNumber, setEscortPhoneNumber] = useState(site?.escort?.phoneNumber)
  const [escortEmail, setEscortEmail] = useState(site?.escort?.email)

  const [lockCode, setLockCode] = useState(site?.lockCode)
  const [keyLocation, setKeyLocation] = useState(site?.keyLocation)

  const [lat, setLat] = useState(site?.location?.lat)
  const [lon, setLon] = useState(site?.location?.long)
  const [height, setHeight] = useState(site?.location?.height)
  const [loopback, setLoopBack] = useState(null)

  const [showPassword, setShowPassword] = useState(false)

  const handleDeviceSubmit = (device) => {
    setDevice(device)
    ws.addDevice(device)
  }


  const handleEdit = () => {
    axios.put(`/api/sites/${site._id}`, {
      name,
      mrc: Number(mrc),
      category,
      address: {
        line1,
        line2,
        city,
        zip,
        state,
      },
      electricCompany: {
        name: electricName,
        phoneNumber: electricPhoneNumber,
        meter,
      },
      escort: {
        name: escortName,
        phoneNumber: escortPhoneNumber,
        email: escortEmail
      },
      lockCode,
      keyLocation,
      location: {
        lat: lat,
        long: lon,
        height: height,
      },
    })
    setEdit(!edit)
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position='static'>
        <Toolbar variant='dense'>
          <Breadcrumbs aria-label='breadcrumb'>
            <Link
              style={{ textDecoration: 'inherit', color: 'inherit' }}
              to={'/'}>
              Home
            </Link>
            {
              site.inboundPath.map((site) => {
                return (
                  <Link
                    key={site._id}
                    style={{ textDecoration: 'inherit', color: 'inherit' }}
                    to={`/network/site/${site._id}`}>
                    {site.name}
                  </Link>
                )
              })
            }
          </Breadcrumbs>
          <Box sx={{ flexGrow: 1 }} />
          <Button color='inherit' onClick={() => setDialogOpen(!dialogOpen)}>
            Add New Device
          </Button>
          <Button color='inherit' onClick={() => setEdit(!edit)}>
            Edit Site
          </Button>
        </Toolbar>
      </AppBar>
      <NewDeviceDialog site={site} open={dialogOpen} onClose={() => setDialogOpen(false)} onSubmit={handleDeviceSubmit} />
      <Dialog open={edit} onClose={() => setEdit(!edit)}>
        <DialogTitle>{name}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin='dense'
            label='Name'
            fullWidth
            variant="filled"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <TextField
            margin='dense'
            label='Category'
            fullWidth
            variant="filled"
            value={category}
            onChange={(e) => setCategory(e.target.value)}
          />
          <TextField
            margin='dense'
            label='MRC'
            fullWidth
            variant="filled"
            value={mrc}
            onChange={(e) => setMrc(e.target.value)}
          />
          <TextField
            margin='dense'
            label='Latitude'
            fullWidth
            variant="filled"
            value={lat}
            onChange={(e) => setLat(e.target.value)}
          />
          <TextField
            margin='dense'
            label='Longitude'
            fullWidth
            variant="filled"
            value={lon}
            onChange={(e) => setLon(e.target.value)}
          />
          <TextField
            margin='dense'
            label='Height (Meters)'
            fullWidth
            variant="filled"
            value={height}
            onChange={(e) => setHeight(e.target.value)}
          />
          <TextField
            autoFocus
            margin='dense'
            label='Electric Company'
            fullWidth
            variant="filled"
            value={electricName}
            onChange={(e) => setElectricName(e.target.value)}
          />
          <TextField
            autoFocus
            margin='dense'
            label='Electric Number'
            fullWidth
            variant="filled"
            value={electricPhoneNumber}
            onChange={(e) => setElectricPhoneNumber(e.target.value)}
          />
          <TextField
            autoFocus
            margin='dense'
            label='Meter'
            fullWidth
            variant="filled"
            value={meter}
            onChange={(e) => setMeter(e.target.value)}
          />
          <TextField
            autoFocus
            margin='dense'
            label='Escort'
            fullWidth
            variant="filled"
            value={escortName}
            onChange={(e) => setEscortName(e.target.value)}
          />
          <TextField
            autoFocus
            margin='dense'
            label='Escort Phone'
            fullWidth
            variant="filled"
            value={escortPhoneNumber}
            onChange={(e) => setEscortPhoneNumber(e.target.value)}
          />
          <TextField
            autoFocus
            margin='dense'
            label='Escort Email'
            fullWidth
            variant="filled"
            value={escortEmail}
            onChange={(e) => setEscortEmail(e.target.value)}
          />
          <TextField
            autoFocus
            margin='dense'
            label='Key Location'
            fullWidth
            variant="filled"
            value={keyLocation}
            onChange={(e) => setKeyLocation(e.target.value)}
          />
          <TextField
            autoFocus
            margin='dense'
            label='Lock Code'
            fullWidth
            variant="filled"
            value={lockCode}
            onChange={(e) => setLockCode(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button variant='filled' onClick={handleEdit}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}

export default SiteBar