import React from 'react'
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Divider,
  Box,
  Icon,
  Chip,
  Button,
} from '@mui/material'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import WifiIcon from '@mui/icons-material/Wifi'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import PersonIcon from '@mui/icons-material/Person'
import HomeIcon from '@mui/icons-material/Home'
import SpeedIcon from '@mui/icons-material/Speed'

const SubInfoCard = ({ services, arps }) => {
  const handleRemoveService = (service) => {
    console.log(service)
  }
  return (
    <Grid container spacing={3} justifyContent="center">
      {services.length
        ? services.map((service) => (
            <Grid item key={service?._id}>
              <Card sx={{ p: 2, boxShadow: 3 }}>
                <CardContent>
                  {/* Subscriber Section */}
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    mb={2}
                  >
                    {/* Left-aligned PersonIcon and Name */}
                    <Box display="flex" alignItems="center">
                      <PersonIcon color="primary" sx={{ mr: 1 }} />
                      <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
                        {service?.subscriberDetails.name}
                      </Typography>
                    </Box>
                    <Box display="flex" flexGrow={1} />

                    {/* Right-aligned Subscriber Type and Status Chip */}
                    <Box display="flex" alignItems="center">
                      <Chip
                        label={service?.status}
                        color={
                          service?.status === 'Active' ? 'success' : 'default'
                        }
                      />
                    </Box>
                  </Box>

                  <Divider sx={{ my: 2 }} />

                  {/* Address Section */}
                  <Box display="flex" alignItems="center" mb={2}>
                    <HomeIcon color="secondary" sx={{ mr: 1 }} />
                    <Typography variant="body1">{service?.address}</Typography>
                  </Box>

                  {/* Service Information Section */}
                  <Box display="flex" alignItems="center" mb={2}>
                    <SpeedIcon color="action" sx={{ mr: 1 }} />
                    <Typography variant="body1">
                      Speed Profile: {service?.speed_profile}
                    </Typography>
                  </Box>

                  <Box display="flex" alignItems="center" mb={2}>
                    <FiberManualRecordIcon
                      color={service?.fiberAvailable ? 'success' : 'error'}
                      sx={{ mr: 1 }}
                    />
                    <Typography variant="body1">
                      Fiber Available: {service?.fiberAvailable ? 'Yes' : 'No'}
                    </Typography>
                  </Box>

                  {/* Financial Info Section */}
                  <Box display="flex" alignItems="center" mb={2}>
                    <AttachMoneyIcon color="success" sx={{ mr: 1 }} />
                    <Typography variant="body1">
                      Monthly Recurring Revenue (MRR): $
                      {service?.mrr?.toFixed(2)}
                    </Typography>
                  </Box>

                  <Box display="flex" alignItems="center" mb={2}>
                    <WifiIcon
                      color={service?.provisioned ? 'primary' : 'disabled'}
                      sx={{ mr: 1 }}
                    />
                    <Typography variant="body1">
                      Provisioned: {service?.provisioned ? 'Yes' : 'No'}
                    </Typography>
                  </Box>

                  {/* Dates Section */}
                  <Divider sx={{ my: 2 }} />
                  <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                    <Box>
                      <Typography
                        variant="body1"
                        color="textSecondary"
                        sx={{ mr: 2 }}
                      >
                        {service?.subscriberDetails.subscriberType === 'R'
                          ? 'Residential'
                          : 'Business'}
                      </Typography>
                      <Typography variant="caption" color="textSecondary">
                        Created:{' '}
                        {new Date(service?.createdAt).toLocaleDateString()}
                      </Typography>
                      <br />
                      <Typography variant="caption" color="textSecondary">
                        Last Updated:{' '}
                        {new Date(service?.updatedAt).toLocaleDateString()}
                      </Typography>
                    </Box>
                    <Box sx={{ flex: '1 1 auto' }} />
                    <Button
                      variant={'contained'}
                      onClick={() => handleRemoveService(service)}
                    >
                      Remove Service
                    </Button>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))
        : 'No services found'}
    </Grid>
  )
}

export default SubInfoCard
