import React from 'react'
import { useSelector } from 'react-redux'
import {
  Typography,
  Toolbar,
  AppBar,
  Divider,
  List,
  ListItemText,
  Avatar,
  Grid,
  ListItemAvatar,
  ListItemButton,
} from '@mui/material'
import Moment from 'react-moment'
import { Link } from 'react-router-dom'
import { useGetUsers } from '../../hooks'

const UserList = () => {

  useGetUsers()

  const { users, loading } = useSelector((state) => state.wsUsers)

  return (
    <>
      {
        loading
          ? null
          : <div>
            <AppBar sx={{ position: 'relative' }}>
              <Toolbar>
                <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                  Users
                </Typography>
              </Toolbar>
            </AppBar>
            <List>
              {
                users.map((user) => {
                  return (
                    <div key={user._id}>
                      <Link
                        style={{ color: 'inherit', textDecoration: 'inherit' }}
                        to={`/users/${user._id}`}>
                        <ListItemButton alignItems="flex-start">
                          <ListItemAvatar>
                            <Avatar alt={user.fullName} src={user?.picture} />
                          </ListItemAvatar>
                          <ListItemText
                            primary={
                              <Grid container direction='row' justifyContent="space-between">
                                <Grid item>
                                  <Typography
                                    sx={{ display: 'inline' }}
                                    component="span"
                                    variant="body2"
                                    color="text.primary"
                                  >
                                    {user.fullName}
                                  </Typography>
                                </Grid>
                                <Grid item>
                                  <Typography
                                    sx={{ display: 'inline' }}
                                    component="span"
                                    variant="subtitle"
                                    color="text.primary"
                                  >
                                    {user.email}
                                  </Typography>
                                </Grid>
                              </Grid>
                            }
                            secondary={
                              <Grid container direction='row' justifyContent="space-between" >
                                <Grid item>
                                  <Typography
                                    sx={{ display: 'inline' }}
                                    component="span"
                                    variant="body2"
                                    color="text.primary"
                                  >
                                    {user?.team}
                                  </Typography>
                                </Grid>
                                <Grid item>
                                  <Typography
                                    sx={{ display: 'inline' }}
                                    component="span"
                                    variant="subtitle"
                                    color="text.primary"
                                  >
                                    Created <Moment fromNow>{user.createdAt}</Moment>
                                  </Typography>
                                </Grid>
                              </Grid>
                            }
                          />
                        </ListItemButton>
                        <Divider />
                      </Link>
                    </div>
                  )
                })
              }
            </List>
          </div>
      }
    </>

  )
}

export default UserList