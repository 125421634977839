import React, { useState } from 'react'
import {
  Typography,
  Grid,
  Paper,
  TextField,
  Button,
  Collapse,
  Divider,
  Stack,
} from '@mui/material'

import { useUptime } from '../../../hooks'
import DeviceMenu from '../../Core/Device/DeviceMenu'

const EdgePower = ({ device }) => {
  const uptime = useUptime(device.uptime)
  const [collapsed, setCollapsed] = useState(false)


  return (
    <Paper elevation={5} sx={{ p: 1, flexGrow: 1 }}>
      <Grid backgroundColor={!device.connected && 'red'}
        container
        direction='column'
        sx={{ p: 2 }}
        justifyContent='space-evenly'>
        <Grid item>
          <Grid item container direction='row' justifyContent='space-between'>
            <Grid item>
              <Typography variant='h6'>{device.name}</Typography>
            </Grid>
            <Grid item>
              <DeviceMenu device={device} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item container direction='row' justifyContent='space-between'>
          <Grid item>
            <Typography variant='caption'>
              {uptime}
            </Typography>
          </Grid>
          <Grid item>
            <Typography>
              Status: {device.power?.state}
            </Typography>
          </Grid>
        </Grid>
        <Divider />

        <Grid
          item
          container
          direction='row'
          justifyContent='space-between'
          alignItems='center'
          onClick={() => setCollapsed(!collapsed)}>
          <Grid item>
            <Grid
              item
              container
              direction='column'
              justifyContent='space-between'>
              <Grid item>
                <Typography>{device?.model}</Typography>
              </Grid>
              <Grid item>
                <Typography>
                  <a
                    onClick={(e) => e.stopPropagation()}
                    style={{
                      textDecoration: 'inherit',
                      color: 'inherit',
                    }}
                    target='_blank'
                    href={`http://${device.ip}`}>
                    {device.ip}
                  </a>
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant='caption' component='div'>
                  {device?.mac?.toUpperCase()}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid
              item
              container
              direction='row'
              justifyContent='space-between'
              alignItems='center'>
              <Typography variant='body2' color='text.secondary'>
                Output:
              </Typography>
              <Typography variant='body2' color='text.secondary'>
                {`${device.power?.output?.wattage}W/${device.power?.output?.maxWattage}W`}
              </Typography>
            </Grid>
            <Divider />
          </Grid>
        </Grid>
        <Collapse in={collapsed}>
          <Grid
            backgroundColor={!device.connected && 'red'}
            item
            container
            direction='row'
            justifyContent='space-between'
          >
            {device?.power?.psu?.map((psu, i) => (
              <Grid
                key={i}
                sx={{ p: 1 }}
                item
                container
                direction='column'
                alignItems='center'
                xs={6}>
                <Divider style={{ width: '100%' }}>{`PSU${i + 1}`}</Divider>
                {
                  psu.type != null &&
                  <>
                    <Grid
                      item
                      container
                      direction='row'
                      justifyContent='space-between'
                      alignItems='center'>
                      <Typography variant='body2' color='text.secondary'>
                        Type:
                      </Typography>
                      <Typography variant='body2' color='text.secondary'>
                        {psu.type}
                      </Typography>
                    </Grid>
                    <Divider style={{ width: '100%' }} />
                  </>
                }
                <Grid
                  item
                  container
                  direction='row'
                  justifyContent='space-between'
                  alignItems='center'>
                  <Typography variant='body2' color='text.secondary'>
                    Connected:
                  </Typography>
                  <Typography variant='body2' color='text.secondary'>
                    {psu.connected ? 'Yes' : 'No'}
                  </Typography>
                </Grid>
                <Divider style={{ width: '100%' }} />
                <Grid
                  item
                  container
                  direction='row'
                  justifyContent='space-between'
                  alignItems='center'>
                  <Typography variant='body2' color='text.secondary'>
                    Standby:
                  </Typography>
                  <Typography variant='body2' color='text.secondary'>
                    {psu.standby ? 'Yes' : 'No'}
                  </Typography>
                </Grid>
                <Divider style={{ width: '100%' }} />
                {
                  psu.voltage != null &&
                  <>
                    <Grid
                      item
                      container
                      direction='row'
                      justifyContent='space-between'
                      alignItems='center'>
                      <Typography variant='body2' color='text.secondary'>
                        Voltage:
                      </Typography>
                      <Typography variant='body2' color='text.secondary'>
                        {psu.voltage}
                      </Typography>
                    </Grid>
                    <Divider style={{ width: '100%' }} />
                  </>
                }
                {
                  psu.current != null &&
                  <>
                    <Grid
                      item
                      container
                      direction='row'
                      justifyContent='space-between'
                      alignItems='center'>
                      <Typography variant='body2' color='text.secondary'>
                        Current:
                      </Typography>
                      <Typography variant='body2' color='text.secondary'>
                        {psu.current}
                      </Typography>
                    </Grid>
                    <Divider style={{ width: '100%' }} />
                  </>
                }
                {
                  psu.wattage != null &&
                  <>
                    <Grid
                      item
                      container
                      direction='row'
                      justifyContent='space-between'
                      alignItems='center'>
                      <Typography variant='body2' color='text.secondary'>
                        Wattage:
                      </Typography>
                      <Typography variant='body2' color='text.secondary'>
                        {psu.wattage}
                      </Typography>
                    </Grid>
                    <Divider style={{ width: '100%' }} />
                  </>
                }
                {
                  psu.type != null &&
                  <>
                    <Grid
                      item
                      container
                      direction='row'
                      justifyContent='space-between'
                      alignItems='center'>
                      <Typography variant='body2' color='text.secondary'>
                        Type:
                      </Typography>
                      <Typography variant='body2' color='text.secondary'>
                        {psu.type}
                      </Typography>
                    </Grid>
                    <Divider style={{ width: '100%' }} />
                  </>
                }
              </Grid>

            ))}
          </Grid>
        </Collapse>
      </Grid>
    </Paper>
  )
}

export default EdgePower
/*


*/
