import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  users: [],
  loading: false,
  error: null,
}

export const wsUsersSlice = createSlice({
  name: 'clients',
  initialState,
  reducers: {
    resetUsers: () => initialState,
    requestUsers: (state) => {
      state.loading = true
    },
    receiveUsers: (state, action) => {
      state.loading = false
      state.users = action.payload
      state.error = null
    },
    updateUser: (state) => {
      state.loading = true
    },
  },
})

export const { resetUsers, requestUsers, receiveUsers, updateUser } =
  wsUsersSlice.actions

export default wsUsersSlice.reducer
