import React from 'react'
import ReactDOM from 'react-dom/client'
import store from './store'
import { Provider } from 'react-redux'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import AppProvider from './context/AppProvider'
import './index.css'
import App from './App'


const themeConfig = (mode) =>
  createTheme({
    palette: {
      mode: mode,
      ...(mode === 'light'
        ? {
          // Light theme colors inspired by the image
          primary: {
            main: '#87D6DD', // Soft blue for primary elements
          },
          secondary: {
            main: '#a5b5a7', // Muted green for secondary elements
          },
          background: {
            default: '#f4f4f4', // Off-white background
            paper: '#e7e7e7', // Light gray for paper elements
          },
          text: {
            primary: '#333333', // Soft black for primary text
            secondary: 'rgba(51, 51, 51, 0.7)', // Lighter black for secondary text
          },
          divider: 'rgba(51, 51, 51, 0.12)', // Light dividers
        }
        : {
          // Dark theme colors
          primary: {
            main: '#4d88ff', // Teal blue
          },
          secondary: {
            main: '#ff6666', // Muted coral red
          },
          background: {
            default: '#121212', // Near black
            paper: '#1e1e1e', // Slightly lighter black
          },
          text: {
            primary: '#e0e0e0', // Soft white
            secondary: 'rgba(224, 224, 224, 0.7)', // Slightly transparent white
          },
        }),
    },
    components: {
      // Customizations for MUI components can go here, if needed for both themes
    },
  })

const lightTheme = themeConfig('light')
const darkTheme = themeConfig('dark')

// Use the theme you want here; replace with lightTheme for light mode
const theme = darkTheme

// Initialize a root
const root = ReactDOM.createRoot(document.getElementById('root'))

// Render inside the root
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <AppProvider>
          <App />
        </AppProvider>
      </ThemeProvider>
    </Provider>
  </React.StrictMode>
)