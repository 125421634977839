import { Netmask } from 'netmask'
import { saveAs } from 'file-saver'
import JSZip from 'jszip'
import axios from 'axios'

import { createLtuConfigs } from './configGenerators/ltuRocket'
import { createMikrotikConfig } from './configGenerators/mikrotikV2'
import { createEsTar } from './configGenerators/edgeswitch'
import { createTikSwitchConfig } from './configGenerators/mikrotikSwOS'

const updateDb = async (
  siteDetail,
  managementNet,
  publicNet,
  cgNatNet,
  fiberNet,
  loopbackNet,
  apCount,
  initConfig
) => {
  const siteId = await axios
    .post(`/api/sites`, {
      name: siteDetail.name,
      type: siteDetail.type,
      loopback: loopbackNet.base,
      managementNet: managementNet.base + '/' + managementNet.bitmask,
      location: {
        lat: Number(siteDetail.latitude),
        long: Number(siteDetail.longitude),
        height: Number(siteDetail.height),
      },
      initConfig,
    })
    .then((res) => res.data._id)

  await axios
    .post('/api/devices', {
      site: siteId,
      ip: loopbackNet.base,
      family: 'mikrotik',
      model: 'CCR1009-7G-1C-1S+',
      type: 'tikRouter',
    })
    .then((res) => res.data)

  // for (let i = 0; i < apCount; i++) {
  //   let managementIp =
  //     managementNet.first.split('.').slice(0, 3).join('.') + '.' + (10 + i)
  //   await axios.post('/api/devices', {
  //     site: siteId,
  //     ip: managementIp,
  //     family: 'ltu',
  //     model: 'LTU-Rocket',
  //     function: 'ap-ptmp',
  //   })
  // }
}

const siteCreator = async (initConfig) => {
  const {
    siteDetail,
    siteWirelessDetail,
    siteDevices,
    fiberInterface,
    bhList,
    managementInterface,
    hsiInterface,
  } = initConfig

  let managementNet = new Netmask(managementInterface.network)
  let fiberNet = fiberInterface.useFiber && new Netmask(fiberInterface.network)
  let cgNatNet =
    hsiInterface.cgNatNetwork && new Netmask(hsiInterface.cgNatNetwork)
  let publicNet =
    hsiInterface.publicNetwork && new Netmask(hsiInterface.publicNetwork)

  const bhNetworks = bhList.map((el) => {
    el.subnet = new Netmask(el.bhNetwork)
    return el
  })

  let lbArr = managementNet.base.split('.')

  let loopbackNet = new Netmask(`${lbArr[0]}.${lbArr[1]}.255.${lbArr[2]}/32`)
  let cgNatPublic = new Netmask(`${hsiInterface.cgNatPublic}/32`)

  console.log(
    bhNetworks,
    fiberInterface.name,
    fiberNet,
    siteDetail.name,
    managementInterface.vlan,
    managementNet,
    hsiInterface.vlan,
    cgNatNet,
    cgNatPublic,
    publicNet,
    loopbackNet
  )

  let mikrotik = createMikrotikConfig(
    bhNetworks,
    fiberInterface.name,
    fiberNet,
    siteDetail.name,
    managementInterface.vlan,
    managementNet,
    hsiInterface.vlan,
    cgNatNet,
    cgNatPublic,
    publicNet,
    loopbackNet
  )
  let ltuConfigs = createLtuConfigs(
    siteDetail.name,
    managementNet,
    siteWirelessDetail.beamWidth,
    siteWirelessDetail.apCount
  )
  let switchCfg = await createEsTar(siteDetail.name, managementNet).then(
    (res) => res
  )
  let tikSwitchCfg = await createTikSwitchConfig(siteDetail.name, managementNet)
  let zip = new JSZip()

  zip.file(`${siteDetail.name}_switch.tar.gz`, switchCfg)
  zip.file(`${siteDetail.name}_tikSwitch.swb`, tikSwitchCfg)
  zip.file(`${siteDetail.name}_tikRRouter.txt`, mikrotik)
  ltuConfigs.forEach((el) => {
    zip.file(`ltuAps/${el.name}.cfg`, el.file)
    console.log(`Adding ${el.name} to zip...`, ltuConfigs)
  })
  zip.generateAsync({ type: 'blob' }).then((blob) => {
    saveAs(blob, `${siteDetail.name}.zip`)
  })
  await updateDb(
    siteDetail,
    managementNet,
    publicNet,
    cgNatNet,
    fiberNet,
    loopbackNet,
    siteWirelessDetail.apCount,
    initConfig
  )
}

export default siteCreator
