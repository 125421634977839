import { useState } from 'react'
import {
  AreaChart,
  Area,
  Label,
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ReferenceArea,
} from "recharts"

const initialData = [
  { name: 1, cost: 4.11, impression: 100 },
  { name: 2, cost: 2.39, impression: 120 },
  { name: 3, cost: 1.37, impression: 150 },
  { name: 4, cost: 1.16, impression: 180 },
  { name: 5, cost: 2.29, impression: 200 },
  { name: 6, cost: 3, impression: 499 },
  { name: 7, cost: 0.53, impression: 50 },
  { name: 8, cost: 2.52, impression: 100 },
  { name: 9, cost: 1.79, impression: 200 },
  { name: 10, cost: 2.94, impression: 222 },
  { name: 11, cost: 4.3, impression: 210 },
  { name: 12, cost: 4.41, impression: 300 },
  { name: 13, cost: 2.1, impression: 50 },
  { name: 14, cost: 8, impression: 190 },
  { name: 15, cost: 0, impression: 300 },
  { name: 16, cost: 9, impression: 400 },
  { name: 17, cost: 3, impression: 200 },
  { name: 18, cost: 2, impression: 50 },
  { name: 19, cost: 3, impression: 100 },
  { name: 20, cost: 7, impression: 100 },
]

const ZoomIn = () => {


  const [data, setData] = useState(initialData)
  const [left, setLeft] = useState("dataMin")
  const [right, setRight] = useState("dataMax")
  const [refAreaLeft, setRefAreaLeft] = useState("")
  const [refAreaRight, setRefAreaRight] = useState("")


  const zoom = () => {

    if (refAreaLeft === refAreaRight || refAreaRight === "") {
      setRefAreaLeft("")
      setRefAreaRight("")
      return
    }

    // xAxis domain
    if (refAreaLeft > refAreaRight) {
      setRefAreaLeft(refAreaRight)
      setRefAreaRight(refAreaLeft)
    }


    setRefAreaLeft("")
    setRefAreaRight("")
    setData(data.slice())
    setLeft(refAreaLeft)
    setRight(refAreaRight)



  }

  const zoomOut = () => {
    setData(data.slice())
    setRefAreaLeft("")
    setRefAreaRight("")
    setLeft("dataMin")
    setRight("dataMax")
  }


  return (
    <div className="highlight-bar-charts" style={{ userSelect: "none" }}>
      <button
        className="btn update"
        onClick={zoomOut}
      >
        Zoom Out
      </button>

      <AreaChart
        width={800}
        height={400}
        data={data}
        onMouseDown={(e) => { setRefAreaLeft(e.activeLabel) }}
        onMouseMove={(e) => { setRefAreaRight(e.activeLabel) }}
        onMouseUp={zoom}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          allowDataOverflow
          dataKey="name"
          domain={[left, right]}
          type="number"
        />
        <Area
          yAxisId="1"
          type="natural"
          dataKey="cost"
          stroke="#8884d8"
          animationDuration={300}
        />
        <Area
          yAxisId="2"
          type="natural"
          dataKey="impression"
          stroke="#82ca9d"
          animationDuration={300}
        />

        {refAreaLeft && refAreaRight ? (
          <ReferenceArea
            yAxisId="1"
            x1={refAreaLeft}
            x2={refAreaRight}
            strokeOpacity={0.3}
          />
        ) : null}
      </AreaChart>
    </div>
  )
}

export default ZoomIn