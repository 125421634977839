import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  sockets: [],
  error: ''
}

export const wsActiveSocketsSlice = createSlice({
  name: 'roominfo',
  initialState,
  reducers: {
    resetActiveSockets: () => initialState,
    receiveActiveSockets: (state, action) => {
      state.sockets = action.payload
    },
  },
})

export const { resetActiveSockets, receiveActiveSockets } = wsActiveSocketsSlice.actions

export default wsActiveSocketsSlice.reducer