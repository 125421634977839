import { Typography, Grid, Paper, Divider } from '@mui/material'

const WirelessDetailCard = ({ data }) => {
  const formatThroughput = (bitData) => {
    switch (data?.family) {
      case 'ltu':
        return parseFloat(bitData / 1000000).toFixed(2)
      case 'af5xhd':
        return parseFloat(bitData / 1000000).toFixed(2)
      case 'wave':
        return parseFloat(bitData / 1000000).toFixed(2)
      case 'af60':
        return parseFloat(bitData / 1000000).toFixed(2)
      case 'airmax':
        return parseFloat(bitData / 1000000).toFixed(2)
      case 'epmp':
        return parseFloat(bitData / 1000000).toFixed(2)
      case 'aviat':
        return parseFloat(bitData / 1000000).toFixed(2)
      default:
        return parseFloat(bitData / 1000000).toFixed(2)
    }
  }

  return (
    <Paper elevation={5} sx={{ p: 1, flexGrow: 1 }}>
      <Grid
        container
        direction="column"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid
          item
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid
            item
            container
            direction="column"
            justifyContent="space-between"
            alignItems="center"
            xs={12}
          >
            <Divider style={{ width: '100%' }}>Wireless</Divider>
            {data?.wireless?.ssid != null && (
              <>
                <Grid
                  item
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography variant="body2" color="text.secondary">
                    Network Name:
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {data?.wireless.ssid}
                  </Typography>
                </Grid>
                <Divider style={{ width: '100%' }} />
              </>
            )}
            {data?.wireless?.passphrase != null && (
              <>
                <Grid
                  item
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography variant="body2" color="text.secondary">
                    Passphrase:
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {data?.wireless?.passphrase}
                  </Typography>
                </Grid>
                <Divider style={{ width: '100%' }} />
              </>
            )}
            {data?.wireless?.activeFrequency != null && (
              <>
                <Grid
                  item
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography variant="body2" color="text.secondary">
                    Frequency:
                  </Typography>
                  {data?.wireless?.activeFrequency ===
                  data?.wireless?.frequency ? (
                    <Typography variant="body2" color="text.secondary">
                      {data?.wireless?.activeFrequency}
                    </Typography>
                  ) : (
                    <Typography
                      variant="body2"
                      color="error"
                    >{`${data?.wireless?.activeFrequency} (Alternate)`}</Typography>
                  )}
                </Grid>
                <Divider style={{ width: '100%' }} />
              </>
            )}
            {data?.wireless?.dlThroughput != null && (
              <>
                <Grid
                  item
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography variant="body2" color="text.secondary">
                    {data?.function.includes('sta') ? 'RX' : 'TX'} Throughput:
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {formatThroughput(data?.wireless.dlThroughput)} Mbps
                  </Typography>
                </Grid>
                <Divider style={{ width: '100%' }} />
              </>
            )}
            {data?.wireless?.ulThroughput != null && (
              <>
                <Grid
                  item
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography variant="body2" color="text.secondary">
                    {data?.function.includes('sta') ? 'TX' : 'RX'} Throughput:
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {formatThroughput(data?.wireless.ulThroughput)} Mbps
                  </Typography>
                </Grid>
                <Divider style={{ width: '100%' }} />
              </>
            )}
            {data?.wireless?.dlUtilization != null && (
              <>
                <Grid
                  item
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography variant="body2" color="text.secondary">
                    {data?.function.includes('sta') ? 'RX' : 'TX'} Utilization:
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {data?.wireless.dlUtilization}%
                  </Typography>
                </Grid>
                <Divider style={{ width: '100%' }} />
              </>
            )}
            {data?.wireless?.ulUtilization != null && (
              <>
                <Grid
                  item
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography variant="body2" color="text.secondary">
                    {data?.function.includes('sta') ? 'TX' : 'RX'} Utilization:
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {data?.wireless.ulUtilization}%
                  </Typography>
                </Grid>
                <Divider style={{ width: '100%' }} />
              </>
            )}
            {data?.wireless?.dlCapacity != null && (
              <>
                <Grid
                  item
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography variant="body2" color="text.secondary">
                    {data?.function.includes('sta') ? 'RX/TX' : 'TX/RX'}{' '}
                    Capacity:
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {formatThroughput(data?.wireless.dlCapacity)}/
                    {formatThroughput(data?.wireless.ulCapacity)} Mbps
                  </Typography>
                </Grid>
                <Divider style={{ width: '100%' }} />
              </>
            )}
            {data?.wireless?.dlScore != null && (
              <>
                <Grid
                  item
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography variant="body2" color="text.secondary">
                    Download Score:
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {data?.wireless.dlScore}%
                  </Typography>
                </Grid>
                <Divider style={{ width: '100%' }} />
              </>
            )}
            {data?.wireless?.dlScore != null && (
              <>
                <Grid
                  item
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography variant="body2" color="text.secondary">
                    Upload Score:
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {data?.wireless.ulScore}%
                  </Typography>
                </Grid>
                <Divider style={{ width: '100%' }} />
              </>
            )}
            {data?.accelerometer?.xydegreescurr != null && (
              <>
                <Grid
                  item
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography variant="body2" color="text.secondary">
                    Rotation:
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {(data?.accelerometer.xydegreescurr - 90).toFixed(2)}°
                  </Typography>
                </Grid>
                <Divider style={{ width: '100%' }} />
              </>
            )}
            {data?.accelerometer?.zdegreescurr != null && (
              <>
                <Grid
                  item
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography variant="body2" color="text.secondary">
                    Tilt:
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {(data?.accelerometer.zdegreescurr - 90).toFixed(2)}°
                  </Typography>
                </Grid>
              </>
            )}
          </Grid>
          <Grid
            item
            container
            direction="column"
            justifyContent="space-between"
            alignItems="center"
            xs={12}
          >
            <Divider style={{ width: '100%' }}>Host</Divider>
            <Grid
              item
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="body2" color="text.secondary">
                Firmware:
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {data?.firmware}
              </Typography>
            </Grid>
            <Divider style={{ width: '100%' }} />
            {data?.shaping != null && (
              <>
                <Grid
                  item
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography variant="body2" color="text.secondary">
                    DL Limit:
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {data?.shaping?.downloadRate}
                  </Typography>
                </Grid>
                <Divider style={{ width: '100%' }} />
                <Grid
                  item
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography variant="body2" color="text.secondary">
                    UL Limit:
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {data?.shaping?.uploadRate}
                  </Typography>
                </Grid>
                <Divider style={{ width: '100%' }} />
              </>
            )}
            {data?.lan?.lanSpeed != null && (
              <>
                <Grid
                  item
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography variant="body2" color="text.secondary">
                    LAN Status:
                  </Typography>
                  {data?.lan.lanSpeed ? (
                    <Typography variant="body2" color="text.secondary">{`${
                      data?.lan.lanSpeed
                    }Mbps${
                      data?.lan.lanDuplex === undefined
                        ? '/Full'
                        : data?.lan.lanDuplex
                        ? '/Full'
                        : '/Half'
                    }`}</Typography>
                  ) : (
                    <Typography variant="body2" color="error">
                      NO LAN
                    </Typography>
                  )}
                </Grid>
                <Divider style={{ width: '100%' }} />
              </>
            )}
            {data?.lan?.lanTxErrors != null && (
              <>
                <Grid
                  item
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography variant="body2" color="text.secondary">
                    TX Errors:
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {data?.lan.lanTxErrors}
                  </Typography>
                </Grid>
                <Divider style={{ width: '100%' }} />
              </>
            )}
            {data?.lan?.lanRxErrors != null && (
              <>
                <Grid
                  item
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography variant="body2" color="text.secondary">
                    RX Errors:
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {data?.lan.lanRxErrors}
                  </Typography>
                </Grid>
                <Divider style={{ width: '100%' }} />
              </>
            )}
            {data?.mac != null && (
              <>
                <Grid
                  item
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography variant="body2" color="text.secondary">
                    MAC:
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {data?.mac}
                  </Typography>
                </Grid>
                <Divider style={{ width: '100%' }} />
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default WirelessDetailCard
