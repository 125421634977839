import React, { useEffect, useState } from 'react'
import { Netmask } from 'netmask'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import { MenuItem, Button, FormControl, Grid, IconButton, InputLabel, Paper, TextField, Select } from "@mui/material"


const incrementIP = (ip) => {
  // Convert each octet to its 8-bit representation and merge them into a single 32-bit integer
  const ipAsInt = ip.split('.').reduce((acc, octet) => (acc << 8) + parseInt(octet, 10), 0)

  // Increment the 32-bit integer
  const nextIpAsInt = ipAsInt + 1

  // Convert it back to an IPv4 string
  const nextIp = [
    (nextIpAsInt >>> 24) & 0xFF,
    (nextIpAsInt >>> 16) & 0xFF,
    (nextIpAsInt >>> 8) & 0xFF,
    nextIpAsInt & 0xFF
  ].join('.')

  return nextIp
}


const DhcpNetworkForm = ({ setDhcpNetworks, dhcpNetworks, routerAddresses, network, networkIndex, server }) => {

  const [dhcpStartError, setDhcpStartError] = useState(false)
  const [dhcpStopError, setDhcpStopError] = useState(false)

  const handleDhcpNetworks = (e, index) => {
    const { name, value } = e.target
    const list = [...dhcpNetworks]
    list[index][name] = value

    switch (name) {
      case 'ipAddr':
        const net = new Netmask(value)
        list[index]['block'] = net
        list[index]['network'] = `${net.toString()}`
        list[index]['gateway'] = value.split('/')[0]
        list[index]['dhcpStart'] = incrementIP(net.first)
        list[index]['dhcpStop'] = `${net.last}`
        break
      case 'dhcpStart':
        try {
          list[index]['block'].contains(value) ? setDhcpStartError(false) : setDhcpStartError(`IP must be part of ${list[index]['network']}`)
        } catch (e) {
          setDhcpStartError('IP is invalid.')
        }
        break
      case 'dhcpStop':
        try {
          list[index]['block'].contains(value) ? setDhcpStopError(false) : setDhcpStopError(`IP must be part of ${list[index]['network']}`)
        } catch (e) {
          setDhcpStopError('IP is invalid.')
        }
        break
    }

    if (name === 'ipAddr') {

    }
    setDhcpNetworks(list)
  }
  // handle remove DHCP Network
  const handleRemoveDhcpNetwork = (index) => {
    const list = [...dhcpNetworks]
    list.splice(index, 1)
    setDhcpNetworks(list)
  }


  return (
    <Grid item sx={{ p: 3 }}>
      <Paper sx={{ p: 1, position: 'relative' }}>
        <IconButton aria-label='delete' sx={{ position: 'absolute', bottom: 2, right: 2 }} onClick={() => handleRemoveDhcpNetwork(networkIndex)}>
          <DeleteIcon />
        </IconButton>
        <Grid item sx={{ p: 1 }}>
          <FormControl fullWidth size="small">
            <InputLabel>Gateway</InputLabel>
            <Select
              name='ipAddr'
              value={server.gateway}
              onChange={(e) => handleDhcpNetworks(e, networkIndex)}>
              {routerAddresses?.map((i) => {
                if (i.interface === server.interface) {
                  return (
                    <MenuItem key={i.ip} value={i.ip}>
                      {i.ip}
                    </MenuItem>
                  )
                }
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item container direction={'row'}>
          <Grid item sx={{ p: 1 }}>
            <TextField
              size="small"
              fullWidth
              autoComplete='off'
              name='dhcpStart'
              label={'DHCP Pool Start Address'}
              type='text'
              variant="filled"
              value={network.dhcpStart}
              error={dhcpStartError}
              helperText={dhcpStartError}
              onChange={(e) => handleDhcpNetworks(e, networkIndex)}
            />
          </Grid>
          <Grid item sx={{ p: 1 }}>
            <TextField
              size="small"
              fullWidth
              autoComplete='off'
              name='dhcpStop'
              label={'DHCP Pool Stop Address'}
              type='text'
              variant="filled"
              value={network.dhcpStop}
              error={dhcpStopError}
              helperText={dhcpStopError}
              onChange={(e) => handleDhcpNetworks(e, networkIndex)}
            />
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  )
}

export default DhcpNetworkForm