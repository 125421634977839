import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  site: null,
  devices: null,
  loading: true,
  error: null,
}

export const wsSiteSlice = createSlice({
  name: 'site',
  initialState,
  reducers: {
    resetSite: () => initialState,
    requestSite: (state) => {
      state.loading = true
    },
    receiveSite: (state, action) => {
      state.loading = false
      state.site = action.payload.site
      state.devices = action.payload.devices
      state.error = null
    },
  },
})

export const { resetSite, requestSite, receiveSite } = wsSiteSlice.actions

export default wsSiteSlice.reducer
