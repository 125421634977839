import React, { useState } from 'react'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Step,
  Stepper,
  StepLabel,
  Switch,
  TextField,
  Typography,
} from '@mui/material'
import siteCreator from '../../../utils/siteCreator'
import SelectAllTransferList from '../../TransferList'

const mikrotikRouters = [
  {
    model: '1009',
    interfaces: [
      'combo1',
      'ether1',
      'ether2',
      'ether3',
      'ether4',
      'ether5',
      'ether6',
      'ether7',
      'sfp-sfpplus1',
    ]
  },
  {
    model: '2004',
    interfaces: [
      'ether1',
      'ether2',
      'ether3',
      'ether4',
      'ether5',
      'ether6',
      'ether7',
      'ether8',
      'ether9',
      'ether10',
      'ether11',
      'ether12',
      'ether13',
      'ether14',
      'ether15',
      'ether16',
      'sfp-sfpplus1',
      'sfp-sfpplus2',
    ]
  },
  {
    model: '2116',
    interfaces: [
      'ether1',
      'ether2',
      'ether3',
      'ether4',
      'ether5',
      'ether6',
      'ether7',
      'ether8',
      'ether9',
      'ether10',
      'ether11',
      'ether12',
      'ether13',
      'sfp-sfpplus1',
      'sfp-sfpplus2',
      'sfp-sfpplus3',
      'sfp-sfpplus4',
    ]
  },
]

const SiteCreate = () => {
  const [open, setOpen] = useState(true)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const [siteName, setSiteName] = useState()
  const [siteType, setSiteType] = useState('')
  const [siteHeight, setSiteHeight] = useState()
  const [siteLatitude, setSiteLatitude] = useState()
  const [siteLongitude, setSiteLongitude] = useState()

  const [routerModel, setRouterModel] = useState('')
  const [routerInterfaces, setRouterInterfaces] = useState([
    'combo1',
    'ether1',
    'ether2',
    'ether3',
    'ether4',
    'ether5',
    'ether6',
    'ether7',
    'sfp-sfpplus1',
  ])
  const [switchModel, setSwitchModel] = useState('')
  const [poe, setPoe] = useState(false)
  const [PSUModel, setPSUModel] = useState('')

  const [apCount, setApCount] = useState(6)
  const [beamWidth, setBeamWidth] = useState(60)

  const [managementNetwork, setManagementNetwork] = useState()
  const [managementVlanId, setManagementVlanId] = useState(800)

  const [hsiVlanId, setHsiVlanId] = useState(600)
  const [cgNatNetwork, setCgNatNetwork] = useState()
  const [cgNatPublic, setCgNatPublic] = useState()
  const [publicNetwork, setPublicNetwork] = useState()

  const [fiberSite, setFiberSite] = useState(false)
  const [wichitaVlan, setWichitaVlan] = useState()
  const [kcVlan, setKcVlan] = useState()
  const [wichitaNetwork, setWichitaNetwork] = useState()
  const [kcNetwork, setKcNetwork] = useState()
  const [fiberInterfaceName, setFiberInterfaceName] = useState('')

  const [selectedInterfaces, setSelectedInterfaces] = useState([])

  const [bhList, setBhList] = useState([])

  const [activeStep, setActiveStep] = useState(0)

  const handlePoeChange = (event) => {
    setPoe(event.target.checked)
  }

  // handle add new BH link
  const handleAddClick = () => {
    setBhList([
      ...bhList,
      { remoteSite: '', isFirst: null, bhNetwork: '', bhInterface: '' },
    ])
  }
  const handleBackhauls = (e, index) => {
    const { name, value } = e.target
    const list = [...bhList]
    list[index][name] = value
    setBhList(list)
  }
  // handle remove BH link
  const handleRemoveClick = (index) => {
    const list = [...bhList]
    list.splice(index, 1)
    setBhList(list)
  }

  const handleCreateSite = () => {
    const newSite = {
      siteDetail: {
        name: siteName,
        height: siteHeight,
        latitude: siteLatitude,
        longitude: siteLongitude,
      },
      siteWirelessDetail: {
        beamWidth,
        apCount,
      },
      siteDevices: {
        routerModel,
        switchModel,
        PSUModel,
      },
      fiberInterface: {
        useFiber: fiberSite,
        name: fiberInterfaceName,
        wichitaNetwork,
        kcNetwork,
      },
      bhList,
      managementInterface: {
        vlan: managementVlanId,
        network: managementNetwork,
      },
      hsiInterface: {
        vlan: hsiVlanId,
        cgNatPublic,
        cgNatNetwork,
        publicNetwork,
      },
    }
    siteCreator(newSite)
  }

  const interfaces = [
    'combo1',
    'ether1',
    'ether2',
    'ether3',
    'ether4',
    'ether5',
    'ether6',
    'ether7',
    'sfp-sfpplus1',
  ]

  const steps = [
    {
      label: 'Details',
      contents: (
        <Grid container direction='column' sx={{ p: 1 }}>
          <Grid container item direction='row' justifyContent='space-between'>
            <Grid item xs sx={{ p: 1 }}>
              <TextField
                autoFocus
                autoComplete='off'
                fullWidth
                label='Site Name'
                value={siteName}
                onChange={(e) => setSiteName(e.target.value)}
              />
            </Grid>
          </Grid>
          <Grid container item direction='row' justifyContent='space-between'>
            <Grid item xs sx={{ p: 1 }}>
              <FormControl fullWidth>
                <InputLabel>Site Type</InputLabel>
                <Select
                  value={siteType}
                  onChange={(e) => setSiteType(e.target.value)}>
                  <MenuItem value={'Elevator'}>{'Elevator'}</MenuItem>
                  <MenuItem value={'Guyed'}>{'Guyed'}</MenuItem>
                  <MenuItem value={'Monopole'}>{'Monopole'}</MenuItem>
                  <MenuItem value={'Rooftop'}>{'Rooftop'}</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs sx={{ p: 1 }}>
              <TextField
                autoComplete='off'
                required
                fullWidth
                label='Height'
                value={siteHeight}
                onChange={(e) => setSiteHeight(e.target.value)}
              />
            </Grid>
          </Grid>
          <Grid container item direction='row' justifyContent='space-between'>
            <Grid item xs sx={{ p: 1 }}>
              <TextField
                autoComplete='off'
                required
                fullWidth
                label='Latitude'
                value={siteLatitude}
                onChange={(e) => setSiteLatitude(e.target.value)}
              />
            </Grid>
            <Grid item xs sx={{ p: 1 }}>
              <TextField
                autoComplete='off'
                required
                fullWidth
                label='Longitude'
                value={siteLongitude}
                onChange={(e) => setSiteLongitude(e.target.value)}
              />
            </Grid>
          </Grid>
        </Grid>
      ),
    },
    {
      label: 'Setup Router',
      contents: (
        <Grid container direction='column' sx={{ p: 1 }}>
          <Grid item xs sx={{ p: 1 }}>
            <FormControl fullWidth>
              <InputLabel>Router Model</InputLabel>
              <Select
                value={routerModel}
                onChange={(e) => {
                  mikrotikRouters.map(el => {
                    if (el.model === e.target.value) {
                      setRouterModel(el.model)
                      setRouterInterfaces(el.interfaces)
                    }
                  })
                }}>
                <MenuItem value={'1009'}>{'1009'}</MenuItem>
                <MenuItem value={'2004'}>{'2004'}</MenuItem>
                <MenuItem value={'2116'}>{'2116'}</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item container direction={'row'}>
            <Grid item xs sx={{ p: 1 }}>
              <FormControl fullWidth>
                <InputLabel>Switch Model</InputLabel>
                <Select
                  value={switchModel}
                  onChange={(e) => setSwitchModel(e.target.value)}>
                  <MenuItem value={'Mikrotik'}>{'Mikrotik'}</MenuItem>
                  <MenuItem value={'EP-S16'}>{'EP-S16'}</MenuItem>
                  <MenuItem value={'NetOnix'}>{'NetOnix'}</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            {
              switchModel === 'Mikrotik'
              && <Grid item xs sx={{ p: 1 }}>
                <FormControlLabel
                  fullWidth
                  labelPlacement="start"
                  label="POE"
                  control={<Switch
                    checked={poe}
                    onChange={handlePoeChange}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />}
                />
              </Grid>
            }
          </Grid>
          <Grid item xs sx={{ p: 1 }}>
            <FormControl fullWidth>
              <InputLabel>AP Count</InputLabel>
              <Select
                value={apCount}
                onChange={(e) => setApCount(e.target.value)}>
                <MenuItem value={2}>{2}</MenuItem>
                <MenuItem value={4}>{4}</MenuItem>
                <MenuItem value={6}>{6}</MenuItem>
                <MenuItem value={8}>{8}</MenuItem>
                <MenuItem value={12}>{12}</MenuItem>
                <MenuItem value={14}>{14}</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs sx={{ p: 1 }}>
            <FormControl fullWidth>
              <InputLabel>AP Beam Width</InputLabel>
              <Select
                value={beamWidth}
                onChange={(e) => setBeamWidth(e.target.value)}>
                <MenuItem value={30}>{30}</MenuItem>
                <MenuItem value={60}>{60}</MenuItem>
                <MenuItem value={90}>{90}</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      ),
    },
    {
      label: 'Uplinks and Downlinks',
      contents: (
        <Grid container direction='column' sx={{ p: 1 }}>
          <Grid container item direction={'column'}>
            <Grid item xs sx={{ p: 1 }}>
              <FormControlLabel
                labelPlacement='start'
                control={
                  <Switch
                    checked={fiberSite}
                    onChange={() => setFiberSite(!fiberSite)}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                }
                label={'Fiber Uplink'}
              />
            </Grid>
            {fiberSite && (
              <>
                <Grid item xs sx={{ p: 1 }}>
                  <FormControl fullWidth>
                    <InputLabel>Fiber Interface</InputLabel>
                    <Select
                      value={fiberInterfaceName}
                      onChange={(e) => setFiberInterfaceName(e.target.value)}>
                      {routerInterfaces?.map((i) => {
                        return (
                          <MenuItem key={i} value={i}>
                            {i}
                          </MenuItem>
                        )
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs sx={{ p: 1 }}>
                  <TextField
                    autoComplete='off'
                    fullWidth
                    label='Wichita VLAN'
                    value={wichitaVlan}
                    onChange={(e) => setWichitaVlan(e.target.value)}
                  />
                </Grid>
                <Grid item xs sx={{ p: 1 }}>
                  <TextField
                    autoComplete='off'
                    fullWidth
                    label='Wichita Network'
                    value={wichitaNetwork}
                    onChange={(e) => setWichitaNetwork(e.target.value)}
                  />
                </Grid>
                <Grid item xs sx={{ p: 1 }}>
                  <TextField
                    autoComplete='off'
                    fullWidth
                    label='Kansas City Vlan'
                    value={kcVlan}
                    onChange={(e) => setKcVlan(e.target.value)}
                  />
                </Grid>
                <Grid item xs sx={{ p: 1 }}>
                  <TextField
                    autoComplete='off'
                    fullWidth
                    label='Kansas City Network'
                    value={kcNetwork}
                    onChange={(e) => setKcNetwork(e.target.value)}
                  />
                </Grid>
              </>
            )}
          </Grid>
          <Grid container item direction='row'>
            {bhList.map((x, i) => {
              return (
                <Grid item container justifyContent={'space-between'}>
                  <Grid item xs sx={{ p: 1 }}>
                    <TextField
                      autoComplete='off'
                      name='remoteSite'
                      label={'Remote Site Name'}
                      type='text'
                      variant='standard'
                      value={x.remoteSite}
                      onChange={(e) => handleBackhauls(e, i)}
                    />
                  </Grid>
                  <Grid item xs sx={{ p: 1 }}>
                    <TextField
                      autoComplete='off'
                      name='bhNetwork'
                      fullWidth
                      label={`BH10${i + 1} Network`}
                      value={x.fiberNetwork}
                      onChange={(e) => handleBackhauls(e, i)}
                    />
                  </Grid>
                  <Grid item xs sx={{ p: 1 }}>
                    <FormControl fullWidth>
                      <InputLabel>BH Interface</InputLabel>
                      <Select
                        name='bhInterface'
                        value={x.bhInterface}
                        label='BH Interface'
                        onChange={(e) => handleBackhauls(e, i)}>
                        {routerInterfaces?.map((i) => {
                          return (
                            <MenuItem key={i} value={i}>
                              {i}
                            </MenuItem>
                          )
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs sx={{ p: 1 }}>
                    <FormControl fullWidth>
                      <InputLabel>Use first, or last IP</InputLabel>
                      <Select
                        name='isFirst'
                        value={x.isFirst}
                        label='Use first, or last IP'
                        onChange={(e) => handleBackhauls(e, i)}>
                        <MenuItem value={true}>First</MenuItem>
                        <MenuItem value={false}>Last</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item>
                    <IconButton
                      aria-label='delete'
                      onClick={() => handleRemoveClick(i)}>
                      <DeleteIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              )
            })}
          </Grid>
          <Grid item xs sx={{ p: 1 }}>
            <Button
              fullWidth
              variant={'contained'}
              aria-label='Add BH'
              onClick={handleAddClick}
              endIcon={<AddIcon />}>
              Wireless Backhaul
            </Button>
          </Grid>
        </Grid>
      ),
    },
    {
      label: 'Routing Configuration',
      contents: (
        <Grid container direction={'column'}>
          <SelectAllTransferList title='bridge' interfaces={routerInterfaces} selectedInterfaces={selectedInterfaces} setSelectedInterfaces={setSelectedInterfaces} />
          <pre>{JSON.stringify(selectedInterfaces, null, 4)}</pre>
        </Grid>
      ),
    },
    {
      label: 'IP Details',
      contents: (
        <Grid container direction={'column'}>
          <Grid item container direction='row'>
            <Grid item xs sx={{ p: 1 }}>
              <TextField
                autoComplete='off'
                fullWidth
                label='Managment VLAN'
                value={managementVlanId}
                onChange={(e) => setManagementVlanId(e.target.value)}
              />
            </Grid>
            <Grid item xs sx={{ p: 1 }}>
              <TextField
                autoComplete='off'
                fullWidth
                label='HSI VLAN'
                value={hsiVlanId}
                onChange={(e) => setHsiVlanId(e.target.value)}
              />
            </Grid>
          </Grid>
          <Grid item xs sx={{ p: 1 }}>
            <TextField
              autoComplete='off'
              fullWidth
              label='Managment Network'
              value={managementNetwork}
              onChange={(e) => {
                setManagementNetwork(e.target.value)
                let arr = e.target.value.split('.')
                let cgNetwork = ['100', arr[1] - 41, arr[2], arr[3]].join('.')
                setCgNatNetwork(cgNetwork)
              }}
            />
          </Grid>
          <Grid item xs sx={{ p: 1 }}>
            <TextField
              autoComplete='off'
              fullWidth
              label='CgNat Network'
              value={cgNatNetwork}
              onChange={(e) => setCgNatNetwork(e.target.value)}
            />
          </Grid>
          <Grid item xs sx={{ p: 1 }}>
            <TextField
              autoComplete='off'
              fullWidth
              label='Public Nat Address'
              value={cgNatPublic}
              onChange={(e) => setCgNatPublic(e.target.value)}
            />
          </Grid>
          <Grid item xs sx={{ p: 1 }}>
            <TextField
              autoComplete='off'
              fullWidth
              label='Public Network'
              value={publicNetwork}
              onChange={(e) => setPublicNetwork(e.target.value)}
            />
          </Grid>
        </Grid>
      ),
    },
  ]

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const handleReset = () => {
    setActiveStep(0)
  }

  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <DialogTitle>New Site</DialogTitle>
      <DialogContent sx={{ p: 3 }}>
        <Box sx={{ p: 2, width: '100%' }}>
          <Stepper alternativeLabel activeStep={activeStep}>
            {steps.map((step, index) => {
              const stepProps = {}
              const labelProps = {}
              return (
                <Step key={step.label} {...stepProps}>
                  <StepLabel {...labelProps}>{step.label}</StepLabel>
                </Step>
              )
            })}
          </Stepper>
          {activeStep === steps.length ? (
            <React.Fragment>
              <Typography sx={{ mt: 2, mb: 1 }}>
                All steps completed - you&apos;re finished
              </Typography>
              <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Box sx={{ flex: '1 1 auto' }} />
                <Button onClick={handleReset}>Reset</Button>
              </Box>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Typography sx={{ mt: 2, mb: 1 }}>
                {steps[activeStep].contents}
              </Typography>
              <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Button
                  variant={'contained'}
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  sx={{ mr: 1 }}>
                  Back
                </Button>
                <Box sx={{ flex: '1 1 auto' }} />

                {activeStep === steps.length - 1 ? (
                  <>
                    <Button variant={'contained'} onClick={handleCreateSite}>
                      Submit
                    </Button>
                  </>
                ) : (
                  <Button variant={'contained'} onClick={handleNext} disabled={!siteLatitude || !siteLongitude || !siteName || !siteType || !siteHeight}>
                    Next
                  </Button>
                )}
              </Box>
            </React.Fragment>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default SiteCreate
