import React, { useState } from 'react'
import { useGetSites, useJoinSitesListRoom } from '../hooks'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Box, CircularProgress } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import CreateNewSite from '../Components/Network/SiteDashboard/CreateNewSite'
import NetworkMap from '../Components/Maps/Geolocation/NetworkMap'
import NetworkBar from '../Components/Network/NetworkBar'
import { useTheme } from '@emotion/react'
import { useAppContext } from '../context/AppProvider'
import Moment from 'react-moment'

const SitesListScreen = () => {
  const theme = useTheme()
  const { user } = useAppContext()

  console.log('Rendering SiteListScreen')

  useJoinSitesListRoom()

  const { sites, loading } = useSelector((state) => state.wsSites)
  const dataLinks = useSelector((state) => state.wsDataLinks)
  const [newSite, setNewSite] = useState(false)

  const columns = [
    {
      headerName: 'Activation Date',
      field: 'createdAt',
      minWidth: 100,
      flex: 0.5,
      renderCell: (params) => {
        return <Moment format="YYYY/MM/DD">{params.row.createdAt}</Moment>
      },
    },
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
      renderCell: (params) => (
        <Link
          style={{ textDecoration: 'inherit', color: 'inherit' }}
          to={`/network/site/${params.row._id}`}
        >
          {params.value}
        </Link>
      ),
    },
    {
      field: 'services',
      headerName: 'Services',
      flex: 1,
      valueGetter: (params) => {
        return params.row.services.length
      },
    },
    {
      field: 'devices',
      headerName: 'Unassigned Devices',
      flex: 1,
      valueGetter: (params) => {
        return params.row.devices.filter(
          (device) => !device.backbone && !device.services?.length
        ).length
      },
    },
    {
      field: 'category',
      headerName: 'Category',
      flex: 1,
    },
    {
      field: 'mrc',
      headerName: 'MRC',
      type: 'number',
      flex: 0.5,
      valueGetter: (params) => {
        return Number(params.row.mrc).toFixed(2)
      },
    },
    ...(user._id === '64078985ea26f061704e41e2' ||
    user._id === '61e050c1eb5d9292899aed8b' ||
    user._id === '61dc5551eb5d9292898df3d8' ||
    user._id === '61dc52b6eb5d9292898de8a6' ||
    user._id === '61e9afa7b7ec4791fa1e0976' ||
    user._id === '62dad41cbf879fb798ab20f4' ||
    user._id === '61dc54d5eb5d9292898df197' ||
    user._id === '641e12c3f1585483657535e1' ||
    user._id === '61dccffaeb5d9292898f7397' ||
    user._id === '627bcd5ab7ec4791fadf8da7' ||
    user._id === '637e4cbdea26f06170027014' ||
    user._id === '62d6bca6bf879fb798377b18' ||
    user._id === '6205893bb7ec4791fadbe0c3'
      ? [
          {
            field: 'mrr',
            headerName: 'MRR',
            type: 'number',
            flex: 0.5,
            valueGetter: (params) => {
              return Number(params.row.mrr.toFixed(2))
            },
          },
        ]
      : []),
    ...(user._id === '64078985ea26f061704e41e2' ||
    user._id === '61e050c1eb5d9292899aed8b' ||
    user._id === '61dc5551eb5d9292898df3d8' ||
    user._id === '61dc52b6eb5d9292898de8a6' ||
    user._id === '61e9afa7b7ec4791fa1e0976' ||
    user._id === '62dad41cbf879fb798ab20f4' ||
    user._id === '61dc54d5eb5d9292898df197' ||
    user._id === '641e12c3f1585483657535e1' ||
    user._id === '61dccffaeb5d9292898f7397' ||
    user._id === '627bcd5ab7ec4791fadf8da7' ||
    user._id === '637e4cbdea26f06170027014' ||
    user._id === '62d6bca6bf879fb798377b18' ||
    user._id === '6205893bb7ec4791fadbe0c3'
      ? [
          {
            field: 'total_mrr',
            type: 'number',
            headerName: 'Cumulative MRR',
            flex: 0.5,
            valueGetter: (params) => {
              return Number(
                (params.row.mrr + params.row.dependent_mrr).toFixed(2)
              )
            },
          },
        ]
      : []),
  ]

  return (
    <>
      {loading ? (
        <Box
          sx={{
            display: 'flex',
            flexGrow: 1,
            height: `calc(100vh - ${theme.mixins.toolbar.minHeight}px)`,
            width: '100%',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          <NetworkBar newSite={newSite} setNewSite={setNewSite} />
          <Box>{newSite && <CreateNewSite />}</Box>
          {sites.length > 0 && (
            <NetworkMap
              sites={sites}
              dataLinks={dataLinks ? dataLinks : { dataLinks: [] }}
            />
          )}
          <div style={{ display: 'flex', flexGrow: 1 }}>
            <DataGrid
              initialState={{
                sorting: {
                  sortModel: [{ field: 'name', sort: 'asc' }],
                },
              }}
              autoHeight
              rowHeight={30}
              getRowId={(row) => row._id}
              rows={sites}
              columns={columns}
            />
          </div>
        </>
      )}
    </>
  )
}

export default SitesListScreen
