import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  events: [],
  loading: false,
}

export const wsEvents = createSlice({
  name: 'events',
  initialState,
  reducers: {
    resetEvents: () => initialState,
    requestEvents: () => ({
      events: [],
      loading: true
    }),
    receiveEvents: (state, action) => {
      state.events = action.payload
      state.loading = false
    },
  },
})

export const { resetEvents, requestEvents, receiveEvents } = wsEvents.actions

export default wsEvents.reducer