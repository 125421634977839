import { Avatar, Divider, List, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Moment from 'react-moment'


const EventList = ({ events }) => {

  return (
    <List sx={{ width: '100%' }}>
      {
        events.map((event) => {
          let eventText = ''
          switch (event.event) {
            case 'deviceAdded':
              eventText = `${event.device.name} added by ${event.user.fullName}.`
              break
            case 'deviceProvisioned':
              eventText = `${event.device.name} provisioned by ${event.user.fullName}.`
              break
            case 'rebootSuccess':
              eventText = `${event.user.fullName} rebooted ${event.device.name} successfully.`
              break
            case 'rebootFailed':
              eventText = `${event.user.fullName} failed to reboot ${event.device.name}.`
              break
            case 'firmwareUpgraded':
              eventText = `${event.user.fullName} successfully changed firmware on ${event.device.name}.`
              break
            case 'firmwareUpgradeFailed':
              eventText = `${event.user.fullName} failed to change firmware on ${event.device.name}.`
              break
            case 'assigned':
              eventText = `${event.user.fullName} assigned ${event.device.name}  to ${event.site.name}.`
              break
            default:
              eventText = `(Unknown event) ${event.user.fullName} ${event.device.name}.`
              break
          }
          return (
            <>
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar alt={event.user.fullName} src={event.user.picture} />
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <Typography
                      sx={{ display: 'inline' }}
                      component="span"
                      variant="body2"
                      color="text.primary"
                    >
                      {eventText}
                    </Typography>
                  }
                  secondary={
                    <>
                      <Moment format='MMMM Do YYYY, h:mm:ss a'>{event.createdAt}</Moment>
                      {` - ${event.user.fullName}`}
                    </>
                  }
                />
              </ListItem>
              <Divider variant="inset" component="li" />
            </>
          )
        })
      }
    </List>
  )
}

export default EventList