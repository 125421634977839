
import React, { useState } from 'react'
import { FormControl, Grid, IconButton, InputLabel, MenuItem, Select, TextField } from "@mui/material"
import DeleteIcon from '@mui/icons-material/Delete'

const InterfaceForm = ({ routerInterfaces, setRouterInterfaces, iface, ifaceIndex }) => {

  const handleInterfaces = (e, index) => {
    const { name, value } = e.target
    const list = [...routerInterfaces]
    list[index][name] = value
    setRouterInterfaces(list)
  }
  // handle remove Interface
  const handleRemoveInterface = (index) => {
    const list = [...routerInterfaces]
    list.splice(index, 1)
    setRouterInterfaces(list)
  }

  return (
    <Grid item container justifyContent={'space-between'}>
      <Grid item md sx={{ p: 1 }}>
        <TextField
          size="small"
          fullWidth
          autoComplete='off'
          name='name'
          label={'Interface'}
          type='text'
          variant="filled"
          value={iface.name}
          onChange={(e) => handleInterfaces(e, ifaceIndex)}
        />
      </Grid>
      <Grid item md sx={{ p: 1 }}>
        <TextField
          size="small"
          fullWidth
          autoComplete='off'
          name='comment'
          label={'Comment'}
          type='text'
          variant="filled"
          value={iface.comment}
          onChange={(e) => handleInterfaces(e, ifaceIndex)}
        />
      </Grid>
      <Grid item md sx={{ p: 1 }}>
        <FormControl fullWidth size="small">
          <InputLabel>iface Type</InputLabel>
          <Select
            name='type'
            value={iface.type}
            onChange={(e) => handleInterfaces(e, ifaceIndex)}>
            <MenuItem value={'ethernet'}>{'Ethernet'}</MenuItem>
            <MenuItem value={'bridge'}>{'Bridge'}</MenuItem>
            <MenuItem value={'vlan'}>{'VLAN'}</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs sx={{ p: 1 }}>
        <TextField
          disabled={(iface.type === 'ethernet' || iface.type === 'bridge') && routerInterfaces.filter(el => el.name === iface.parent)[0]?.type !== 'bridge'}
          variant="filled"
          size="small"
          autoComplete='off'
          name='vlanId'
          fullWidth
          label={iface.type === 'vlan' ? 'VLAN ID' : 'Untagged VLAN'}
          value={iface.vlanId}
          onChange={(e) => handleInterfaces(e, ifaceIndex)}
        />
      </Grid>
      {
        iface.type === 'vlan'
          ? <Grid item xs sx={{ p: 1 }}>
            <FormControl fullWidth size="small">
              <InputLabel>Parent</InputLabel>
              <Select
                name='parent'
                value={iface.parent}
                onChange={(e) => handleInterfaces(e, ifaceIndex)}>
                {routerInterfaces?.map((i) => {
                  return (
                    <MenuItem key={i} value={i.name}>
                      {i.name}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </Grid>
          : <Grid item xs sx={{ p: 1 }}>
            <FormControl fullWidth size="small">
              <InputLabel>Bridge</InputLabel>
              <Select
                disabled={iface.type === 'bridge'}
                name='parent'
                value={iface.parent}
                onChange={(e) => handleInterfaces(e, ifaceIndex)}>
                {routerInterfaces?.map((i) => {
                  if (i.type === 'bridge') {
                    return (
                      <MenuItem key={i} value={i.name}>
                        {i.name}
                      </MenuItem>
                    )
                  }
                })}
              </Select>
            </FormControl>
          </Grid>
      }
      <Grid item>
        <IconButton
          aria-label='delete'
          onClick={() => handleRemoveInterface(ifaceIndex)}>
          <DeleteIcon />
        </IconButton>
      </Grid>
    </Grid>
  )
}

export default InterfaceForm