import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  dispatchables: {},
  loading: false,
  error: null,
}

export const dispatchablesSlice = createSlice({
  name: 'dispatchables',
  initialState,
  reducers: {
    resetDispatchables: () => initialState,
    requestDispatchables: (state) => {
      state.loading = true
    },
    receiveDispatchables: (state, action) => {
      state.loading = false
      state.dispatchables = action.payload
      state.error = null
    }
  },
})

export const { resetDispatchables, requestDispatchables, receiveDispatchables } =
  dispatchablesSlice.actions

export default dispatchablesSlice.reducer
