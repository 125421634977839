const ltuRocket = [
  `### PART ###`,
  `## 1640879798`,
  `##aflturocket.v2.1.0`,
  `#board.sysid=0xae05`,
  `#board.cpurevision=0xdf78704e`,
  `#board.name=LTU-Rocket`,
  `#board.shortname=LTUR`,
  `#board.model=LTU-Rocket`,
  `#board.netmodes=4`,
  `#board.hwaddr=E063DA0F894A`,
  `#board.reboot=80`,
  `#board.upgrade=240`,
  `#board.phycount=1`,
  `#board.phy.1.hwaddr=E663DA0F894A`,
  `#board.phy.1.maxmtu=9000`,
  `#board.gps=1`,
  `#board.qca8337n=0`,
  `#board.qcagmac=0`,
  `#board.waferid=20`,
  `#board.chip_rev=d`,
  `#board.rev=7`,
  `#board.fcc_id=SWX-LTUR`,
  `#board.ic_id=6545A-LTUR`,
  `#board.min_separation_distance=128`,
  `#board.bom=21-02861-21`,
  `#board.required_fw_version=131076`,
  `#uuid.factory.default=a34b9c82-5366-45ca-b377-586591569de3`,
  `#uuid.user.configured=7108b460-fe5b-4f7a-abd4-9153b8046e18`,
  `#radio.1.name=Radio`,
  `#radio.1.shortname=radio`,
  `#radio.1.bus=pci`,
  `#radio.1.ccode=840`,
  `#radio.1.subsystemid=0xae05`,
  `#radio.1.subvendorid=0x168c`,
  `#radio.1.txpower.max=30`,
  `#radio.1.txpower.min=-10`,
  `#radio.1.txpower.offset=0`,
  `#radio.1.modes=0`,
  `#radio.1.ieee_modes=0`,
  `#radio.1.antennas=11`,
  `#radio.1.def_antenna=0`,
  `#radio.1.antenna.1.id=1`,
  `#radio.1.antenna.1.name=AMO-5G10`,
  `#radio.1.antenna.1.gain=10`,
  `#radio.1.antenna.1.builtin=0`,
  `#radio.1.antenna.2.id=2`,
  `#radio.1.antenna.2.name=AMO-5G13`,
  `#radio.1.antenna.2.gain=13`,
  `#radio.1.antenna.2.builtin=0`,
  `#radio.1.antenna.3.id=3`,
  `#radio.1.antenna.3.name=AM-5G16-120`,
  `#radio.1.antenna.3.gain=16`,
  `#radio.1.antenna.3.builtin=0`,
  `#radio.1.antenna.4.id=4`,
  `#radio.1.antenna.4.name=AM-5G17-90`,
  `#radio.1.antenna.4.gain=17`,
  `#radio.1.antenna.4.builtin=0`,
  `#radio.1.antenna.5.id=5`,
  `#radio.1.antenna.5.name=AM-M-V5G-Ti`,
  `#radio.1.antenna.5.gain=17`,
  `#radio.1.antenna.5.builtin=0`,
  `#radio.1.antenna.6.id=6`,
  `#radio.1.antenna.6.name=AM-5G19-120`,
  `#radio.1.antenna.6.gain=19`,
  `#radio.1.antenna.6.builtin=0`,
  `#radio.1.antenna.7.id=7`,
  `#radio.1.antenna.7.name=AM-5G20-90`,
  `#radio.1.antenna.7.gain=20`,
  `#radio.1.antenna.7.builtin=0`,
  `#radio.1.antenna.8.id=8`,
  `#radio.1.antenna.8.name=AM-V5G-Ti`,
  `#radio.1.antenna.8.gain=21`,
  `#radio.1.antenna.8.builtin=0`,
  `#radio.1.antenna.9.id=9`,
  `#radio.1.antenna.9.name=AM-5AC21-60`,
  `#radio.1.antenna.9.gain=21`,
  `#radio.1.antenna.9.builtin=0`,
  `#radio.1.antenna.10.id=10`,
  `#radio.1.antenna.10.name=AM-5AC22-45`,
  `#radio.1.antenna.10.gain=22`,
  `#radio.1.antenna.10.builtin=0`,
  `#radio.1.antenna.11.id=11`,
  `#radio.1.antenna.11.name=AP-5AC-90-HD`,
  `#radio.1.antenna.11.gain=22`,
  `#radio.1.antenna.11.builtin=0`,
  `#radio.1.regdomain_flags=""`,
  `#radio.1.ptmp_only=1`,
  `#radio.1.connectorized=1`,
  `#radio.1.distance_limit_km=0;`,
  `#radio.1.chanbw="10,20,30,40,50"`,
  `#feature.rssi.leds=0`,
  `#feature.gps=1`,
  `#feature.gps.leds=0`,
  `#feature.accel=1`,
  `#feature.ext_reset=0`,
  `#device.id=c021efc0fa574917ff4db240fff9affb`,
  `#3a54fb59f1195049ce9917b1f89e5870`,
  `### PART ###`,
  'accel.fixset=1',
  'accel.movedeg=5',
  'accel.status=enabled',
  'ame.net.jumbo=disabled',
  'ame.net.lan_en=enabled',
  'bridge.1.devname=br0',
  'bridge.1.port.1.devname=ath0',
  'bridge.1.port.1.status=enabled',
  'bridge.1.status=enabled',
  'bridge.2.devname=br-mvlan',
  'bridge.2.port.1.devname=ath0.800',
  'bridge.2.port.1.status=enabled',
  'bridge.2.status=enabled',
  'bridge.status=enabled',
  'bt_pan.1.http=enabled',
  'bt_pan.1.ip_hostid=10',
  'bt_pan.1.pincode=9876543210',
  'bt_pan.1.ssh=disabled',
  'bt_pan.1.status=enabled',
  'bt_pan.1.timeout=900',
  'bt_pan.status=enabled',
  'bt.mode=2',
  'carrier_drop.blockafterpulse=disabled',
  'carrier_drop.firstsessiontimer=0',
  'carrier_drop.firstsessionwait=disabled',
  'carrier_drop.mincapholdoff=0',
  'carrier_drop.mincapholdoffup=0',
  'carrier_drop.minrxcapacity=0',
  'carrier_drop.minrxcapacityup=0',
  'carrier_drop.mintxcapacity=0',
  'carrier_drop.mintxcapacityup=0',
  'carrier_drop.operation=0',
  'carrier_drop.pulse_duration=5',
  'carrier_drop.tracknosession=disabled',
  'cent_mgmt.cfg.status=enabled',
  'discovery.cdp.status=disabled',
  'discovery.status=enabled',
  'gui.language=en_US',
  'gui.network.advanced.status=disabled',
  'httpd.https.port=443',
  'httpd.https.status=enabled',
  'httpd.port=80',
  'httpd.session.timeout=900',
  'httpd.status=enabled',
  'igmpproxy.status=disabled',
  'info.cfg.editor.ui=2021-12-30T15:53:45.475Z',
  'netconf.1.autoip.status=disabled',
  'netconf.1.devname=ath0',
  'netconf.1.flowcontrol.rx.status=enabled',
  'netconf.1.flowcontrol.tx.status=enabled',
  'netconf.1.mtu=1500',
  'netconf.1.speed=auto',
  'netconf.1.status=enabled',
  'netconf.1.up=enabled',
  'netconf.2.autoip.status=disabled',
  'netconf.2.devname=ath0.800',
  'netconf.2.mtu=1500',
  'netconf.2.status=enabled',
  'netconf.3.autoip.status=disabled',
  'netconf.3.devname=br0',
  'netconf.3.mtu=1500',
  'netconf.3.status=enabled',
  'netconf.4.autoip.status=disabled',
  'netconf.4.devname=br-mvlan',
  'netconf.4.mtu=1500',
  'netconf.4.netmask=255.255.255.0',
  'netconf.4.role=mlan',
  'netconf.4.status=enabled',
  'netconf.status=enabled',
  'netmode=bridge_h1',
  'ntpclient.1.server=0.ubnt.pool.ntp.org',
  'ntpclient.1.status=enabled',
  'ntpclient.status=enabled',
  'pwdog.delay=300',
  'pwdog.period=300',
  'pwdog.retry=3',
  'pwdog.status=disabled',
  'qos.dscp.1.priority=1',
  'qos.dscp.1.status=enabled',
  'qos.dscp.1.value=8',
  'qos.dscp.2.priority=3',
  'qos.dscp.2.status=enabled',
  'qos.dscp.2.value=10',
  'qos.dscp.3.priority=3',
  'qos.dscp.3.status=enabled',
  'qos.dscp.3.value=18',
  'qos.dscp.4.priority=4',
  'qos.dscp.4.status=enabled',
  'qos.dscp.4.value=26',
  'qos.dscp.5.priority=5',
  'qos.dscp.5.status=enabled',
  'qos.dscp.5.value=34',
  'qos.dscp.6.priority=6',
  'qos.dscp.6.status=enabled',
  'qos.dscp.6.value=46',
  'qos.dscp.7.priority=7',
  'qos.dscp.7.status=enabled',
  'qos.dscp.7.value=48',
  'qos.dscp.8.priority=7',
  'qos.dscp.8.status=enabled',
  'qos.dscp.8.value=56',
  'qos.dscp.status=enabled',
  'qos.pcp.1.priority=0',
  'qos.pcp.1.status=enabled',
  'qos.pcp.1.value=0',
  'qos.pcp.2.priority=1',
  'qos.pcp.2.status=enabled',
  'qos.pcp.2.value=1',
  'qos.pcp.3.priority=2',
  'qos.pcp.3.status=enabled',
  'qos.pcp.3.value=2',
  'qos.pcp.4.priority=3',
  'qos.pcp.4.status=enabled',
  'qos.pcp.4.value=3',
  'qos.pcp.5.priority=4',
  'qos.pcp.5.status=enabled',
  'qos.pcp.5.value=4',
  'qos.pcp.6.priority=5',
  'qos.pcp.6.status=enabled',
  'qos.pcp.6.value=5',
  'qos.pcp.7.priority=6',
  'qos.pcp.7.status=enabled',
  'qos.pcp.7.value=6',
  'qos.pcp.8.priority=7',
  'qos.pcp.8.status=enabled',
  'qos.pcp.8.value=7',
  'qos.pcp.status=enabled',
  'radio.1.antenna.id=0',
  'radio.1.auto_txpower=enabled',
  'radio.1.autofreqmode=disabled',
  'radio.1.cable.loss=0',
  'radio.1.chanbw=40',
  'radio.1.czlen=10',
  'radio.1.devname=ath0',
  'radio.1.dfs.status=enabled',
  'radio.1.distance_scale=2',
  'radio.1.duty_cycle=667',
  'radio.1.frame_period=2000',
  'radio.1.freq=5285',
  'radio.1.mode=master',
  'radio.1.ptpmode=0',
  'radio.1.reg_obey=disabled',
  'radio.1.rf_mode=0',
  'radio.1.rxfreq=5285',
  'radio.1.rxgain.auto=enabled',
  'radio.1.rxgain=60',
  'radio.1.rxtarget=-60',
  'radio.1.splitfreqmode=disabled',
  'radio.1.status=enabled',
  'radio.1.rxfreq=5285',
  'radio.1.txpower_max=30',
  'radio.1.txpower_min=-10',
  'radio.1.txpower=28',
  'radio.countrycode=840',
  'radio.status=enabled',
  'resolv.host.1.status=enabled',
  'resolv.nameserver.1.ip=1.1.1.1',
  'resolv.nameserver.1.status=enabled',
  'resolv.nameserver.2.ip=8.8.8.8',
  'resolv.nameserver.2.status=enabled',
  'resolv.nameserver.status=enabled',
  'route.1.devname=br-mvlan',
  'route.1.ip=0.0.0.0',
  'route.1.netmask=0',
  'route.1.status=enabled',
  'route.status=enabled',
  'snmp.rwcommunity.status=disabled',
  'snmp.status=disabled',
  'sshd.auth.passwd=enabled',
  'sshd.port=22',
  'sshd.status=enabled',
  'syslog.klog_level=-1',
  'syslog.remote.ip=192.168.66.22',
  'syslog.remote.port=514',
  'syslog.remote.status=disabled',
  'syslog.status=enabled',
  'system.autosend_crash_reports=disabled',
  'system.cfg.version=131081',
  'system.date.status=disabled',
  'system.external.reset=enabled',
  'system.imperial_units.status=disabled',
  'system.timezone=CST6CDT,M3.2.0,M11.1.0',
  'telnetd.port=23',
  'telnetd.status=disabled',
  'tshaper.status=disabled',
  'unms.status=enabled',
  'unms.uri=wss://uisp.ideatek.com:443+CMnVFBUqPiVAcsJ4xXhBg2DirMr8W3JGrcEpBEdnVnGORVkY+allowSelfSignedCertificate',
  'update.cent.status=enabled',
  'update.check.status=enabled',
  'users.1.name=wireless',
  'users.1.password=$1$Km5bMhvi$Esa6n8JGFREs1q370dwGl1',
  'users.1.status=enabled',
  'users.2.gid=100',
  'users.2.shell=/bin/false',
  'users.2.status=disabled',
  'users.2.uid=100',
  'users.status=enabled',
  'vlan.1.devname=ath0',
  'vlan.1.id=800',
  'vlan.1.status=enabled',
  'vlan.status=enabled',
  'wireless.1.devname=ath0',
  'wireless.1.drv_en=1',
  'wireless.1.frame_offset=0',
  'wireless.1.l2_isolation=enabled',
  'wireless.1.mac_acl.status=disabled',
  'wireless.1.max_sq_cpe=101',
  'wireless.1.mcast.enhance=1',
  'wireless.1.rate.mcs=4',
  'wireless.1.scan_list.status=disabled',
  'wireless.1.security.psk=l924a221',
  'wireless.1.security=WPA2-PSK',
  'wireless.1.status=enabled',
  'wireless.1.sync_mode=2',
  'wireless.status=enabled',
]

export const createLtuConfigs = (
  siteName,
  managementNet,
  beamWidth,
  apCount
) => {
  console.log(beamWidth)
  let coverage = 360 / apCount
  let configs = []
  let configBlobs = []
  let ap = {}
  switch (beamWidth) {
    case 30:
      ap.gain = 20
      break
    case 60:
      ap.gain = 17
      break
    case 90:
      ap.gain = 22
      break
  }
  for (let i = 0; i < apCount; i++) {
    console.log(i)
    let thisConfig = ltuRocket.filter(
      (el) =>
        !el.startsWith('route.1.gateway') ||
        !el.startsWith('netconf.4.ip') ||
        !el.startsWith('resolv.host.1.name') ||
        !el.startsWith('wireless.1.ssid') ||
        !el.startsWith('radio.1.antenna.gain')
    )
    let managementIp =
      managementNet.first.split('.').slice(0, 3).join('.') + '.' + (10 + i)
    thisConfig.push(`radio.1.antenna.gain=${ap.gain}`)
    thisConfig.push(`route.1.gateway=${managementNet.first}`)
    thisConfig.push(`netconf.4.ip=${managementIp}`)
    thisConfig.push(`resolv.host.1.name=${siteName} ${i * coverage}`)
    thisConfig.push(`wireless.1.ssid=${siteName} ${i * coverage}`)
    configs.push({
      apName: `${siteName} ${i * coverage}`,
      config: thisConfig.join('\r\n'),
    })
    console.log(`Pushing config ${i * coverage} to array...`)
  }
  configs.forEach((el) => {
    let file = new Blob([el.config], { type: 'text/plain;charset=utf-8' })
    configBlobs.push({ name: el.apName, file })
  })
  return configBlobs
}
