import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  dataLinks: [],
  loading: false,
  error: null,
}

export const wsDataLinksSlice = createSlice({
  name: 'datalinks',
  initialState,
  reducers: {
    resetDataLinks: () => initialState,
    requestDataLinks: (state) => {
      state.loading = true
    },
    receiveDataLinks: (state, action) => {
      state.loading = false
      state.dataLinks = action.payload
      state.error = null
    },
  },
})

export const { resetDataLinks, requestDataLinks, receiveDataLinks } =
  wsDataLinksSlice.actions

export default wsDataLinksSlice.reducer
