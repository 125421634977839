import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  results: [],
  loading: false,
  error: null,
}

export const wsMbsSearch = createSlice({
  name: 'mbsSearchResults',
  initialState,
  reducers: {
    resetMbsSearch: () => initialState,
    requestMbsSearch: (state) => {
      state.loading = true
    },
    receiveMbsSearch: (state, action) => {
      state.results = action.payload
      state.loading = false
      state.error = null
    },
  },
})

export const { resetMbsSearch, requestMbsSearch, receiveMbsSearch } =
  wsMbsSearch.actions

export default wsMbsSearch.reducer
