import React, { useEffect, useState } from 'react'
import { useAppContext } from './context/AppProvider'
import MainScreen from './Screens/MainScreen'

const App = () => {

  const { isLoading } = useAppContext()

  console.log('Rendering App', isLoading)

  return (
    <>
      {
        !isLoading
        && <MainScreen />
      }
    </>
  )
}

export default App
