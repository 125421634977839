import React from 'react'
import { Link } from 'react-router-dom'
import {
  Card, CardContent, Typography, Chip, Grid, Tooltip, Divider,
  List, ListItem, ListItemText, ListItemIcon, IconButton
} from '@mui/material'

import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import WarningIcon from '@mui/icons-material/Warning'
import { generateUrl } from '../../utils/generateUrl'
import DeviceMenu from '../Core/Device/DeviceMenu'

const DeviceCard = ({ device }) => {
  const formatDateTime = (dateTime) => new Date(dateTime).toLocaleString()

  return (
    <Card>
      <CardContent>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Link
              style={{ textDecoration: 'inherit', color: 'inherit' }}
              to={generateUrl(device)}>
              <Typography variant="h5" gutterBottom>
                {device.name}
              </Typography>
            </Link>
          </Grid>
          <Grid item>
            <DeviceMenu device={device} />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography color="textSecondary">{device.model}</Typography>
            <Typography variant="body2">{`MAC: ${device.mac}`}</Typography>
            <Typography variant="body2">{`IP: ${device.ip}`}</Typography>
            <Typography variant="body2">{`Firmware: ${device.firmware}`}</Typography>
            <Chip label={device.connected ? 'Online' : 'Offline'} color={device.connected ? 'primary' : 'secondary'} />
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2">
              <SignalCellularAltIcon /> {`Signal: ${device.wireless.signal}`}
            </Typography>
            <Typography variant="body2">
              <AccessTimeIcon /> {`Last seen: ${formatDateTime(device.lastContact)}`}
            </Typography>
          </Grid>
        </Grid>
        <Divider style={{ margin: '10px 0' }} />
        <List dense>
          {device?.warnings?.map((warning, index) => (
            <ListItem key={index}>
              <ListItemIcon>
                <WarningIcon />
              </ListItemIcon>
              <ListItemText
                primary={warning.description}
              />
            </ListItem>
          ))}
        </List>
      </CardContent>
    </Card>
  )
}

export default DeviceCard