import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  sites: [],
  loading: false,
  error: null,
}

export const wsSitesSlice = createSlice({
  name: 'sites',
  initialState,
  reducers: {
    resetSites: () => initialState,
    requestSites: (state) => {
      state.loading = true
    },
    receiveSites: (state, action) => {
      state.loading = false
      state.sites = action.payload
      state.error = null
    },
  },
})

export const { resetSites, requestSites, receiveSites } = wsSitesSlice.actions

export default wsSitesSlice.reducer
