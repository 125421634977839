import React from 'react'
import { Typography, Grid, Paper } from '@mui/material'
import { useAppContext } from '../../../context/AppProvider'

const classifySite = (site) => {
  switch (true) {
    case site.services.length + site.dependentServices.length > 250 ||
      site.mrr + site.dependent_mrr > 20000:
      return 'A'
    case site.services.length + site.dependentServices.length > 100 ||
      site.mrr + site.dependent_mrr > 5000:
      return 'B'
    case site.services.length + site.dependentServices.length > 50 ||
      site.mrr + site.dependent_mrr > 2500:
      return 'C'
    default:
      return 'D'
  }
}

const SiteOverview = ({ site }) => {
  const { user } = useAppContext()

  return (
    <Paper elevation={5} sx={{ p: 1, flexGrow: 1 }}>
      <Grid container direction="row" justifyContent="space-between">
        <Grid container direction="column">
          <Grid item container direction="row" justifyContent="space-between">
            <Grid item>
              <Typography align="center" variant="body1" component="div">
                Classification:
              </Typography>
            </Grid>
            <Grid item>
              <Typography align="center" variant="body1" component="div">
                {classifySite(site)}
              </Typography>
            </Grid>
          </Grid>
          <Grid item container direction="row" justifyContent="space-between">
            <Grid item>
              <Typography align="center" variant="body1" component="div">
                Category:
              </Typography>
            </Grid>
            <Grid item>
              <Typography align="center" variant="body1" component="div">
                {site.category ? site.category : 'Not set..'}
              </Typography>
            </Grid>
          </Grid>
          <Grid item container direction="row" justifyContent="space-between">
            <Grid item>
              <Typography align="center" variant="body1" component="div">
                Uplink:
              </Typography>
            </Grid>
            <Grid item>{site.isFiberUplink ? 'Fiber' : 'Wireless'}</Grid>
          </Grid>
          {(user._id === '64078985ea26f061704e41e2' ||
            user._id === '61e050c1eb5d9292899aed8b' ||
            user._id === '61dc5551eb5d9292898df3d8' ||
            user._id === '61dc52b6eb5d9292898de8a6' ||
            user._id === '61e9afa7b7ec4791fa1e0976' ||
            user._id === '62dad41cbf879fb798ab20f4' ||
            user._id === '61dc54d5eb5d9292898df197' ||
            user._id === '641e12c3f1585483657535e1' ||
            user._id === '61dccffaeb5d9292898f7397' ||
            user._id === '627bcd5ab7ec4791fadf8da7' ||
            user._id === '637e4cbdea26f06170027014' ||
            user._id === '62d6bca6bf879fb798377b18' ||
            user._id === '6205893bb7ec4791fadbe0c3') && (
            <>
              <Grid
                item
                container
                direction="row"
                justifyContent="space-between"
              >
                <Grid item>
                  <Typography align="center" variant="body1" component="div">
                    MRR:
                  </Typography>
                </Grid>
                <Grid item>${site.mrr.toFixed(2)}</Grid>
              </Grid>
              <Grid
                item
                container
                direction="row"
                justifyContent="space-between"
              >
                <Grid item>
                  <Typography align="center" variant="body1" component="div">
                    Cumulative MRR:
                  </Typography>
                </Grid>
                <Grid item>${(site.mrr + site.dependent_mrr).toFixed(2)}</Grid>
              </Grid>
            </>
          )}
          <Grid item container direction="row" justifyContent="space-between">
            <Grid item>
              <Typography align="center" variant="body1" component="div">
                Services:
              </Typography>
            </Grid>
            <Grid item>
              <Typography align="center" variant="body1" component="div">
                {site.services.length}
              </Typography>
            </Grid>
          </Grid>
          <Grid item container direction="row" justifyContent="space-between">
            <Grid item>
              <Typography align="center" variant="body1" component="div">
                Cumulative Services:
              </Typography>
            </Grid>
            <Grid item>
              <Typography align="center" variant="body1" component="div">
                {site.services.length + site.dependentServices.length}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default SiteOverview
/*


*/
