import React, { useEffect, useState, useMemo, useRef } from 'react'
import VisGraph from 'react-vis-graph-wrapper'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import Tooltip from '@mui/material/Tooltip'
import FullscreenIcon from '@mui/icons-material/Fullscreen'
import LinkIcon from '@mui/icons-material/Link'
import RouteIcon from '@mui/icons-material/Route'
import { MenuItem, Paper, Select, Stack, Typography } from '@mui/material'
import { Box } from '@mui/system'





const getReadableFileSizeString = (fileSizeInBytes) => {
  let i = -1
  const byteUnits = ["kbps", "Mbps", "Gbps", "Tbps", "Pbps", "Ebps", "Zbps", "Ybps"]
  do {
    fileSizeInBytes = fileSizeInBytes / 1000
    i++
  } while (fileSizeInBytes > 1000)

  return Math.max(fileSizeInBytes, 0.1).toFixed(1) + byteUnits[i]
}



const BidirectionalTopology = ({ topo }) => {
  const [selectedEdge, setSelectedEdge] = useState(null)
  const [showFiberUplink, setShowFiberUplink] = useState(true)
  const [showInactiveEdges, setShowInactiveEdges] = useState(false)
  const [edgeLabelType, setEdgeLabelType] = useState('none')
  const [isFullscreen, setIsFullscreen] = useState(false)

  const divRef = useRef(null)

  const setEdgeColor = (direction, active, expired, capacity) => {
    if (!capacity && edgeLabelType === 'capacity') return 'yellow'
    if (expired && !active) {
      return 'purple'
    } else if (expired) return '#EF4B56'
    if (active) {
      if (direction === 'in') return '#23C766'
      if (direction === 'out') return 'cyan'
    } else return 'grey'
  }

  const toggleFullScreen = () => {
    if (!document.fullscreenElement && divRef.current) {
      divRef.current.requestFullscreen().catch(err => {
        console.error(`Error attempting to enable full-screen mode: ${err.message} (${err.name})`)
      })
      setIsFullscreen(true)
    } else if (document.fullscreenElement) {
      document.exitFullscreen().catch(err => {
        console.error(`Error attempting to disable full-screen mode: ${err.message} (${err.name})`)
      })
      setIsFullscreen(false)
    }
  }

  useEffect(() => {
    if (selectedEdge) {
      console.log(`Edge clicked.`, selectedEdge)

    } else console.log(`Edge not clicked.`)
  }, [selectedEdge])

  const handleEdgeSelect = ({edges, event}) => {
    setSelectedEdge(edges[0])
  }


  const handleLabel = edge => {
    switch (edgeLabelType) {
      case 'capacity': return getReadableFileSizeString(edge.capacity)
      case 'throughput': getReadableFileSizeString(edge.throughput)
      case 'cost': return `${edge.interface}::${edge.cost}`
      case 'utilization': return `${Math.round((Number(edge.throughput) / Number(edge.capacity)) * 100)}%`
      default: return ' '
    }
  }

  const legendItems = [
    { color: '#23C766', text: 'Active Inbound' },
    { color: 'cyan', text: 'Active Outbound' },
    { color: '#EF4B56', text: 'Expired' },
    { color: 'purple', text: 'Expired (Inactive)' },
    { color: 'grey', text: 'Inactive' },
    { color: 'yellow', text: 'Unknown Capacity' }
  ]

  const nodesToRender = useMemo(() => {
    return (showFiberUplink
      ? [...topo.nodes, { id: 'WWW', label: 'Fiber', color: 'cyan', active: true, fixed: true }]
      : [...topo.nodes]
    ).map(node => ({
      id: node.id,
      label: node.name,
      color: node.active ? '#23C766' : '#EF4B56'
    }))
  }, [topo.nodes, showFiberUplink])

  const edgesToRender = useMemo(() => {
    return topo.edges.map(edge => ({
      id: edge.id,
      active: edge.active,
      to: edge.to,
      from: edge.from,
      color: setEdgeColor(edge.direction, edge.active, edge.expired, edge.capacity),
      label: handleLabel(edge),
      value: edge.throughput
    })).filter(edge => showInactiveEdges || edge.active)
  }, [topo.edges, edgeLabelType, showInactiveEdges, showFiberUplink])

  const options = useMemo(() => ({
    layout: {
      randomSeed: '0.5015997545281881:1674586708474', //seed,
      improvedLayout: true
    },
    physics: {
      forceAtlas2Based: {
        springLength: 230,
        springConstant: 0.18,
      },
      maxVelocity: 146,
      solver: "forceAtlas2Based",
      timestep: 0.35,
      stabilization: { iterations: 150 },
    },
    nodes: {
      shape: "dot",
      font: { color: 'white' }
    },
    edges: {
      smooth: true,
    },
  }), [])

  return (
    <div sx={{ position: 'relative' }} ref={divRef}>
      <Box sx={{ position: 'fixed', p: 1, height: isFullscreen ? '100%' : '100vh', width: isFullscreen ? '100%' : 'auto' }}>
        <Paper elevation={3} style={{
          maxWidth: '250px',
          position: isFullscreen ? 'fixed' : 'absolute',
          top: 10,
          right: 10,
          padding: '10px',
          //backgroundColor: 'rgba(241, 241, 241, 0.85)',
          zIndex: 10000000
        }}>
          <Stack>
            <Tooltip title="Select Path Labels">
              <Select
                labelId="label-select-label"
                id="label-select"
                value={edgeLabelType}
                onChange={(e) => setEdgeLabelType(e.target.value)}>
                <MenuItem value={'none'}>None</MenuItem>
                <MenuItem value={'utilization'}>Utilization</MenuItem>
                <MenuItem value={'throughput'}>Throughput</MenuItem>
                <MenuItem value={'capacity'}>Capacity</MenuItem>
                <MenuItem value={'cost'}>Cost</MenuItem>
              </Select>
            </Tooltip>

            <ToggleButtonGroup
              size="small"
              orientation="vertical"
            >
              <Tooltip title="Fullscreen Mode">
                <ToggleButton value="fullscreen" selected={isFullscreen} onChange={toggleFullScreen}>
                  <FullscreenIcon />
                </ToggleButton>
              </Tooltip>
              <Tooltip title="Show Fiber Links">
                <ToggleButton value="fiberLinks" selected={showFiberUplink} onChange={(e) => setShowFiberUplink(!showFiberUplink)}>
                  <LinkIcon />
                </ToggleButton>
              </Tooltip>
              <Tooltip title="Show Inactive Paths">
                <ToggleButton value="inactivePaths" selected={showInactiveEdges} onChange={(e) => setShowInactiveEdges(!showInactiveEdges)}>
                  <RouteIcon />
                </ToggleButton>
              </Tooltip>
            </ToggleButtonGroup>
          </Stack>
        </Paper>
        <Paper elevation={3} style={{ position: isFullscreen ? 'fixed' : 'absolute', top: 10, left: 10, padding: '10px', 'z-index': '10000000' }}>
          {legendItems.map((item, index) => (
            <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
              <div style={{ backgroundColor: item.color, height: '15px', width: '15px', marginRight: '10px' }}></div>
              <Typography variant="body2">{item.text}</Typography>
            </div>
          ))}
        </Paper>
        {
          nodesToRender.length
            ? <VisGraph
              key={edgeLabelType}
              graph={{ nodes: nodesToRender, edges: edgesToRender }}
              options={options}
              events={{
                selectEdge: handleEdgeSelect,
                deselectEdge: () => setSelectedEdge(null)
              }}
            />
            : null
        }
      </Box>
    </div >
  )
}

export default BidirectionalTopology