import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  apTunnel: null,
  loading: false,
  error: null,
}

export const wsApTunnelSlice = createSlice({
  name: 'tunnel',
  initialState,
  reducers: {
    reset: () => initialState,
    requestApTunnel: state => {
      state.loading = true
    },
    receiveApTunnel: (state, action) => {
      state.loading = false
      state.apTunnel = action.payload
      state.error = null
    },
    
  }
})

export const { reset, requestApTunnel, receiveApTunnel } =  wsApTunnelSlice.actions

export default wsApTunnelSlice.reducer