import React from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'
import { useAppContext } from '../../../context/AppProvider'

const RebootDialog = ({ device, setReboot, reboot }) => {
  const { ws } = useAppContext()


  const rebootDevice = () => {
    ws.rebootDevice(device)
    setReboot(false)
  }

  return (
    <Dialog open={reboot} onClose={() => setReboot(false)}>
      <DialogTitle>Reboot {device.name}...</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to reboot {device.name}?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setReboot(false)}>Cancel</Button>
        <Button onClick={rebootDevice}>Reboot</Button>
      </DialogActions>
    </Dialog>
  )
}

export default RebootDialog