import React, { useEffect } from 'react'
import {
  Box,
  Paper,
  Grid,
  Card,
  CardContent,
  Typography,
  Divider,
  List,
  ListItem,
  ListItemText,
  CardHeader,
  Chip,
  ListItemIcon,
  Button
} from '@mui/material'
import PhoneIcon from '@mui/icons-material/Phone'
import EmailIcon from '@mui/icons-material/Email'
import StarIcon from '@mui/icons-material/Star'
import { useParams } from 'react-router-dom'
import { useGetSubscriber } from '../hooks'
import { useSelector } from 'react-redux'
import GroupedTable from '../Components/Core/Device/LanTable'
import DeviceCard from '../Components/Subscriber/DeviceCard'



const LanCard = ({ ipAddresses }) => {
  return (
    <Card>
      <CardHeader
        action={null}
        title={'IP Addresses'}
      />
      <CardContent>
        <GroupedTable data={ipAddresses} />
      </CardContent>
    </Card>
  )
}

const ServicesCard = ({ services }) => {
  return (
    <Card >
      <CardHeader
        action={null}
        title={'Services'}
      />
      <CardContent>
        <List>
          {services.map(service => (
            <ListItem key={service._id}>
              <ListItemText primary={service.name} secondary={`Price: $${service.price / 100}`} />
            </ListItem>
          ))}
        </List>
      </CardContent>
    </Card>
  )
}

const ContactCard = ({ contact }) => {
  return (
    <Card >
      <CardContent>
        <Typography variant="h5" gutterBottom>
          {contact.fullName}
        </Typography>
        {contact.primary && (
          <Chip icon={<StarIcon />} label="Primary Contact" color="primary" />
        )}
        <List>
          <ListItem>
            <ListItemIcon>
              <EmailIcon />
            </ListItemIcon>
            <ListItemText primary={contact.email} secondary="Email" />
          </ListItem>
          {contact.phoneNumbers.map((phone, index) => (
            <ListItem key={index}>
              <ListItemIcon>
                <PhoneIcon />
              </ListItemIcon>
              <ListItemText primary={phone.number} secondary={phone.type} />
            </ListItem>
          ))}
        </List>
      </CardContent>
    </Card>
  )
}


const SubscriberScreen = () => {

  const { subscriberId } = useParams()
  useGetSubscriber(subscriberId)

  const { subscriber, loading } = useSelector((state) => state.subscriber)

  const { name, sonarId, contact, location, services, devices, ipAddresses } = subscriber

  if (loading || Object.keys(subscriber).length === 0) {
    return <Typography>Loading...</Typography>
  }

  return (
    <Box sx={{ flexGrow: 1, padding: 2 }}>
      <Grid container spacing={2}>
        {/* Subscriber Name */}
        <Grid container spacing={2} alignItems="center" justifyContent="space-between">
          <Grid item xs={6}>
            <Typography variant="h4" gutterBottom>{name}</Typography>
          </Grid>
          <Grid item xs={6} display="flex" justifyContent="flex-end">
            <Button
              variant="contained"
              color="primary"
              href={`https://ideatek.sonar.software/app#/accounts/show/${sonarId}`}
              target="_blank"
              rel="noreferrer"
            >
              Open Account in Sonar
            </Button>
          </Grid>
        </Grid>

        {/* Contact Information and Services */}
        <Grid item container spacing={2}>
          <Grid item xs={12} md={6}>
            {contact.map((con, index) => (
              <ContactCard contact={con} key={`contact-${index}`} />
            ))}
          </Grid>
          <Grid item xs={12} md={6}>
            <ServicesCard services={services} />
          </Grid>
        </Grid>

        {/* Devices and LAN Information */}
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>Devices and Network</Typography>
        </Grid>
        {devices.map((device, index) => (
          <Grid item xs={12} sm={6} key={`device-${index}`}>
            <DeviceCard device={device} />
          </Grid>
        ))}
        <Grid item xs={12} sm={6}>
          <LanCard ipAddresses={ipAddresses} />
        </Grid>
      </Grid>
    </Box>

  )





}

export default SubscriberScreen