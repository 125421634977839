import * as React from 'react'
import Avatar from '@mui/material/Avatar'
import AvatarGroup from '@mui/material/AvatarGroup'
import { useSelector } from 'react-redux'
import { Badge } from '@mui/material'

const RoomInfo = () => {

  const { users } = useSelector((state) => state.wsRoomInfo)

  const result = users.reduce((a, c) => a.set(c._id, (a.get(c._id) || 0) + 1), new Map())

  return (
    <AvatarGroup max={5}>
      {
        users.map((user, i) => {
          return (
            <Badge key={user._id} invisible={user.count === 1 ? true : false} badgeContent={user.count}>
              <Avatar alt={user.fullName} src={user.picture} sx={{ width: 24, height: 24 }} />
            </Badge>
          )
        })
      }
    </AvatarGroup>
  )
}

export default RoomInfo