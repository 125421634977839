import { Button, CircularProgress, Divider, Grid, List, ListItem, ListItemButton, ListItemText, Paper, TextField, Typography } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import ProvisionMap from '../Components/Maps/ProvisionMap.js'
import { useAppContext } from '../context/AppProvider.js'
import { useGeolocation } from '../hooks/index.js'
import { resetBestAps } from '../reduxSlices/wsBestApsSlice.js'

const ApInfo = ({ ap, rxHeight }) => {

  return (
    <Paper elevation={5} sx={{ p: 1, flexGrow: 1 }}>
      <Grid container direction='colunm' justifyContent='space-between'>
        <Grid
          item
          container
          direction='row'
          justifyContent='space-between'
          alignItems='center'>
          <Grid
            item
            container
            direction='column'
            justifyContent='space-between'
            alignItems='center'
            xs={12}>
            <Divider style={{ width: '100%' }}>{ap.name}</Divider>
            <Grid
              item
              container
              direction='row'
              justifyContent='space-between'
              alignItems='center'>
              <Typography variant='body2' color='text.secondary'>
                SSID:
              </Typography>
              <Typography variant='body2' color='text.secondary'>
                {ap.ssid}
              </Typography>
            </Grid>
            <Divider style={{ width: '100%' }} />
            <Grid
              item
              container
              direction='row'
              justifyContent='space-between'
              alignItems='center'>
              <Typography variant='body2' color='text.secondary'>
                Link distance:
              </Typography>
              <Typography variant='body2' color='text.secondary'>
                {ap.distanceInMiles} Miles
              </Typography>
            </Grid>
            <Divider style={{ width: '100%' }} />
            <Grid
              item
              container
              direction='row'
              justifyContent='space-between'
              alignItems='center'>
              <Typography variant='body2' color='text.secondary'>
                Heading:
              </Typography>
              <Typography variant='body2' color='text.secondary'>
                {ap.apEstimatedBearing}
              </Typography>
            </Grid>
            <Divider style={{ width: '100%' }} />
            <Grid
              item
              container
              direction='row'
              justifyContent='space-between'
              alignItems='center'>
              <Typography variant='body2' color='text.secondary'>
                Obstructions:
              </Typography>
              <Typography variant='body2' color='text.secondary'>
                {ap.crfObstructions.length}
              </Typography>
            </Grid>
            <Divider style={{ width: '100%' }} />
            <Grid
              item
              container
              direction='row'
              justifyContent='space-between'
              alignItems='center'>
              <Typography variant='body2' color='text.secondary'>
                Expected Signal:
              </Typography>
              <Typography variant='body2' color='text.secondary'>
                {ap.crfSignal}dB
              </Typography>
            </Grid>
            <Divider style={{ width: '100%' }} />
            {
              ap.crf.Transmitters[0]['Raise RX antenna for full fresnel'] !== 0 &&
              <>
                <Grid
                  item
                  container
                  direction='row'
                  justifyContent='space-between'
                  alignItems='center'>
                  <Typography variant='body2' color='text.secondary'>
                    Height Required:
                  </Typography>
                  <Typography variant='body2' color='text.secondary'>
                    {Math.round(ap.crf.Transmitters[0]['Raise RX antenna for full fresnel'])}
                  </Typography>
                </Grid>
                <Divider style={{ width: '100%' }} />
              </>
            }
            <Grid item>
              <img
                style={{ width: "100%" }}
                src={ap.crfImage}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  )
}

const ApFinderScreen = () => {
  const { ws } = useAppContext()
  const dispatch = useDispatch()
  let { lat, lng } = useParams()
  const [refreshEnabled, setRefreshEnabled] = useState(false)
  const [position, setPosition] = useState(lat && lng ? { lat, lng } : null)
  const [rxHeight, setRxHeight] = useState(10)
  const [txHeight, setTxHeight] = useState(null)
  const [ap, setAp] = useState()
  const geoLocation = useGeolocation()
  const userPosition = [geoLocation?.latitude, geoLocation?.longitude]

  useEffect(() => {
    if (position) {
      ws.fetchBestAps(position, rxHeight, txHeight)
    } else dispatch(resetBestAps())
  }, [position])

  const handleRxHeightChange = (event) => {
    setRxHeight(event.target.value)
    setRefreshEnabled(true)
  }

  const handleTxHeightChange = (event) => {
    setTxHeight(event.target.value)
    setRefreshEnabled(true)
  }

  const handleSelection = (ap) => {
    setAp(ap)
  }

  const handleRefresh = () => {
    ws.fetchBestAps(position, rxHeight, txHeight)
    setRefreshEnabled(false)
  }

  const { loading, aps, error } = useSelector((state) => state.wsBestAps)

  return (
    <Grid container direction={'column'}>
      <Paper sx={{ p: 1 }}>
        <Grid item container direction={'row'} justifyContent='space-evenly'>
          <Grid item>
            <TextField
              id="rx-height"
              label="Manual AP Height"
              value={txHeight}
              onChange={handleTxHeightChange}
            />
          </Grid>
          <Grid item>
            <TextField
              fullWidth
              id="rx-height"
              label="Receiver Height"
              value={rxHeight}
              onChange={handleRxHeightChange}
            />
          </Grid>
          <Grid item>
            <Button
              disabled={!refreshEnabled}
              onClick={handleRefresh}>
              Refresh
            </Button>
          </Grid>
        </Grid>
      </Paper>
      <Grid item>
        <ProvisionMap rxHeight={rxHeight} txHeight={txHeight} setAp={setAp} ap={ap} position={position} setPosition={setPosition} />
      </Grid>
      <Grid item container direction={'row'}>
        <Grid item xs={6}>
          <Paper>
            {ap && <ApInfo ap={ap} rxHeight={rxHeight}></ApInfo>}
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <List>
            {
              loading
                ? <CircularProgress />
                : aps.length
                  ? (
                    aps.map((ap) => (
                      <Paper elevation={2} sx={{ p: 1 }} key={ap._id}>
                        <ListItem disablePadding>
                          <ListItemButton onClick={() => { handleSelection(ap) }}>
                            <Grid container direction='row' justifyContent='space-between'>
                              <Grid item>
                                <Typography>
                                  {ap.name}
                                  <br />
                                  Score: {ap.accuracy}%
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography>
                                  Signal: {ap.crfSignal}dB
                                  <br />
                                  Distance: {ap.distanceInMiles} Miles
                                </Typography>
                              </Grid>
                            </Grid>
                          </ListItemButton>
                        </ListItem>
                      </Paper>
                    ))
                  )
                  : <Typography>No APs within range...</Typography>
            }
          </List>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default ApFinderScreen