import { Avatar, Divider, List, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Moment from 'react-moment'
import { useSelector } from 'react-redux'
import { useGetEvents } from '../hooks/index.js'


const EventList = () => {
  useGetEvents()

  const { events } = useSelector((state) => state.wsEvents)

  return (
    <List sx={{ width: '100%' }}>
      {
        events.map((event) => {
          let eventText = ''
          switch (event.event) {
            case 'assigned':
              eventText = `${event.device?.name} was assigned to ${event.site?.name}.`
              break
            case 'deviceProvisioned':
              eventText = `${event.device?.name} was provisioned.`
              break
            case 'deviceRemoved':
              eventText = `${event.value?.name} was removed.`
              break
            case 'firmwareUpgraded':
              eventText = `${event.device?.name} was upgraded.`
              break
            case 'firmwareUpgradeFailed':
              eventText = `${event.device?.name} upgrade failed.`
              break
          }
          return (
            <>
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar alt={event.user.fullName} src={event.user.picture} />
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <Typography
                      sx={{ display: 'inline' }}
                      component="span"
                      variant="body2"
                      color="text.primary"
                    >
                      {eventText}
                    </Typography>
                  }
                  secondary={
                    <>
                      <Moment format='MMMM Do YYYY, h:mm:ss a'>{event.createdAt}</Moment>
                      {` - ${event.user.fullName}`}
                    </>
                  }
                />
              </ListItem>
              <Divider variant="inset" component="li" />
            </>
          )
        })
      }
    </List>
  )
}

export default EventList