import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import {
  Typography,
  Grid,
  Paper,
  Box,
  Collapse,
  Checkbox,
  Select,
  MenuItem,
  Divider,
} from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import { Bolt, FlashAuto } from '@mui/icons-material/'
import { useUptime } from '../../../hooks'
import DeviceMenu from '../../Core/Device/DeviceMenu'

const ES = ({ device }) => {
  const uptime = useUptime(device?.uptime)

  const [collapsed, setCollapsed] = useState(false)
  const [editRowsModel, setEditRowsModel] = useState({})

  const handleEditRowsModelChange = React.useCallback((model) => {
    setEditRowsModel(model)
  }, [])

  const connectedDevices = () => { }

  const data = device?.switching?.interfaces?.filter((port) => port.type === 'port')

  const InterfaceBox = ({ iface }) => {
    const { name, id, status, mac, ip, firmware } = iface
    const {
      enabled,
      currentSpeed,
      plugged,
      arpProxy,
      comment,
      speed,
      mtu,
      dhcpSnooping,
      flowControl,
      isolated,
      pingWatchdog,
      poe,
      sfp,
      stp,
    } = status

    const boxColor = () => {
      switch (currentSpeed) {
        case '10000-full':
          return 'white'
        case '1000-full':
          return 'green'
        case '100-full':
          return 'orange'
        case '10-full':
          return 'red'
        default:
          return 'grey'
      }
    }
    return (
      <Box
        sx={{
          backgroundColor: boxColor(),
          height: '20px',
          width: '20px',
        }}>
        {poe === 'active' ? (
          <FlashAuto fontSize='small' />
        ) : poe !== 'off' ? (
          <Bolt fontSize='small' />
        ) : null}
      </Box>
    )
  }

  const columns = [
    {
      field: 'id',
      headerName: 'Interface',
      flex: 0.25,
      valueGetter: (params) => params?.row?.id,
    },
    {
      field: 'name',
      headerName: 'Name',
      editable: true,
      flex: 1,
      valueGetter: (params) => params?.row?.name,
    },
    {
      field: 'poe',
      headerName: 'POE',
      flex: 0.5,
      renderCell: (params) => (
        <>
          {params?.row?.poeOptions && (
            <Select
              variant='standard'
              fullWidth
              value={params?.row?.status?.poe}>
              {params?.row?.poeOptions.map((option, i) => (
                <MenuItem key={i} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          )}
        </>
      ),
    },
    {
      field: 'status',
      headerName: 'Speed',
      flex: 1,
      valueGetter: (params) =>
        params?.row?.status?.plugged ? params?.row?.status?.currentSpeed : null,
    },
    {
      field: 'isolation',
      headerName: 'Isolate',
      flex: 0.25,
      renderCell: (params) => (
        <Checkbox
          onChange={(e) => console.log(e.target.checked, params.row.id)}
          checked={params?.row?.status?.isolated}
        />
      ),
    },
    {
      field: 'dhcpSnooping',
      headerName: 'DHCP Snooping',
      flex: 0.25,
      renderCell: (params) => (
        <Checkbox checked={params?.row?.status?.dhcpSnooping} />
      ),
    },
    {
      field: 'enabled',
      headerName: 'Enabled',
      flex: 0.25,
      renderCell: (params) => (
        <Checkbox checked={params?.row?.status?.enabled} />
      ),
    },
  ]

  return (
    <Paper elevation={5} sx={{ p: 1, flexGrow: 1 }}>
      <Grid
        container
        backgroundColor={!device?.connected && 'red'}
        direction='column'
        sx={{ p: 2 }}
        justifyContent='space-evenly'>
        <Grid item>
          <Grid item container direction='row' justifyContent='space-between'>
            <Grid item>
              <Typography variant='h6'>{device?.name}</Typography>
            </Grid>
            <Grid item>
              <DeviceMenu device={device} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item container direction='row' justifyContent='space-between'>
          <Grid item>
            <Typography variant='caption' component='div'>
              {uptime}
            </Typography>
          </Grid>
        </Grid>
        <Divider />
        <Grid
          item
          container
          direction='row'
          justifyContent='space-between'
          alignItems='center'
          onClick={() => setCollapsed(!collapsed)}>
          <Grid item>
            <Grid
              item
              container
              direction='column'
              justifyContent='space-between'>
              <Grid item>
                <Typography>{device?.model}</Typography>
              </Grid>
              <Grid item>
                <Typography>
                  <a
                    onClick={(e) => e.stopPropagation()}
                    style={{
                      textDecoration: 'inherit',
                      color: 'inherit',
                    }}
                    target='_blank'
                    href={`http://${device?.ip}`}>
                    {device?.ip}
                  </a>
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant='caption' component='div'>
                  {device?.mac?.toUpperCase()}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Paper elevation={0} sx={{ p: 1, flexGrow: 1 }}>
              <Grid container direction='column'>
                <Grid item container direction='row'>
                  {device?.switching?.interfaces
                    .filter((iface) => iface.type === 'port')
                    .filter((iface) => iface.id.split('/')[1] % 2 !== 0)
                    .map((iface) => (
                      <Grid item key={iface.id}>
                        <Box sx={{ pr: 0.5, pl: 0.5 }}>
                          <Typography
                            component='div'
                            align='center'
                            variant='caption'>
                            {iface.id.split('/')[1]}
                          </Typography>
                          <InterfaceBox iface={iface} />
                        </Box>
                      </Grid>
                    ))}
                </Grid>
                <Divider />
                <Grid item container direction='row'>
                  {device?.switching?.interfaces
                    .filter((iface) => iface.type === 'port')
                    .filter((iface) => iface.id.split('/')[1] % 2 === 0)
                    .map((iface) => (
                      <Grid item key={iface.id}>
                        <Box sx={{ pr: 0.5, pl: 0.5 }}>
                          <InterfaceBox iface={iface} />
                          <Typography
                            component='div'
                            align='center'
                            variant='caption'>
                            {iface.id.split('/')[1]}
                          </Typography>
                        </Box>
                      </Grid>
                    ))}
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
        <Divider />
        <Grid item>
          {
            device?.switching?.interfaces &&
            <Collapse in={collapsed}>
              <div style={{ display: 'flex' }}>
                <div style={{ flexGrow: 1 }}>
                  <DataGrid
                    autoHeight
                    disableSelectionOnClick
                    editRowsModel={editRowsModel}
                    onEditRowsModelChange={handleEditRowsModelChange}
                    getRowId={(row) => row.id}
                    getRowHeight={() => 'auto'}
                    rows={data}
                    columns={columns}
                    sx={{
                      '& .MuiDataGrid-columnHeaderTitle': {
                        textOverflow: 'clip',
                        whiteSpace: 'break-spaces',
                        lineHeight: 1,
                      },
                      '&.MuiDataGrid-root .MuiDataGrid-cell:focus': {
                        outline: 'none',
                      },
                    }}
                  />
                </div>
              </div>
            </Collapse>
          }
        </Grid>
      </Grid>
    </Paper>
  )
}

export default ES
