import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  user: {},
  loading: false,
  error: null,
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    resetUser: () => initialState,
    requestUser: (state) => {
      state.loading = true
    },
    receiveUser: (state, action) => {
      state.loading = false
      state.user = action.payload
      state.error = null
    },
    updateUser: (state) => {
      state.loading = true
    },
  },
})

export const { resetUser, requestUser, receiveUser, updateUser } =
  userSlice.actions

export default userSlice.reducer
