import * as React from 'react'
import { useAppContext } from '../../../context/AppProvider'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import MoveDialog from './MoveDialog'
import AssignDialog from './AssignDialog'
import RemoveDialog from './RemoveDialog'
import { useParams } from 'react-router-dom'
import RebootDialog from './RebootDialog'

const DeviceMenu = ({ device }) => {
  const { ws } = useAppContext()

  const { siteId } = useParams()
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [move, setMove] = React.useState(false)
  const [remove, setRemove] = React.useState(false)
  const [reboot, setReboot] = React.useState(false)
  const [assign, setAssign] = React.useState(false)
  const open = Boolean(anchorEl)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleAssign = () => {
    setAssign(true)
    handleClose()
  }

  const handleMove = () => {
    setMove(true)
    handleClose()
  }

  const handleRemove = () => {
    setRemove(true)
    handleClose()
  }

  const handleReboot = () => {
    setReboot(true)
    handleClose()
  }

  const handleFirmware = () => {
    ws.requestFwUpdate(device?._id)
    handleClose()
  }

  return (
    <div>
      {move === true && (
        <MoveDialog
          device={device}
          setMove={setMove}
          move={move}
          siteId={siteId}
        />
      )}
      {remove === true && (
        <RemoveDialog
          device={device}
          setRemove={setRemove}
          remove={remove}
          siteId={siteId}
        />
      )}
      {reboot === true && (
        <RebootDialog device={device} setReboot={setReboot} reboot={reboot} />
      )}
      {assign === true && (
        <AssignDialog device={device} setAssign={setAssign} assign={assign} />
      )}
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem disabled={device?.family !== 'ltu'} onClick={handleFirmware}>
          Update Firmware...
        </MenuItem>
        <MenuItem onClick={handleAssign}>Assign...</MenuItem>
        <MenuItem onClick={handleReboot}>Reboot...</MenuItem>
        <MenuItem onClick={handleMove}>Move...</MenuItem>
        <MenuItem onClick={handleRemove}>Remove...</MenuItem>
      </Menu>
    </div>
  )
}

export default DeviceMenu

//<MenuItem disabled={device?.family !== 'ltu'} onClick={handleFirmware}>Update Firmware...</MenuItem>
