import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  ap: {},
  stas: [],
  site: [],
  arps: [],
  services: [],
  loading: true,
  error: null,
}

export const wsDeviceSlice = createSlice({
  name: 'device',
  initialState,
  reducers: {
    resetDevice: () => initialState,
    requestDevice: (state) => {
      state.loading = true
    },
    receiveDevice: (state, action) => {
      state.ap = action.payload.ap
      state.stas = action.payload.stas
      state.site = action.payload.site
      state.arps = action.payload.arps
      state.services = action.payload.services
      state.loading = false
      state.error = null
    },
  },
})

export const { resetDevice, requestDevice, receiveDevice } =
  wsDeviceSlice.actions

export default wsDeviceSlice.reducer
