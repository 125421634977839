import React, { useState } from 'react'
import {
  Typography,
  Grid,
  Paper,
  Collapse,
  Divider,
} from '@mui/material'

import { useUptime } from '../../../hooks'
import DeviceMenu from '../../Core/Device/DeviceMenu'

const Alg = ({ device }) => {
  const uptime = useUptime(device.uptime)
  const [collapsed, setCollapsed] = useState(false)


  return (
    <Paper elevation={5} sx={{ p: 1, flexGrow: 1 }}>
      <Grid backgroundColor={!device.connected && 'red'}
        container
        direction='column'
        sx={{ p: 2 }}
        justifyContent='space-evenly'>
        <Grid item>
          <Grid item container direction='row' justifyContent='space-between'>
            <Grid item>
              <Typography variant='h6'>{device.name}</Typography>
            </Grid>
            <Grid item>
              <DeviceMenu device={device} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item container direction='row' justifyContent='space-between'>
          <Grid item>
            <Typography variant='caption'>
              {uptime}
            </Typography>
          </Grid>
          <Grid item>
            <Typography>
              Status: {device?.connected ? device?.power?.state : 'Unreachable'}
            </Typography>
          </Grid>
        </Grid>
        <Divider />

        <Grid
          item
          container
          direction='row'
          justifyContent='space-between'
          alignItems='center'
          onClick={() => setCollapsed(!collapsed)}>
          <Grid item>
            <Grid
              item
              container
              direction='column'
              justifyContent='space-between'>
              <Grid item>
                <Typography>{device?.model}</Typography>
              </Grid>
              <Grid item>
                <Typography>
                  <a
                    onClick={(e) => e.stopPropagation()}
                    style={{
                      textDecoration: 'inherit',
                      color: 'inherit',
                    }}
                    target='_blank'
                    href={`http://${device.ip}`}>
                    {device.ip}
                  </a>
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant='caption' component='div'>
                  {device?.mac?.toUpperCase()}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid
              item
              container
              direction='row'
              justifyContent='space-between'
              alignItems='center'>
              <Typography variant='body2' color='text.secondary'>
                Output:
              </Typography>
              <Typography variant='body2' color='text.secondary'>
                {`${device.power?.output?.voltage * device.power?.output?.current}W`}
              </Typography>
            </Grid>
            <Divider />
          </Grid>
        </Grid>
        <Collapse in={collapsed}>
          <Grid
            backgroundColor={!device.connected && 'red'}
            item
            container
            direction='row'
            justifyContent='space-between'
          >
            <Grid
              sx={{ p: 1 }}
              item
              container
              direction='column'
              alignItems='center'>
              <Grid
                item
                container
                direction='row'
                justifyContent='space-between'
                alignItems='center'>
                <Typography variant='body2' color='text.secondary'>
                  Connected:
                </Typography>
                <Typography variant='body2' color='text.secondary'>
                  {device.connected ? 'Yes' : 'No'}
                </Typography>
              </Grid>
              <Divider style={{ width: '100%' }} />
              <Grid
                item
                container
                direction='row'
                justifyContent='space-between'
                alignItems='center'>
                <Typography variant='body2' color='text.secondary'>
                  Voltage:
                </Typography>
                <Typography variant='body2' color='text.secondary'>
                  {device.power?.output?.voltage}
                </Typography>
              </Grid>
              <Divider style={{ width: '100%' }} />
              <Grid
                item
                container
                direction='row'
                justifyContent='space-between'
                alignItems='center'>
                <Typography variant='body2' color='text.secondary'>
                  Current:
                </Typography>
                <Typography variant='body2' color='text.secondary'>
                  {device.power?.output?.current}
                </Typography>
              </Grid>
              <Divider style={{ width: '100%' }} />
              <Grid
                item
                container
                direction='row'
                justifyContent='space-between'
                alignItems='center'>
                <Typography variant='body2' color='text.secondary'>
                  Wattage:
                </Typography>
                <Typography variant='body2' color='text.secondary'>
                  {device.power?.output?.voltage * device.power?.output?.current}
                </Typography>
              </Grid>
              <Divider style={{ width: '100%' }} />
            </Grid>
          </Grid>
        </Collapse>
      </Grid>
    </Paper>
  )
}

export default Alg
/*


*/
