import React, { useState } from 'react'
import { Card, CardContent, Typography, Avatar, Box, Button, TextField, Collapse } from '@mui/material'
import { LocationOn } from '@mui/icons-material'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useGetUser } from '../hooks'
import { useAppContext } from '../context/AppProvider'

const UserInfo = ({ data }) => {

  const { ws } = useAppContext()

  const [changePassword, setChangePassword] = useState(false)
  const [password, setPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const [passwordError, setPasswordError] = useState("")

  const handleSubmit = (e) => {
    e.preventDefault()

    if (password !== confirmPassword) {
      setPasswordError("Passwords do not match")
      return
    }

    ws.fetchUpdateUser(data._id, { password })
    setPasswordError("")
    setChangePassword(false)
    setPassword("")
    setConfirmPassword("")

  }

  return (
    <Card>
      <CardContent>
        <Box display="flex" alignItems="center">
          <Avatar src={data?.picture} />
          <Box ml={2}>
            <Typography variant="h6">{data?.fullName}</Typography>
            <Typography variant="subtitle1">{data?.email}</Typography>
          </Box>
        </Box>
        {
          data?.password && (
            <>
              <Button onClick={() => setChangePassword(!changePassword)}>Change Password</Button>
              <Collapse in={changePassword}>
                <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    inputProps={{ minLength: 12 }}
                  />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="confirm_password"
                    label="Confirm Password"
                    type="password"
                    id="confirmPassword"
                    error={!!passwordError}
                    helperText={passwordError}
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    sx={{ mt: 3, mb: 2 }}
                  >
                    Submit
                  </Button>
                </Box>
              </Collapse>
            </>
          )
        }
      </CardContent>
    </Card>
  )
}

const UserAccountScreen = () => {

  const { id } = useParams()

  useGetUser(id)

  const { user } = useSelector((state) => state.user)



  return (
    <>
      {
        user && (
          <UserInfo data={user} />
        )
      }
    </>
  )
}

export default UserAccountScreen