import Moment from 'react-moment'
import React, { useState } from 'react'
import { Typography, Grid, Paper, Button, IconButton, AppBar, Toolbar, Badge, Dialog, DialogTitle, DialogContent, List, ListItem, ListItemText, Collapse, ListItemButton, ListItemIcon, Divider, DialogActions } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord' // Active indicator
import HighlightOffIcon from '@mui/icons-material/HighlightOff' // Inactive indicator
import { ExpandLess, ExpandMore } from '@mui/icons-material'

const isActive = (lastContact) => {
  const updatedTime = new Date(lastContact).getTime()
  const fiveMinutesAgo = Date.now() - 5 * 60 * 1000
  return updatedTime > fiveMinutesAgo
}

const hasActiveEntry = (entries) => {
  return entries.some(entry => isActive(entry.lastContact))
}

const ListItemWithDetails = ({ item }) => {
  const [open, setOpen] = useState(false)
  const containsActive = hasActiveEntry(item)

  item.sort((a, b) => {
    // Convert the date strings to Date objects
    const dateA = new Date(a.lastContact)
    const dateB = new Date(b.lastContact)

    // Sort in descending order
    return dateB - dateA
  })

  return (
    <Paper>
      <ListItemButton onClick={() => setOpen(!open)}>
        <ListItemIcon>
          {containsActive ? <FiberManualRecordIcon color="success" /> : <HighlightOffIcon color="error" />}
        </ListItemIcon>
        <ListItemText primary={
          <Divider style={{ width: '100%' }}>{`${item[0].mac} | ${item[0].vendor}`}</Divider>
        } secondary={`${item.length} entries`} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {item.map((detail, index) => (
            <ListItem key={detail._id} sx={{ pl: 4 }}>
              <ListItemIcon>
                {isActive(detail.lastContact) ? <FiberManualRecordIcon color="success" /> : <HighlightOffIcon color="error" />}
              </ListItemIcon>
              <ListItemText
                primary={
                  <Grid
                    item
                    container
                    direction='column'
                    justifyContent='space-between'
                    alignItems='center'
                    xs={12}>
                    <Grid
                      item
                      container
                      direction='row'
                      justifyContent='space-between'
                      alignItems='center'>
                      <Typography variant='body1' color='text.primary'>
                        IPv4:
                      </Typography>
                      <Typography variant='body1' color='text.primary'>
                        <a
                          style={{ textDecoration: 'inherit', color: 'inherit' }}
                          target='_blank'
                          href={`http://${detail.ip}`}>
                          {detail.ip}
                        </a>
                      </Typography>
                    </Grid>
                    <Divider style={{ width: '100%' }} />
                    {
                      detail.ipv6Prefix &&
                      <>
                        <Grid
                          item
                          container
                          direction='row'
                          justifyContent='space-between'
                          alignItems='center'>
                          <Typography variant='body1' color='text.primary'>
                            IPv6:
                          </Typography>
                          <Typography variant='body1' color='text.primary'>
                            {detail.ipv6Prefix ? detail.ipv6Prefix : 'N/A'}
                          </Typography>
                        </Grid>
                        <Divider style={{ width: '100%' }} />
                      </>
                    }
                  </Grid>
                }
                secondary={
                  <Grid
                    item
                    container
                    direction='column'
                    justifyContent='space-between'
                    alignItems='center'
                    xs={12}>
                    <Grid
                      item
                      container
                      direction='row'
                      justifyContent='space-between'
                      alignItems='center'>
                      <Typography variant='body2' color='text.secondary'>
                        First used:
                      </Typography>
                      <Typography variant='body2' color='text.secondary'>
                        {new Date(detail.createdAt).toLocaleString()}
                      </Typography>
                    </Grid>
                    <Divider style={{ width: '100%' }} />
                    <Grid
                      item
                      container
                      direction='row'
                      justifyContent='space-between'
                      alignItems='center'>
                      <Typography variant='body2' color='text.secondary'>
                        Last used:
                      </Typography>
                      <Typography variant='body2' color='text.secondary'>
                        {new Date(detail.lastContact).toLocaleString()}
                      </Typography>
                    </Grid>
                    <Divider style={{ width: '100%' }} />
                  </Grid>
                }
              />
            </ListItem>
          ))}
        </List>
      </Collapse>
    </Paper>
  )
}

const GroupedTable = ({ data }) => {
  const groupedData = data.reduce((acc, obj) => {
    acc[obj.mac] = acc[obj.mac] || []
    acc[obj.mac].push(obj)
    return acc
  }, {})

  const sortedGroups = Object.values(groupedData).sort((groupA, groupB) => {
    // Find the most recent lastContact in each group
    const latestDateA = new Date(Math.max(...groupA.map(obj => new Date(obj.lastContact))))
    const latestDateB = new Date(Math.max(...groupB.map(obj => new Date(obj.lastContact))))

    // Sort in descending order
    return latestDateB - latestDateA
  })

  return (
    <List>
      {sortedGroups.map((group, index) => (
        <ListItemWithDetails key={index} item={group} />
      ))}
    </List>
  )
}

export default GroupedTable