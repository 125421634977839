import zlib from 'react-zlib-js'
import Tar from 'tarts'


const full = (siteName, managementNet) => {
  let cfg = [
    `hostname "${siteName}-s16"`,
    'network protocol none',
    `network parms ${managementNet.base.split('.').slice(0, 3).join('.')}.5 ${managementNet.mask} ${managementNet.first}`,
    'vlan database',
    'vlan 101-104,600,700,800',
    'vlan name 101 "VLAN 101" ',
    'vlan name 102 "VLAN 102" ',
    'vlan name 103 "VLAN 103" ',
    'vlan name 104 "VLAN 104" ',
    'vlan name 600 "CgNat" ',
    'vlan name 700 "Public" ',
    'vlan name 800 "Management" ',
    'exit ',
    'network mgmt_vlan 800',
    'configure',
    'ip name server 1.1.1.1 8.8.8.8',
    'username "wireless" password 952bf7409e58a91c90ccdf580642b82ad7f9d868032a1c765688c481a1fd81cf180455630452ced20de2dd996a24f3003b52e2ed957ad64236a5f47a41647e7c level 15 encrypted',
    'no username "ubnt"',
    'line console',
    'exit ',
    'line telnet',
    'exit ',
    'line ssh',
    'exit ',
    `snmp-server sysname "${siteName}-s16"`,
    '! ',
    'interface 0/1',
    'description \'BH101\'',
    'vlan pvid 101',
    'vlan participation exclude 1,102-104,600,700,800',
    'vlan participation include 101',
    'poe opmode passive24v-4pair',
    'exit ',
    'interface 0/2',
    'description \'BH102\'',
    'vlan pvid 102',
    'vlan participation exclude 1,101,103-104,600,700,800',
    'vlan participation include 102',
    'poe opmode passive24v-4pair',
    'exit ',
    'interface 0/3',
    'description \'LTU AP\'',
    'vlan pvid 600',
    'vlan participation exclude 1,101-104',
    'vlan participation include 600,700,800',
    'vlan tagging 800,700',
    'poe opmode passive54v-4pair',
    'exit ',
    'interface 0/4',
    'description \'LTU AP\'',
    'vlan pvid 600',
    'vlan participation exclude 1,101-104',
    'vlan participation include 600,700,800',
    'vlan tagging 800,700',
    'poe opmode passive54v-4pair',
    'exit ',
    'interface 0/5',
    'description \'LTU AP\'',
    'vlan pvid 600',
    'vlan participation exclude 1,101-104',
    'vlan participation include 600,700,800',
    'vlan tagging 800,700',
    'poe opmode auto',
    'exit ',
    'interface 0/6',
    'description \'LTU AP\'',
    'vlan pvid 600',
    'vlan participation exclude 1,101-104',
    'vlan participation include 600,700,800',
    'vlan tagging 800,700',
    'poe opmode auto',
    'exit ',
    'interface 0/7',
    'description \'LTU AP\'',
    'vlan pvid 600',
    'vlan participation exclude 1,101-104',
    'vlan participation include 600,700,800',
    'vlan tagging 800,700',
    'poe opmode auto',
    'exit ',
    'interface 0/8',
    'description \'LTU AP\'',
    'vlan pvid 600',
    'vlan participation exclude 1,101-104',
    'vlan participation include 600,700,800',
    'vlan tagging 800,700',
    'poe opmode auto',
    'exit ',
    'interface 0/9',
    'vlan participation exclude 1,101-104,600,700,800',
    'exit ',
    'interface 0/10',
    'vlan participation exclude 1,101-104,600,700,800',
    'exit ',
    'interface 0/11',
    'vlan participation exclude 1,101-104,600,700,800',
    'exit ',
    'interface 0/12',
    'vlan participation exclude 1,101-104,600,700,800',
    'exit ',
    'interface 0/13',
    'vlan participation exclude 1,101-104,600,700,800',
    'exit ',
    'interface 0/14',
    'vlan participation exclude 1,101-104,600,700,800',
    'exit ',
    'interface 0/15',
    'description \'BH103\'',
    'vlan pvid 103',
    'vlan participation exclude 1,101-102,104,600,700,800',
    'vlan participation include 103',
    'poe opmode passive24v',
    'exit ',
    'interface 0/16',
    'description \'BH104\'',
    'vlan pvid 104',
    'vlan participation exclude 1,101-103,600,700,800',
    'vlan participation include 104',
    'poe opmode passive24v',
    'exit ',
    'interface 0/17',
    'description \'VLAN Trunk\'',
    'vlan participation exclude 1',
    'vlan participation include 101-104,600,700,800',
    'vlan tagging 101-104,600,700,800',
    'exit ',
    'interface 0/18',
    'vlan participation exclude 1,101-104,600,700,800',
    'exit ',
    'interface lag 1 ',
    'vlan participation exclude 1,101-104,600,700,800',
    'exit ',
    'interface lag 2 ',
    'vlan participation exclude 1,101-104,600,700,800',
    'exit ',
    'interface lag 3 ',
    'vlan participation exclude 1,101-104,600,700,800',
    'exit ',
    'interface lag 4 ',
    'vlan participation exclude 1,101-104,600,700,800',
    'exit ',
    'interface lag 5 ',
    'vlan participation exclude 1,101-104,600,700,800',
    'exit ',
    'interface lag 6 ',
    'vlan participation exclude 1,101-104,600,700,800',
    'exit ',
    'service ubnt-discovery-active-scan',
    'service unms',
    'exit '
  ]
  return cfg.filter(Boolean).join("\r\n")
}

export const createEsTar = (siteName, managementNet) => new Promise((resolve, reject) => {
  let uispKey = 'wss://uisp.ideatek.com:443+CMnVFBUqPiVAcsJ4xXhBg2DirMr8W3JGrcEpBEdnVnGORVkY+allowSelfSignedCertificate'
  let cfg = full(siteName, managementNet)
  
  const tar = Tar([
    {
      name: 'ubnt_unms.key',
      content: uispKey
    },
    {
      name: 'cfg-backup',
      content: cfg
    }
  ])
  zlib.gzip(tar, (err, buffer) => {
  
    if (!err) {
      let config = new Blob([buffer], {type: 'application/x-compressed-tar'})
      resolve(config)
    } 
    else {
      reject(err)
      console.log(err);
    }
  })
})
