import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  severity: 'info',
  message: ''
}

export const wsNotifySlice = createSlice({
  name: 'notify',
  initialState,
  reducers: {
    resetNotification: () => initialState,
    receiveNotification: (state, action) => {
      state.severity = action.payload.severity
      state.message = action.payload.message
    },
  },
})

export const { resetNotification, receiveNotification } = wsNotifySlice.actions

export default wsNotifySlice.reducer