import { Alert, Snackbar } from '@mui/material'
import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { resetNotification } from '../../reduxSlices/wsNotifySlice'

const Notification = () => {
  const dispatch = useDispatch()

  const { severity, message } = useSelector((state) => state.wsNotify)

  const [snackBar, setSnackBar] = useState(false)

  useEffect(() => {
    setSnackBar(true)
  }, [severity, message])

  const handleSnackBarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setSnackBar(false)
    dispatch(resetNotification())
  }


  return (
    <>
      {
        message &&
          <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={snackBar} autoHideDuration={6000} onClose={handleSnackBarClose}>
            <Alert onClose={handleSnackBarClose} severity={severity} sx={{ width: '100%' }}>
              {message}
            </Alert>
          </Snackbar>
      }
    </>
  )
}

export default Notification