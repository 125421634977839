import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  data: null,
  loading: false,
  error: null,
}

export const wsNoiseDataSlice = createSlice({
  name: 'data',
  initialState,
  reducers: {
    resetNoiseData: () => initialState,
    requestNoiseData: (state) => {
      state.loading = true
    },
    receiveNoiseData: (state, action) => {
      state.loading = false
      state.data = action.payload
      state.error = null
    },
  },
})

export const { resetNoiseData, requestNoiseData, receiveNoiseData } =
  wsNoiseDataSlice.actions

export default wsNoiseDataSlice.reducer
