import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import Moment from 'react-moment'
import {
  Typography,
  Grid,
  Paper,
  Button,
  IconButton,
  AppBar,
  Toolbar,
  Badge,
  Dialog,
  DialogTitle,
  DialogContent,
  List,
  ListItem,
  ListItemText,
  Collapse,
  ListItemButton,
  ListItemIcon,
  Divider,
  DialogActions,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord' // Active indicator
import HighlightOffIcon from '@mui/icons-material/HighlightOff' // Inactive indicator
import ltuRocketImg from '../../../images/ltuRocket.png'
import af5xhd from '../../../images/af5xhd.png'
import ltuLrImg from '../../../images/ltulr.png'
import waveLrImg from '../../../images/waveLr.png'
import waveApImg from '../../../images/waveAp.png'
import ltuProImg from '../../../images/ltupro.png'
import ltuLiteImg from '../../../images/ltulite.png'
import epmp1000 from '../../../images/epmp1000.png'
import epmpForce180 from '../../../images/force180.png'
import epmpForce200 from '../../../images/force200.png'
import { useUptime, useStaTunnel } from '../../../hooks'
import DeviceMenu from '../../Core/Device/DeviceMenu'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { useAppContext } from '../../../context/AppProvider'
import AlertDialog from '../AlertDialog'
import SubInfoCard from '../../Subscriber/SubInfoCard'

const DeviceTopBar = ({ device }) => {
  return (
    <AppBar
      position="static"
      enableColorOnDark
      color={device?.connected ? '' : 'error'}
    >
      <Toolbar variant="dense">
        <Typography variant="body1" sx={{ flexGrow: 1 }}>
          {device?.name}
        </Typography>
        <IconButton size="small" edge="end" color="inherit" aria-label="menu">
          <DeviceMenu device={device} />
        </IconButton>
      </Toolbar>
    </AppBar>
  )
}

const isActive = (lastContact) => {
  const updatedTime = new Date(lastContact).getTime()
  const fiveMinutesAgo = Date.now() - 5 * 60 * 1000
  return updatedTime > fiveMinutesAgo
}

const hasActiveEntry = (entries) => {
  return entries.some((entry) => isActive(entry.lastContact))
}

const ListItemWithDetails = ({ item }) => {
  const [open, setOpen] = useState(false)
  const containsActive = hasActiveEntry(item)

  item.sort((a, b) => {
    // Convert the date strings to Date objects
    const dateA = new Date(a.lastContact)
    const dateB = new Date(b.lastContact)

    // Sort in descending order
    return dateB - dateA
  })

  return (
    <Paper>
      <ListItemButton onClick={() => setOpen(!open)}>
        <ListItemIcon>
          {containsActive ? (
            <FiberManualRecordIcon color="success" />
          ) : (
            <HighlightOffIcon color="error" />
          )}
        </ListItemIcon>
        <ListItemText
          primary={
            <Divider
              style={{ width: '100%' }}
            >{`${item[0].mac} | ${item[0].vendor}`}</Divider>
          }
          secondary={`${item.length} entries`}
        />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {item.map((detail, index) => (
            <ListItem key={detail._id} sx={{ pl: 4 }}>
              <ListItemIcon>
                {isActive(detail.lastContact) ? (
                  <FiberManualRecordIcon color="success" />
                ) : (
                  <HighlightOffIcon color="error" />
                )}
              </ListItemIcon>
              <ListItemText
                primary={
                  <Grid
                    item
                    container
                    direction="column"
                    justifyContent="space-between"
                    alignItems="center"
                    xs={12}
                  >
                    <Grid
                      item
                      container
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Typography variant="body1" color="text.primary">
                        IPv4:
                      </Typography>
                      <Typography variant="body1" color="text.primary">
                        <a
                          style={{
                            textDecoration: 'inherit',
                            color: 'inherit',
                          }}
                          target="_blank"
                          href={`http://${detail.ip}`}
                        >
                          {detail.ip}
                        </a>
                      </Typography>
                    </Grid>
                    <Divider style={{ width: '100%' }} />
                    {detail.ipv6Prefix && (
                      <>
                        <Grid
                          item
                          container
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Typography variant="body1" color="text.primary">
                            IPv6:
                          </Typography>
                          <Typography variant="body1" color="text.primary">
                            {detail.ipv6Prefix ? detail.ipv6Prefix : 'N/A'}
                          </Typography>
                        </Grid>
                        <Divider style={{ width: '100%' }} />
                      </>
                    )}
                  </Grid>
                }
                secondary={
                  <Grid
                    item
                    container
                    direction="column"
                    justifyContent="space-between"
                    alignItems="center"
                    xs={12}
                  >
                    <Grid
                      item
                      container
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Typography variant="body2" color="text.secondary">
                        First used:
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {new Date(detail.createdAt).toLocaleString()}
                      </Typography>
                    </Grid>
                    <Divider style={{ width: '100%' }} />
                    <Grid
                      item
                      container
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Typography variant="body2" color="text.secondary">
                        Last used:
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {new Date(detail.lastContact).toLocaleString()}
                      </Typography>
                    </Grid>
                    <Divider style={{ width: '100%' }} />
                  </Grid>
                }
              />
            </ListItem>
          ))}
        </List>
      </Collapse>
    </Paper>
  )
}

const GroupedTable = ({ data }) => {
  const groupedData = data?.reduce((acc, obj) => {
    acc[obj.mac] = acc[obj.mac] || []
    acc[obj.mac].push(obj)
    return acc
  }, {})

  const sortedGroups = Object.values(groupedData).sort((groupA, groupB) => {
    // Find the most recent lastContact in each group
    const latestDateA = new Date(
      Math.max(...groupA.map((obj) => new Date(obj.lastContact)))
    )
    const latestDateB = new Date(
      Math.max(...groupB.map((obj) => new Date(obj.lastContact)))
    )

    // Sort in descending order
    return latestDateB - latestDateA
  })

  return (
    <List>
      {sortedGroups.map((group, index) => (
        <ListItemWithDetails key={index} item={group} />
      ))}
    </List>
  )
}

const LtuBrief = ({ data }) => {
  const { ws } = useAppContext()
  const uptime = useUptime(data?.uptime)
  const [open, setOpen] = useState(false)
  const [openDialog, setOpenDialog] = useState(false)
  const [warnOpen, setWarnOpen] = useState(false)
  const { apTunnel } = useSelector((state) => state.wsApTunnel)
  const { staTunnel } = useSelector((state) => state.wsStaTunnel)
  const { arps, services } = useSelector((state) => state.wsDevice)

  const imgSel = (model) => {
    let img
    switch (model) {
      case 'LTU-Rocket':
        img = ltuRocketImg
        break
      case 'airFiber 5XHD':
        img = af5xhd
        break
      case 'LTU-LR':
        img = ltuLrImg
        break
      case 'LTU-Pro':
        img = ltuProImg
        break
      case 'LTU-Lite':
        img = ltuLiteImg
        break
      case 'LTU-XR':
        img = ltuLrImg
        break
      case '5 GHz Force 180 (FCC)':
        img = epmpForce180
        break
      case '5G Force 200 (FCC)':
        img = epmpForce200
        break
      case '5 GHz Connectorized Radio':
        img = epmp1000
        break
      case '5 GHz Connectorized Radio with Sync':
        img = epmp1000
        break
      case 'Wave-AP':
        img = waveApImg
        break
      case 'Wave-LR':
        img = waveLrImg
        break
    }
    return img
  }

  const accountStatusColor = (status) => {
    switch (data?.subscriber?.accountStatusId) {
      case 1: {
        return 'green'
      }
      case 2: {
        return 'red'
      }
      case 9: {
        return 'orange'
      }
    }
  }

  return (
    <>
      <DeviceTopBar device={data} />
      <Grid
        container
        direction="column"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item>
          {!data?.connected ? (
            <Typography align="center" variant="caption" component="div">
              Last seen <Moment fromNow>{data?.lastContact}</Moment>
            </Typography>
          ) : !data?.lastContact ? (
            <Typography align="center" variant="caption" component="div">
              Added <Moment fromNow>{data?.createdAt}</Moment>
            </Typography>
          ) : null}
        </Grid>
        {data?.services &&
          services?.map((service) => {
            return (
              <div key={service._id}>
                <Grid item>
                  <AlertDialog
                    active={service.fiberAvailable}
                    title={'Fiber Available!'}
                    message={
                      'Based on this clients address, they are located in an active fiber zone. If this customer is having connection issues, consider upgrading them to fiber.'
                    }
                  />
                </Grid>
                <Grid item>
                  <div>
                    <Typography
                      sx={{
                        textDecoration: 'inherit',
                        color: service?.suspended
                          ? 'orange'
                          : service?.provisioned
                          ? 'green'
                          : 'red',
                        cursor: 'pointer',
                      }}
                      onClick={() => setOpenDialog(true)}
                    >
                      {`${service?.accountName}`}
                    </Typography>

                    {/* Dialog Component */}
                    <Dialog
                      fullWidth
                      open={openDialog}
                      onClose={() => setOpenDialog(false)}
                    >
                      <SubInfoCard services={services} arps={arps} />
                    </Dialog>
                  </div>
                </Grid>
              </div>
            )
          })}
        <Grid item>
          <Badge
            onClick={() => setWarnOpen(true)}
            color="warning"
            badgeContent={data?.warnings?.length}
          >
            <img height="175" src={imgSel(data?.model)} />
          </Badge>
          <Dialog
            open={warnOpen}
            onClose={() => setWarnOpen(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{'Warnings..'}</DialogTitle>
            <DialogContent>
              <List>
                {data?.warnings &&
                  data?.warnings.map((el, i) => {
                    return (
                      <ListItem key={i}>
                        <ListItemText primary={el.description} />
                      </ListItem>
                    )
                  })}
              </List>
            </DialogContent>
          </Dialog>
        </Grid>
        <Grid item>
          {data?.connected ? (
            <a
              style={{ textDecoration: 'inherit', color: 'inherit' }}
              target="_blank"
              href={`http://${data?.ip}`}
            >
              {data?.ip}
            </a>
          ) : (
            <Typography variant="body2" color="text.secondary">
              {data?.ip}
            </Typography>
          )}
        </Grid>
        {data?.function === 'ap-ptmp' && (
          <Grid item>
            {apTunnel && (
              <a
                style={{ textDecoration: 'inherit', color: 'inherit' }}
                target="_blank"
                href={`https://${apTunnel}.mcgee.network`}
              >
                Open via tunnel
              </a>
            )}
          </Grid>
        )}
        {data?.function === 'sta-ptmp' && (
          <>
            <Grid item>
              {staTunnel && (
                <a
                  style={{ textDecoration: 'inherit', color: 'inherit' }}
                  target="_blank"
                  href={`https://${staTunnel}.mcgee.network`}
                >
                  Open via tunnel
                </a>
              )}
            </Grid>
            {arps && (
              <Grid item>
                <Button variant="outlined" onClick={() => setOpen(!open)}>
                  LAN Table
                </Button>
                <Dialog
                  fullScreen
                  open={open}
                  onClose={() => setOpen(false)}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                      <Typography
                        sx={{ ml: 2, flex: 1 }}
                        variant="h6"
                        component="div"
                      >
                        Lan Table
                      </Typography>
                      <IconButton
                        edge="start"
                        color="inherit"
                        onClick={() => setOpen(!open)}
                        aria-label="close"
                      >
                        <CloseIcon />
                      </IconButton>
                    </Toolbar>
                  </AppBar>
                  <DialogContent>
                    <GroupedTable data={arps} />
                  </DialogContent>

                  <DialogActions>
                    <Button
                      variant="outlined"
                      onClick={() => ws.fetchLanRefresh(data?._id)}
                    >
                      Refresh
                    </Button>
                  </DialogActions>
                </Dialog>
              </Grid>
            )}
          </>
        )}
        <Grid item>
          <Typography align="right" variant="body2" color="text.secondary">
            {uptime}
          </Typography>
        </Grid>
      </Grid>
    </>
  )
}

export default LtuBrief
