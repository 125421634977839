import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  aps: [],
  loading: false,
  error: null,
}

export const wsBestApsSlice = createSlice({
  name: 'aps',
  initialState,
  reducers: {
    resetBestAps: () => initialState,
    requestBestAps: (state) => {
      state.loading = true
    },
    receiveBestAps: (state, action) => {
      state.loading = false
      state.aps = action.payload
      state.error = null
    },
  },
})

export const { resetBestAps, requestBestAps, receiveBestAps } =
  wsBestApsSlice.actions

export default wsBestApsSlice.reducer
