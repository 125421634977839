import React, { useEffect, useState } from "react"
import {
  Alert,
  Box,
  Button,
  Collapse,
  Container,
  IconButton,
  TextField,
  Typography,
} from "@mui/material"
import CloseIcon from '@mui/icons-material/Close'
import axios from "axios"

const CreateUser = () => {
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [passwordError, setPasswordError] = useState("")
  const [response, setResponse] = useState()

  useEffect(() => {

  }, [response])

  const handleSubmit = (e) => {
    e.preventDefault()

    if (password !== confirmPassword) {
      setPasswordError("Passwords do not match")
      return
    }

    setPasswordError("")
    console.log({
      email,
      password,
      firstName,
      lastName,
    })
    axios
      .post('/api/auth/register', {
        email,
        password,
        firstName,
        lastName,
      })
      .then((res) => {
        setResponse({ status: res.status, message: res.statusText })
        console.log(res)
      })
      .catch((err) => {
        setResponse({ status: err.status, message: err?.response?.data?.message })
        console.log(Object.keys(err?.response))
      })
  }

  return (
    <Container maxWidth="sm">
      <Box sx={{ mt: 8, mb: 4 }}>
        <Typography variant="h4" align="center">
          Create User
        </Typography>
      </Box>
      <Box sx={{ width: '100%' }}>
        <Collapse in={response}>
          <Alert
            severity={response?.status === 201 ? "success" : "error"}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setResponse(null)
                  setEmail("")
                  setPassword("")
                  setConfirmPassword("")
                  setFirstName("")
                  setLastName("")

                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            sx={{ mb: 2 }}
          >
            {response?.message}
          </Alert>
        </Collapse>
      </Box>
      <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
        <TextField
          margin="normal"
          required
          fullWidth
          id="firstName"
          label="First Name"
          name="firstName"
          autoComplete="given-name"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          id="lastName"
          label="Last Name"
          name="lastName"
          autoComplete="family-name"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          id="email"
          label="Username or Email"
          name="email"
          autoComplete="email"
          autoFocus
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          inputProps={{ minLength: 3 }}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          autoComplete="current-password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          inputProps={{ minLength: 12 }}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          name="confirm_password"
          label="Confirm Password"
          type="password"
          id="confirmPassword"
          error={!!passwordError}
          helperText={passwordError}
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          sx={{ mt: 3, mb: 2 }}
        >
          Create User
        </Button>
      </Box>
    </Container>
  )
}

export default CreateUser
