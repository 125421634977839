import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  users: [],
  error: ''
}

export const wsRoomInfoSlice = createSlice({
  name: 'roominfo',
  initialState,
  reducers: {
    resetRoomInfo: () => initialState,
    receiveRoomInfo: (state, action) => {
      state.users = action.payload.users
      state.error = action.payload.error
    },
  },
})

export const { resetRoomInfo, receiveRoomInfo } = wsRoomInfoSlice.actions

export default wsRoomInfoSlice.reducer