import { configureStore } from '@reduxjs/toolkit'
import wsSitesSlice from './reduxSlices/wsSitesSlice'
import wsDevicesSlice from './reduxSlices/wsDevicesSlice'
import wsDeviceSlice from './reduxSlices/wsDeviceSlice'
import wsNoiseDataSlice from './reduxSlices/wsNoiseDataSlice'
import wsHistoricalDataSlice from './reduxSlices/wsHistoricalDataSlice'
import wsStaTunnelSlice from './reduxSlices/wsStaTunnelSlice'
import wsApTunnelSlice from './reduxSlices/wsApTunnelSlice'
import wsSiteSlice from './reduxSlices/wsSiteSlice'
import wsSearchSlice from './reduxSlices/wsSearchSlice'
import wsUsersSlice from './reduxSlices/wsUsersSlice'
import userSlice from './reduxSlices/userSlice'
import wsSonarSlice from './reduxSlices/wsSonarSlice'
import wsDataLinksSlice from './reduxSlices/dataLinkSlice'
import wsBestApsSlice from './reduxSlices/wsBestApsSlice'
import wsTopoSlice from './reduxSlices/wsTopoSlice'
import wsNotifySlice from './reduxSlices/wsNotifySlice'
import wsRoomInfoSlice from './reduxSlices/wsRoomInfoSlice'
import wsActiveSocketsSlice from './reduxSlices/wsActiveSocketsSlice'
import wsEventsSlice from './reduxSlices/wsEventsSlice'
import wsServerStatusSlice from './reduxSlices/wsServerStatusSlice'
import subscriberSlice from './reduxSlices/subscriberSlice'
import dispatchablesSlice from './reduxSlices/dispatchablesSlice'
import wsMbsSearchSlice from './reduxSlices/wsMbsSearchSlice'

export default configureStore({
  reducer: {
    wsSearch: wsSearchSlice,
    wsSite: wsSiteSlice,
    wsSites: wsSitesSlice,
    wsDevice: wsDeviceSlice,
    wsDevices: wsDevicesSlice,
    wsNoiseData: wsNoiseDataSlice,
    wsHistoricalData: wsHistoricalDataSlice,
    wsSonar: wsSonarSlice,
    wsStaTunnel: wsStaTunnelSlice,
    wsApTunnel: wsApTunnelSlice,
    user: userSlice,
    wsUsers: wsUsersSlice,
    wsDataLinks: wsDataLinksSlice,
    wsEvents: wsEventsSlice,
    wsBestAps: wsBestApsSlice,
    wsTopo: wsTopoSlice,
    wsNotify: wsNotifySlice,
    wsRoomInfo: wsRoomInfoSlice,
    wsActiveSockets: wsActiveSocketsSlice,
    wsServerStatus: wsServerStatusSlice,
    subscriber: subscriberSlice,
    dispatchables: dispatchablesSlice,
    wsMbsSearch: wsMbsSearchSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  devTools: true,
})
