import React, { useContext, useEffect, useState } from 'react'
import ReactDOMServer from 'react-dom/server'
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer'
import {
  MapContainer,
  Marker,
  useMapEvent,
  Polyline,
  useMap
} from 'react-leaflet'
import L, { FeatureGroup } from 'leaflet'
import 'leaflet/dist/leaflet.css'
import PersonIcon from '../../images/person.png'
import { useGeolocation } from '../../hooks'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png').default,
  iconUrl: require('leaflet/dist/images/marker-icon.png').default,
  shadowUrl: require('leaflet/dist/images/marker-shadow.png').default,
})


var Person = new L.Icon({
  iconUrl: PersonIcon,
  iconSize: new L.Point(32, 32),
  iconAnchor: new L.Point(16, 16),
})


const ObstructionIcon = new L.divIcon({
  className: 'dummy',
  iconAnchor: [18, 18],
  html: ReactDOMServer.renderToString(<WarningAmberIcon />)
})

const ApFinder = ({ rxHeight, txHeight, setAp, ap, position, setPosition }) => {

  const map = useMap()
  const group = new FeatureGroup()

  useEffect(() => {
    if (ap) {
      L.marker([ap.location.lat, ap.location.long]).addTo(group)
      L.marker([position.lat, position.lng]).addTo(group)
      map.flyToBounds(group.getBounds())
    }
  }, [ap])

  useMapEvent('click', (e) => {
    if (position) {
      setPosition(null)
      setAp(null)
    } else setPosition(e.latlng)
  })

  const getLineColor = (ap) => {
    if (ap.crf.Transmitters[0]['Raise RX antenna for LOS']) {
      return 'red'
    } else if (ap.crf.Transmitters[0]['Raise RX antenna for fresnel 60%']) {
      return 'orange'
    } else if (ap.crf.Transmitters[0]['Raise RX antenna for full fresnel']) {
      return 'yellow'
    } else {
      return 'green'
    }
  }


  return (
    <>
      {
        position === null ? null : (
          <>
            <Marker
              position={position}
              icon={Person}
            >
            </Marker>
            {
              ap
                ? (
                  <div>
                    <Polyline
                      pathOptions={{ color: getLineColor(ap) }}
                      weight='5'
                      positions={[
                        [ap.location.lat, ap.location.long],
                        [position.lat, position.lng]
                      ]}
                    />
                    {
                      ap.crfObstructions.map((el) => (
                        <Marker
                          position={el}
                          icon={ObstructionIcon}>
                        </Marker>
                      ))
                    }
                  </div>
                )
                : null
            }
          </>
        )

      }
    </>
  )
}

const ProvisionMap = ({ rxHeight, txHeight, setAp, ap, position, setPosition }) => {


  return (
    <div style={{ display: 'flex', height: '50vh' }}>
      <MapContainer
        preferCanvas={true}
        center={[38.053056341097246, -97.92971744800901]}
        zoom={13}
        attributionControl={false}
        style={{ flexGrow: 1 }}
        scrollWheelZoom={true}>
        <ReactLeafletGoogleLayer
          apiKey='AIzaSyC5nxdHvnhMxJSjSe_ydfeMK1OJ12sSOxI'
          type={'hybrid'}
        />

        <ApFinder rxHeight={rxHeight} txHeight={txHeight} setAp={setAp} ap={ap} position={position} setPosition={setPosition} />
      </MapContainer>


    </div>
  )
}

export default ProvisionMap
