import React, { useState, useEffect, useContext } from 'react'
import { useSelector } from 'react-redux'
import Moment from 'react-moment'


import {
  Button,
  Typography,
  TextField,
  Autocomplete,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useTheme,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import { Link } from 'react-router-dom'
import { useGetDevices, useGetSites } from '../hooks'
import { useAppContext } from '../context/AppProvider'

const AssignSiteDialog = ({ deviceIds, devices, open, setOpen }) => {
  const { ws } = useAppContext()
  const { sites } = useSelector((state) => state.wsSites)
  const accountInfo = useSelector((state) => state.wsSonar)
  const [value, setValue] = useState(sites[0])
  const [inputValue, setInputValue] = useState('')
  const [accountId, setAccountId] = useState('')

  let deviceList = []

  deviceIds.forEach((id) => {
    deviceList.push(devices.filter((device) => device._id == id)[0])
  })

  const assignToSite = () => {
    setOpen(false)
    if (value._id) {
      ws.assignDevicesToSite(deviceList, value._id)
    }
    if (accountInfo.accountId) {
      ws.assignDevicesToSubscriber(deviceList, accountId)
    }
  }

  useEffect(() => {
    ws.fetchAccountInfo(accountId)
  }, [accountId])

  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle>{`Assign ${deviceIds.length} devices.`}</DialogTitle>
      <DialogContent>
        <List>
          {deviceList.map((device, i) => (
            <ListItem key={i} disablePadding>
              <ListItemText primary={device?.name} />
            </ListItem>
          ))}
        </List>
        <Autocomplete
          value={value}
          autoFocus
          onChange={(event, newValue) => {
            setValue(newValue)
          }}
          inputValue={inputValue}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue)
          }}
          options={sites}
          getOptionLabel={(option) => option.name}
          sx={{ width: 300 }}
          renderInput={(params) => (
            <TextField {...params} label='Site' />
          )}
        />
        <TextField
          fullWidth
          variant="standard"
          margin="dense"
          id='outlined-name'
          label='Sonar ID'
          value={accountId}
          onChange={(e) => setAccountId(e.target.value)}
        />
        {
          accountInfo && (
            <Typography>
              {accountInfo.deviceHostName}
            </Typography>
          )
        }
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)}>Cancel</Button>
        <Button onClick={assignToSite}>Assign</Button>
      </DialogActions>
    </Dialog>
  )
}

const DevicesListScreen = () => {
  useGetDevices()
  useGetSites()

  const theme = useTheme()

  const { loading, devices } = useSelector((state) => state.wsDevices)

  const [open, setOpen] = useState(false)

  const [selectionModel, setSelectionModel] = useState([])

  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 2,
      renderCell: (params) => (
        <Link
          style={{ textDecoration: 'inherit', color: 'inherit' }}
          to={`/network/device/${params.row._id}`}>
          {params.value}
        </Link>
      ),
    },
    {
      field: 'site',
      headerName: 'Site',
      flex: 1,
      renderCell: (params) => {
        if (params.value) {
          return (
            <>
              <Link
                style={{ textDecoration: 'inherit', color: 'inherit' }}
                to={`/network/site/${params.value?._id}`}>
                {params.value?.name}
              </Link>
            </>
          )
        } else {
          return null
        }
      },
    },
    {
      field: 'model',
      headerName: 'Device Model',
      flex: 1,
    },
    {
      field: 'ip',
      headerName: 'IP Address',
      flex: 1,
      renderCell: (params) => (
        <a
          style={{ textDecoration: 'inherit', color: 'inherit' }}
          target='_blank'
          href={`http://${params.row.ip}`}>
          {params.row.ip}
        </a>
      ),
    },
    {
      field: 'mac',
      headerName: 'MAC Address',
      flex: 1,
    },
    {
      field: 'autoPower',
      headerName: 'AutoPower',
      flex: 1,
      renderCell: (params) => (
        <>
          {params.row.autoPower ? (
            <Typography>Yes</Typography>
          ) : (
            <Typography>No</Typography>
          )}
        </>
      ),
    },
    {
      field: 'lastContact',
      headerName: 'Status',
      flex: 1,
      renderCell: (params) => (
        <>
          {params.row?.connected ? (
            'Connected'
          ) : params.row?.lastContact ? (
            <Moment fromNow>{params.row?.lastContact}</Moment>
          ) : (
            <Typography>Never Connected</Typography>
          )}
        </>
      ),
      sortComparator: (v1, v2) => {
        return v1 > v2 ? -1 : 1
      },
    },
  ]

  return (
    <>
      <AssignSiteDialog

        deviceIds={selectionModel}
        devices={devices}
        open={open}
        setOpen={setOpen}
      />
      <Button disabled={!selectionModel.length} onClick={() => setOpen(true)}>Assign</Button>
      <div style={{ display: 'flex', height: `calc(100vh - ${theme.mixins.toolbar.minHeight}px)` }}>
        <div style={{ flexGrow: 1 }}>
          <DataGrid
            loading={!devices.length && loading}
            checkboxSelection
            rowHeight={30}
            getRowId={(row) => row._id}
            rows={devices}
            columns={columns}
            onSelectionModelChange={(items) => {
              setSelectionModel(items)
            }}
          />
        </div>
      </div>
    </>
  )
}

export default DevicesListScreen

