import React, { useEffect, useState } from 'react'
import { useTheme } from '@emotion/react'
import { useGetSite, useJoinSiteRoom } from '../hooks'
import { useSelector } from 'react-redux'
import { useParams, Link } from 'react-router-dom'
import {
  Box,
  CircularProgress,
  Grid,
  Paper,
  Tab,
  Typography,
} from '@mui/material'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import SiteOverview from '../Components/Network/SiteDashboard/SiteOverview'
import SiteBar from '../Components/Network/SiteDashboard/SiteBar'
import Moment from 'react-moment'
import ES from '../Components/Network/SiteDashboard/ES.js'
import Netonix from '../Components/Network/SiteDashboard/Netonix.js'
import SwOS from '../Components/Network/SiteDashboard/SwOS.js'
import Alg from '../Components/Network/SiteDashboard/AlgV2.js'
import Mikrotik from '../Components/Network/SiteDashboard/Mikrotik.js'
import NetworkMap from '../Components/Maps/Geolocation/NetworkMap'
import { generateUrl } from '../utils/generateUrl'
import EdgePower from '../Components/Network/SiteDashboard/EdgePower'
import DeviceMenu from '../Components/Core/Device/DeviceMenu'
import { TabContext, TabList, TabPanel } from '@mui/lab'

const SiteScreen = () => {
  const { siteId } = useParams()
  const theme = useTheme()

  useGetSite(siteId)
  useJoinSiteRoom(siteId)

  const [incidents, setIncidents] = useState([])

  const { loading, site } = useSelector((state) => state.wsSite)
  const ptps = site?.devices?.filter(
    (item) => item.function?.includes('ptp') && !item.subscriber
  )
  const aps = site?.devices?.filter((el) => el.function === 'ap-ptmp')
  const unassigned = site?.devices?.filter(
    (item) => !item.backbone && !item.services?.length
  )

  const [value, setValue] = useState('1')

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const generateStatus = (device) => {
    if (device.connected) {
      return 'Online'
    } else if (device?.lastContact) {
      return (
        <>
          <Typography variant="caption">
            {' '}
            {device?.failureReason} <br></br> Last seen{' '}
            <Moment fromNow>{device?.lastContact}</Moment>{' '}
          </Typography>
        </>
      )
    } else return 'Waiting for connection'
  }

  useEffect(() => {
    if (Array.isArray(aps)) {
      let icdnts = []
      aps.forEach((ap) => {
        ap?.incidents?.forEach((el) => {
          icdnts.push(el)
        })
      })
      setIncidents(icdnts)
    }
  }, [site])

  const formatUptime = (uptime) => {
    const fm = [
      Math.floor(uptime / (3600 * 24)),
      Math.floor((uptime % (3600 * 24)) / 3600),
      Math.floor((uptime % 3600) / 60),
      Math.floor(uptime % 60),
    ]
    const formattedUptime = () =>
      fm
        .map((el, i) => {
          if (Boolean(el)) {
            if (i === 0) {
              el = el + 'd'
            } else if (i === 1) {
              el = el + 'h'
            } else if (i === 2) {
              el = el + 'm'
            } else if (i === 3) {
              el = el + 's'
            }
            return el
          }
        })
        .join('')
    return formattedUptime()
  }

  const incidentColumns = [
    { field: 'message', headerName: 'Incident', minWidth: 250, flex: 2 },
    {
      field: 'createdAt',
      headerName: 'Date/Time',
      minWidth: 180,
      flex: 1,
      renderCell: (params) => (
        <Moment format="DD MMM YYYY hh:mm A">{params.value}</Moment>
      ),
    },
    {
      field: 'duration',
      headerName: 'Duration',
      width: 150,
      valueGetter: (params) => {
        // Calculate the difference in milliseconds
        const createdAt = new Date(params.row.createdAt)
        const updatedAt = new Date(params.row.updatedAt)
        return updatedAt - createdAt
      },
      renderCell: (params) => {
        // Convert milliseconds to a readable duration format
        const durationMs = params.getValue(params.id, 'duration')
        const seconds = Math.floor((durationMs / 1000) % 60)
        const minutes = Math.floor((durationMs / (1000 * 60)) % 60)
        const hours = Math.floor((durationMs / (1000 * 60 * 60)) % 24)

        const hoursDisplay = hours > 0 ? `${hours}h ` : ''
        const minutesDisplay = minutes > 0 ? `${minutes}m ` : ''
        const secondsDisplay = seconds > 0 ? `${seconds}s` : ''

        return `${hoursDisplay}${minutesDisplay}${secondsDisplay}` || '0s'
      },
    },
    {
      field: 'active',
      headerName: 'Active',
      type: 'boolean',
      minWidth: 90,
      flex: 1,
    },
  ]

  const ptpColumns = [
    {
      field: 'name',
      headerName: 'Name',
      minWidth: 125,
      flex: 1.5,
      renderCell: (params) => (
        <Link
          style={{ textDecoration: 'inherit', color: 'inherit' }}
          to={generateUrl(params.row)}
        >
          {params.value}
        </Link>
      ),
    },
    {
      field: 'model',
      headerName: 'Model',
      flex: 1,
      minWidth: 100,
    },
    {
      field: 'firmware',
      headerName: 'Version',
      flex: 1,
      minWidth: 100,
    },
    {
      field: 'frequency',
      headerName: 'Frequency',
      flex: 1,
      minWidth: 75,
      valueGetter: (params) => params.row.wireless?.frequency,
    },
    {
      field: 'channelWidth',
      headerName: 'Channel Width',
      flex: 1,
      minWidth: 100,
      valueGetter: (params) => params.row.wireless?.channelWidth,
    },
    {
      field: 'stationCount',
      headerName: 'Stations',
      flex: 1,
      minWidth: 75,
      valueGetter: (params) => params.row.wireless?.staCount,
    },
    {
      field: 'autoPower',
      headerName: 'AutoPower',
      flex: 0.5,
      renderCell: (params) => (
        <>
          {params.row?.wireless?.autoPower ? (
            <Typography>Yes</Typography>
          ) : (
            <Typography>No</Typography>
          )}
        </>
      ),
    },
    {
      field: 'ip',
      headerName: 'IP Address',
      flex: 1,
      minWidth: 110,
      renderCell: (params) => (
        <a
          style={{ textDecoration: 'inherit', color: 'inherit' }}
          target="_blank"
          rel="noreferrer"
          href={`http://${params.row?.ip}`}
        >
          {params.row.ip}
        </a>
      ),
    },
    {
      field: 'mac',
      headerName: 'MAC Address',
      flex: 1,
    },
    {
      field: 'uptime',
      headerName: 'Uptime',
      flex: 1,
      minWidth: 100,
      renderCell: (params) => formatUptime(params.row?.uptime),
    },
    {
      field: 'lastContact',
      headerName: 'Status',
      flex: 1,
      minWidth: 100,
      renderCell: (params) => <>{generateStatus(params.row)}</>,
      sortComparator: (v1, v2) => {
        return v1 > v2 ? -1 : 1
      },
    },
    {
      field: 'menu',
      renderCell: (params) => <DeviceMenu device={params.row} />,
    },
  ]

  const apColumns = [
    {
      field: 'name',
      headerName: 'Name',
      minWidth: 125,
      flex: 1.5,
      renderCell: (params) => (
        <Link
          style={{ textDecoration: 'inherit', color: 'inherit' }}
          to={generateUrl(params.row)}
        >
          {params.value}
        </Link>
      ),
    },
    {
      field: 'model',
      headerName: 'Model',
      flex: 1,
      minWidth: 100,
    },
    {
      field: 'firmware',
      headerName: 'Version',
      flex: 1,
      minWidth: 100,
    },
    {
      field: 'activeFrequency',
      headerName: 'Frequency',
      flex: 1,
      minWidth: 75,
      valueGetter: (params) => params.row.wireless?.frequency,
      renderCell: (params) => {
        if (
          params.row.wireless?.activeFrequency !==
          params.row.wireless?.frequency
        ) {
          return (
            <Typography color="error">{`${params.row.wireless?.activeFrequency} (Alternate)`}</Typography>
          )
        } else {
          return <Typography>{params.row.wireless?.activeFrequency}</Typography>
        }
      },
    },
    {
      field: 'channelWidth',
      headerName: 'Channel Width',
      flex: 1,
      minWidth: 100,
      valueGetter: (params) => params.row.wireless?.channelWidth,
    },
    {
      field: 'stationCount',
      headerName: 'Stations',
      flex: 1,
      minWidth: 75,
      valueGetter: (params) => params.row.wireless?.staCount,
    },
    {
      field: 'autoPower',
      headerName: 'AutoPower',
      flex: 0.5,
      renderCell: (params) => (
        <>
          {params.row?.wireless?.autoPower ? (
            <Typography>Yes</Typography>
          ) : (
            <Typography>No</Typography>
          )}
        </>
      ),
    },
    {
      field: 'ip',
      headerName: 'IP Address',
      flex: 1,
      minWidth: 110,
      renderCell: (params) => (
        <a
          style={{ textDecoration: 'inherit', color: 'inherit' }}
          target="_blank"
          rel="noreferrer"
          href={`http://${params.row?.ip}`}
        >
          {params.row.ip}
        </a>
      ),
    },
    {
      field: 'mac',
      headerName: 'MAC Address',
      flex: 1,
    },
    {
      field: 'uptime',
      headerName: 'Uptime',
      flex: 1,
      minWidth: 100,
      renderCell: (params) => formatUptime(params.row?.uptime),
    },
    {
      field: 'lastContact',
      headerName: 'Status',
      flex: 1,
      minWidth: 100,
      renderCell: (params) => <>{generateStatus(params.row)}</>,
      sortComparator: (v1, v2) => {
        return v1 > v2 ? -1 : 1
      },
    },
    {
      field: 'menu',
      renderCell: (params) => <DeviceMenu device={params.row} />,
    },
  ]

  const serviceColumns = [
    {
      headerName: 'Name',
      field: 'accountName',
      minWidth: 150,
      flex: 1,
    },
    {
      headerName: 'Email',
      field: 'subscriber_info.email',
      flex: 1,
      valueGetter: (params) => params.row.subscriber_info.email,
    },
    {
      headerName: 'Service',
      field: 'speed_profile',
      minWidth: 100,
      flex: 1,
    },
    // {
    //   headerName: 'CPE',
    //   field: 'cpe',
    //   minWidth: 75,
    //   flex: 0.5,
    //   valueGetter: (params) =>
    //     site.devices.find((d) =>
    //       d.services.find((service) => service._id === params.row._id)
    //     ).model,
    //   renderCell: (params) => {
    //     const cpe = site.devices.find((d) =>
    //       d.services.find((service) => service._id === params.row._id)
    //     )
    //     return (
    //       <Link
    //         style={{ textDecoration: 'inherit', color: 'inherit' }}
    //         to={generateUrl(cpe)}
    //       >
    //         {cpe?.model}
    //       </Link>
    //     )
    //   },
    // },
    // {
    //   headerName: 'Version',
    //   field: 'version',
    //   minWidth: 75,
    //   flex: 0.5,
    //   valueGetter: (params) =>
    //     site.devices.find((d) =>
    //       d.services.find((service) => service._id === params.row._id)
    //     ).firmware,
    // },
    {
      headerName: 'Revenue',
      field: 'mrr',
      type: 'number',
      minWidth: 100,
      flex: 0.25,
      valueGetter: (params) => {
        return params.row.mrr?.toFixed(2)
      },
    },
    // {
    //   headerName: 'Activation Date',
    //   field: 'activationDate',
    //   minWidth: 100,
    //   flex: 0.25,
    //   renderCell: (params) => {
    //     return <Moment format="YYYY/MM/DD">{params.row.activationDate}</Moment>
    //   },
    // },
    {
      headerName: 'Status',
      field: 'status',
      minWidth: 75,
      flex: 0.5,
    },
  ]

  const cpeColumns = [
    {
      field: 'name',
      headerName: 'Name',
      minWidth: 150,
      flex: 1.5,
      renderCell: (params) => (
        <Link
          style={{ textDecoration: 'inherit', color: 'inherit' }}
          to={generateUrl(params.row)}
        >
          {params.value}
        </Link>
      ),
    },
    {
      field: 'ip',
      headerName: 'IP Address',
      flex: 1,
      minWidth: 110,
      renderCell: (params) => (
        <a
          style={{ textDecoration: 'inherit', color: 'inherit' }}
          target="_blank"
          rel="noreferrer"
          href={`http://${params.row?.ip}`}
        >
          {params.row.ip}
        </a>
      ),
    },
    {
      field: 'expectedSignal',
      headerName: 'Expected Signal',
      flex: 1,
      valueGetter: (params) => params.row?.wireless?.expectedSignal,
    },
    {
      field: 'signal',
      headerName: 'Signal',
      flex: 1,
      valueGetter: (params) => params.row?.wireless?.signal,
    },
    {
      field: 'signalDelta',
      headerName: 'Signal Delta',
      flex: 1,
      valueGetter: (params) =>
        params.row?.wireless?.expectedSignal - params.row?.wireless?.signal,
    },
    {
      field: 'mac',
      headerName: 'MAC Address',
      flex: 1,
      minWidth: 100,
    },
    {
      field: 'uptime',
      headerName: 'Uptime',
      flex: 1,
      minWidth: 100,
      renderCell: (params) => formatUptime(params.row?.uptime),
    },
    {
      field: 'lastContact',
      headerName: 'Status',
      flex: 1,
      minWidth: 100,
      renderCell: (params) => (
        <>
          {params.row?.connected ? (
            'Connected'
          ) : params.row?.lastContact ? (
            <Moment fromNow>{params.row?.lastContact}</Moment>
          ) : (
            <Typography>Never Connected</Typography>
          )}
        </>
      ),
      sortComparator: (v1, v2) => {
        return v1 > v2 ? -1 : 1
      },
    },
    {
      field: 'menu',
      renderCell: (params) => <DeviceMenu device={params.row} />,
    },
  ]

  return (
    <>
      {loading ? (
        <Box
          sx={{
            display: 'flex',
            flexGrow: 1,
            height: `calc(100vh - ${theme.mixins.toolbar.minHeight}px)`,
            width: '100%',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          <SiteBar site={site} />
          <Paper
            sx={{
              p: 1,
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
            }}
          >
            <Grid container direction="column" sx={{ height: '100%' }}>
              <Grid item container direction="row" sx={{ flexGrow: 1 }}>
                <Grid
                  item
                  container
                  direction="column"
                  xs={12}
                  md={6}
                  sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}
                >
                  <Grid item sx={{ p: 1 }}>
                    <SiteOverview site={site} />
                  </Grid>
                  <Grid item sx={{ flexGrow: 1 }}>
                    <TabContext value={value}>
                      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList
                          onChange={handleChange}
                          aria-label="lab API tabs example"
                        >
                          <Tab label="Incidents" value="1" />
                          <Tab label="Services" value="2" />
                          <Tab
                            label={`Unassigned Devices (${unassigned.length})`}
                            value="3"
                          />
                        </TabList>
                      </Box>
                      <TabPanel value="1">
                        <DataGrid
                          autoHeight
                          getRowId={(row) => row._id}
                          rows={incidents}
                          columns={incidentColumns}
                          pageSize={25}
                          rowHeight={30}
                          pageSizeOptions={[5, 10, 25]}
                          initialState={{
                            sorting: {
                              sortModel: [{ field: 'createdAt', sort: 'desc' }],
                            },
                          }}
                        />
                      </TabPanel>
                      <TabPanel value="2">
                        <DataGrid
                          components={{
                            Toolbar: GridToolbar,
                          }}
                          initialState={{
                            sorting: {
                              sortModel: [{ field: 'name', sort: 'asc' }],
                            },
                          }}
                          autoHeight
                          rowHeight={30}
                          getRowId={(row) => row._id}
                          rows={site?.services}
                          columns={serviceColumns}
                          pageSize={25}
                          pageSizeOptions={[5, 10, 25]}
                        />
                      </TabPanel>
                      <TabPanel value="3">
                        <DataGrid
                          initialState={{
                            sorting: {
                              sortModel: [{ field: 'name', sort: 'asc' }],
                            },
                          }}
                          autoHeight
                          rowHeight={30}
                          getRowId={(row) => row._id}
                          rows={unassigned}
                          columns={cpeColumns}
                          pageSize={25}
                          pageSizeOptions={[5, 10, 25]}
                        />
                      </TabPanel>
                    </TabContext>
                  </Grid>
                </Grid>
                <Grid item container direction="column" xs={12} md={6}>
                  <Grid item sx={{ p: 1 }}>
                    <NetworkMap sites={[site]} />
                  </Grid>
                  {site.devices
                    .filter((el) => el.function === 'psu')
                    .map((device, i) => (
                      <Grid key={device._id} item sx={{ p: 1 }}>
                        {device.family === 'edgepower' && (
                          <EdgePower device={device} />
                        )}
                        {device.family === 'alg' && <Alg device={device} />}
                        {device.family === 'ict' && <Alg device={device} />}
                      </Grid>
                    ))}
                  {site.devices
                    .filter(
                      (device) =>
                        device.family === 'mikrotik' &&
                        device.function !== 'switch'
                    )
                    .map((mikrotik) => (
                      <Grid key={mikrotik._id} item sx={{ p: 1 }}>
                        <Mikrotik device={mikrotik} />
                      </Grid>
                    ))}
                  {site.devices
                    .filter((device) => device.function === 'switch')
                    .map((device) => (
                      <Grid key={device._id} item sx={{ p: 1 }}>
                        {device.family === 'edgeswitch' && (
                          <ES device={device} />
                        )}
                        {device.family === 'netonix' && (
                          <Netonix device={device} />
                        )}
                        {device.family === 'swos' && <SwOS device={device} />}
                      </Grid>
                    ))}
                </Grid>
              </Grid>
              <Grid item sx={{ p: 1 }}>
                <div style={{ display: 'flex', flexGrow: 1 }}>
                  <DataGrid
                    initialState={{
                      sorting: {
                        sortModel: [{ field: 'name', sort: 'asc' }],
                      },
                      columns: {
                        columnVisibilityModel: {
                          mac: false,
                          autoPower: false,
                        },
                      },
                    }}
                    autoHeight
                    getRowHeight={() => 'auto'}
                    density="compact"
                    getRowId={(row) => row._id}
                    rows={ptps}
                    columns={ptpColumns}
                  />
                </div>
              </Grid>
              <Grid item sx={{ p: 1 }}>
                <div style={{ display: 'flex', flexGrow: 1 }}>
                  <DataGrid
                    initialState={{
                      sorting: {
                        sortModel: [{ field: 'name', sort: 'asc' }],
                      },
                      columns: {
                        columnVisibilityModel: {
                          mac: false,
                          autoPower: false,
                        },
                      },
                    }}
                    autoHeight
                    getRowHeight={() => 'auto'}
                    density="compact"
                    getRowId={(row) => row._id}
                    rows={aps}
                    columns={apColumns}
                  />
                </div>
              </Grid>
            </Grid>
          </Paper>
        </>
      )}
    </>
  )
}

export default SiteScreen
