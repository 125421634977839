import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  topo: {nodes: [], edges: []},
  loading: false,
  error: null,
}

export const wsTopoSlice = createSlice({
  name: 'topo',
  initialState,
  reducers: {
    resetTopo: () => initialState,
    requestTopo: (state) => {
      state.loading = true
    },
    receiveTopo: (state, action) => {
      state.loading = false
      state.topo = action.payload
      state.error = null
    },
  },
})

export const { resetTopo, requestTopo, receiveTopo } = wsTopoSlice.actions

export default wsTopoSlice.reducer
