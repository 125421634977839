export const generateUrl = (data) => {
  return `/network/device/${data._id}`
  const { family, function: deviceFunction, _id, uplink } = data
  let url = ''
  switch (family) {
    case 'wave':
      switch (deviceFunction) {
        case 'ap-ptmp':
          url = `/network/device/${_id}`
          break
        case 'sta-ptmp':
          url = `/network/device/${uplink}/${_id}`
          break
      }
      break
    case 'ltu':
      switch (deviceFunction) {
        case 'sta-ptmp':
          url = `/network/device/${uplink}/${_id}`
          break
        default:
          url = `/network/device/${_id}`
          break
      }
      break
    case 'af60':
      switch (deviceFunction) {
        case 'ap-ptp':
          url = `/network/device/${_id}`
          break
        case 'sta-ptp':
          url = `/network/device/${uplink}/${_id}`
          break
      }
      break
    case 'aviat':
      url = `/network/device/${_id}`
      break
    case 'airFiber':
      url = `/network/device/${_id}`
      break
    case 'airmax':
      switch (deviceFunction) {
        case 'ap-ptmp':
          url = `/network/device/${_id}`
          break
        case 'sta-ptmp':
          url = `/network/device/${uplink}/${_id}`
          break
      }
      break
    case 'epmp':
      switch (deviceFunction) {
        case 'ap-ptmp':
          url = `/network/device/${_id}`
          break
        case 'sta-ptmp':
          url = `/network/device/${uplink}/${_id}`
          break
      }
      break
    case 'edgepower':
      break
    case 'mikrotik':
      switch (deviceFunction) {
        case 'router':
          break
        case 'switch':
          break
      }
    case 'edgeswitch':
      break
  }
  return url
}
