import React from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'
import { useAppContext } from '../../../context/AppProvider'

const MoveDialog = ({ device, setRemove, remove, siteId }) => {
  const { ws } = useAppContext()


  const removeDevice = () => {
    ws.removeDevices([device], siteId)
    setRemove(false)
  }

  return (
    <Dialog open={remove} onClose={() => setRemove(false)}>
      <DialogTitle>Remove {device.name}...</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Warning: Removing {device.name} is permanant. Are you sure?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setRemove(false)}>Cancel</Button>
        <Button onClick={removeDevice}>Remove</Button>
      </DialogActions>
    </Dialog>
  )
}

export default MoveDialog