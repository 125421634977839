import { Button, Divider, Grid, List, ListItem, ListItemIcon, ListItemText, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material'

import React, { useEffect, useState } from 'react'
import GroupsIcon from '@mui/icons-material/Groups'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Box } from '@mui/system'
import ProcessCard from '../Components/Core/ProcessCard'
import { useAppContext } from '../context/AppProvider'
import FileUpload from '../Components/Core/FileUpload'
import Moment from 'react-moment'


const ProcessGroupHead = () => (
  <TableHead>
    <TableRow>
      <TableCell>Process Group</TableCell>
      <TableCell align="right">Total Restarts</TableCell>
      {/* Add more group-level headers if needed */}
    </TableRow>
  </TableHead>
)

const ProcessDetailsHead = () => (
  <TableHead>
    <TableRow>
      <TableCell>ID</TableCell>
      <TableCell align="right">RAM</TableCell>
      <TableCell align="right">CPU</TableCell>
      <TableCell align="right">Status</TableCell>
      <TableCell align="right">Uptime</TableCell>
      <TableCell align="right">Restarts</TableCell>
    </TableRow>
  </TableHead>
)

const CollapsibleTableRow = ({ groupName, groupProcesses }) => {
  const [open, setOpen] = useState(false)
  const totalRestarts = groupProcesses.reduce((acc, process) => acc + process.restartCount, 0)

  return (
    <>
      <TableRow onClick={() => setOpen(!open)}>
        <TableCell>{groupName}</TableCell>
        <TableCell align="right">{totalRestarts}</TableCell>
        {/* Add more group-level cells if needed */}
      </TableRow>
      {open && (
        <TableRow>
          <TableCell colSpan={8} style={{ padding: 0 }}>
            <Table>
              <ProcessDetailsHead />
              <TableBody>
                {groupProcesses.map(process => (
                  <TableRow key={process.id}>
                    <TableCell component="th" scope="row">{process.id}</TableCell>
                    <TableCell align="right">{getReadableFileSizeString(process.monit.memory)}</TableCell>
                    <TableCell align="right">{process.monit.cpu}%</TableCell>
                    <TableCell align="right">{process.status}</TableCell>
                    <TableCell align="right"><Moment fromNow ago>{process.uptime}</Moment></TableCell>
                    <TableCell align="right">{process.restartCount}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableCell>
        </TableRow>
      )}
    </>
  )
}

const ProcessTable = ({ processes }) => {
  const groupedProcesses = processes.reduce((acc, process) => {
    acc[process.name] = acc[process.name] || []
    acc[process.name].push(process)
    return acc
  }, {})

  return (
    <Box sx={{ flexGrow: 1 }}>
      <TableContainer component={Paper}>
        <Table aria-label="processes table">
          <ProcessGroupHead />
          <TableBody>
            {Object.entries(groupedProcesses).map(([groupName, groupProcesses]) => (
              <CollapsibleTableRow key={groupName} groupName={groupName} groupProcesses={groupProcesses} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}


const getReadableFileSizeString = (fileSizeInBytes) => {
  let i = -1
  const byteUnits = [
    "kB",
    "MB",
    "GB",
    "TB",
    "PB",
    "EB",
    "ZB",
    "YB"
  ]
  do {
    fileSizeInBytes = fileSizeInBytes / 1000
    i++
  } while (fileSizeInBytes > 1000)

  return Math.max(fileSizeInBytes, 0.1).toFixed(1) + byteUnits[i]
}

const ToolsScreen = () => {
  const { ws } = useAppContext()

  const { processes, queueStatus } = useSelector((state) => state.wsServerStatus)

  const [ipv6Config, setIpv6Config] = useState('')
  const [siteId, setSiteId] = useState('')

  useEffect(() => {
    ws.joinServerRoom()
    return () => ws.leaveServerRoom()
  }, [])

  const handleExportSites = () => {
    ws.fetchSitesExport()
  }

  const toHex = (num) => {
    let hexValue = num.toString(16).padStart(2, '0') // Convert to hex and pad
    hexValue += '00'  // Add the two zero bytes
    return hexValue
  }

  useEffect(() => {
    const prefixID = toHex(Number(siteId))
    const prefix = '2606:2607'
    setIpv6Config(`/ipv6 dhcp-server
      add address-pool=HSIv6 interface=HSI name=HSIv6
      /ipv6 pool
      add name=HSIv6 prefix=${prefix}:${(parseInt(prefixID, 16) + 0x10).toString(16)}::/44 prefix-length=56
      /ipv6 address
      add address=${prefix}::${prefixID}/128 advertise=no interface=IdkBridge
      add address=${prefix}:${prefixID}::/40 advertise=no interface=IdkBridge
      add address=${prefix}:${prefixID}::1 interface=HSI
      /ipv6 nd
      set [ find default=yes ] dns=2001:4860:4860::8888,2001:4860:4860::8844 managed-address-configuration=yes other-configuration=yes
      /ipv6 settings
      set max-neighbor-entries=8192`.replace(/^      /gm, ''))
  }, [siteId])




  return (
    <>
      <Paper sx={{ p: 2 }}>
        <List>
          <ListItem>
            <ListItemIcon>
              <GroupsIcon />
            </ListItemIcon>
            <ListItemText id="export-sites" primary="Export Sites" />
            <Button
              variant="contained"
              onClick={() => {
                handleExportSites()
              }}>
              Download
            </Button>
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <GroupsIcon />
            </ListItemIcon>
            <ListItemText id="ap-finder" primary="AP FInder" />
            <Link
              style={{ textDecoration: 'inherit', color: 'inherit' }}
              to="/tools/apfinder">
              <Button
                variant="contained">
                Open
              </Button>
            </Link>
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <GroupsIcon />
            </ListItemIcon>
            <ListItemText id="topology" primary="Network Topology Map" />
            <Link
              style={{ textDecoration: 'inherit', color: 'inherit' }}
              to="/tools/topology">
              <Button
                variant="contained">
                Open
              </Button>
            </Link>
          </ListItem>
          <FileUpload />
          <TextField
            onChange={(e) => setSiteId(e.target.value)}>
            ID
          </TextField>
          {
            siteId && <pre>{ipv6Config}</pre>
          }
        </List>
      </Paper>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container>
          {
            queueStatus.map((queue) => {
              return (
                <Grid item sm={6} md={3} lg={2} key={queue.name}>
                  <Box sx={{ p: 1 }}>
                    <Paper sx={{ p: 1 }}>
                      <Grid container>
                        <Grid item xs container direction="column">
                          <Grid item xs>
                            <Divider>{queue.name} Jobs</Divider>
                            <Typography variant="body2" color="text.secondary">
                              Active: {queue.active}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                              Queued: {queue.waiting}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                              Waiting: {queue.delayed}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Paper>
                  </Box>
                </Grid>
              )
            })
          }
        </Grid>
      </Box>
      <ProcessTable processes={processes} />
    </>
  )



}

export default ToolsScreen