import { Badge } from '@mui/material'
import { createRoot } from 'react-dom/client'


const siteIconCache = new Map()

const SiteBadge = ({ site }) => {
  const siteDevices = site.devices.filter((el) => el.backbone)
  const disconnectedDevices = siteDevices.filter((el) => el.connected === false)

  const percentage = (disconnectedDevices.length / siteDevices.length) * 100
  const siteStatus =
    percentage >= 80 ? 'red' :
      percentage >= 50 ? 'orange' :
        percentage >= 30 ? 'yellow' :
          percentage > 0 ? 'green' :
            '#018BD0'

  return (
    <Badge
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      badgeContent={disconnectedDevices.length}
      color="error"
      overlap="circular"
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '24px',
          width: '24px',
          backgroundColor: siteStatus,
          border: '1px solid white',
          borderRadius: '50%',
          color: 'white',
          fontWeight: 'bold',
          fontSize: '16px',
          lineHeight: '2',
          textTransform: 'uppercase',
        }}
      >
        {`${site.name[0]?.toUpperCase()}${site.name[1]?.toLowerCase()}`}
      </div>
    </Badge>
  )
}

const getDeviceStateSnapshot = (devices) => {
  const stateMap = new Map()
  devices.forEach(device => {
    if (device.backbone) {
      stateMap.set(device._id, device.connected)
    }
  })
  return stateMap
}

const hasDeviceStateChanged = (currentMap, cachedMap) => {
  // Check if the number of devices has changed
  if (currentMap.size !== cachedMap.size) {
    return true
  }

  // Check if any device's 'connected' status has changed
  for (let [id, connected] of currentMap) {
    if (cachedMap.get(id) !== connected) {
      return true // Found a change in connected status
    }
  }

  return false // No changes found
}

const siteIcon = (site, L) => {
  // Generate the current snapshot of device states as a Map
  const currentSnapshot = getDeviceStateSnapshot(site.devices)

  // Check if the icon for this site is already created and cached
  if (siteIconCache.has(site._id)) {
    const cachedEntry = siteIconCache.get(site._id)
    if (!hasDeviceStateChanged(currentSnapshot, cachedEntry.deviceStateSnapshot)) {
      // No change in device states; reuse the cached icon
      return cachedEntry.divIcon
    }
  }

  // Device states have changed or icon not cached; create a new divIcon
  const div = document.createElement('div')
  const root = createRoot(div) // Assume createRoot is imported or defined elsewhere
  root.render(<SiteBadge site={site} />)

  const siteIcon = new L.divIcon({ // Assume L.divIcon is defined elsewhere (e.g., Leaflet map)
    className: 'dummy',
    iconAnchor: [12, 12],
    html: div,
  })

  // Cache the newly created icon along with the current device state snapshot (Map)
  siteIconCache.set(site._id, { divIcon: siteIcon, deviceStateSnapshot: currentSnapshot })

  return siteIcon
}

export default siteIcon