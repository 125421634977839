import React, { useState } from 'react'
import AddIcon from '@mui/icons-material/Add'
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Step,
  Stepper,
  StepLabel,
  TextField,
  Typography,
} from '@mui/material'

import DhcpServerForm from './DhcpServerForm'
import IpAddressForm from './IpAddressForm'
import InterfaceForm from './InterfaceForm'

const mikrotikRouters = [
  {
    model: '1009',
    interfaces: [
      { name: 'combo1', type: 'ethernet', vlanId: '1' },
      { name: 'ether1', type: 'ethernet', vlanId: '1' },
      { name: 'ether2', type: 'ethernet', vlanId: '1' },
      { name: 'ether3', type: 'ethernet', vlanId: '1' },
      { name: 'ether4', type: 'ethernet', vlanId: '1' },
      { name: 'ether5', type: 'ethernet', vlanId: '1' },
      { name: 'ether6', type: 'ethernet', vlanId: '1' },
      { name: 'ether7', type: 'ethernet', vlanId: '1' },
      { name: 'sfp-sfpplus1', type: 'ethernet', vlanId: '1' },
    ]
  },
  {
    model: '2004',
    interfaces: [
      { name: 'ether1', type: 'ethernet', vlanId: '1' },
      { name: 'ether2', type: 'ethernet', vlanId: '1' },
      { name: 'ether3', type: 'ethernet', vlanId: '1' },
      { name: 'ether4', type: 'ethernet', vlanId: '1' },
      { name: 'ether5', type: 'ethernet', vlanId: '1' },
      { name: 'ether6', type: 'ethernet', vlanId: '1' },
      { name: 'ether7', type: 'ethernet', vlanId: '1' },
      { name: 'ether8', type: 'ethernet', vlanId: '1' },
      { name: 'ether9', type: 'ethernet', vlanId: '1' },
      { name: 'ether10', type: 'ethernet', vlanId: '1' },
      { name: 'ether11', type: 'ethernet', vlanId: '1' },
      { name: 'ether12', type: 'ethernet', vlanId: '1' },
      { name: 'ether13', type: 'ethernet', vlanId: '1' },
      { name: 'ether14', type: 'ethernet', vlanId: '1' },
      { name: 'ether15', type: 'ethernet', vlanId: '1' },
      { name: 'ether16', type: 'ethernet', vlanId: '1' },
      { name: 'sfp-sfpplus1', type: 'ethernet', vlanId: '1' },
      { name: 'sfp-sfpplus2', type: 'ethernet', vlanId: '1' },
    ]
  },
  {
    model: '2116',
    interfaces: [
      { name: 'ether1', type: 'ethernet', vlanId: '1' },
      { name: 'ether2', type: 'ethernet', vlanId: '1' },
      { name: 'ether3', type: 'ethernet', vlanId: '1' },
      { name: 'ether4', type: 'ethernet', vlanId: '1' },
      { name: 'ether5', type: 'ethernet', vlanId: '1' },
      { name: 'ether6', type: 'ethernet', vlanId: '1' },
      { name: 'ether7', type: 'ethernet', vlanId: '1' },
      { name: 'ether8', type: 'ethernet', vlanId: '1' },
      { name: 'ether9', type: 'ethernet', vlanId: '1' },
      { name: 'ether10', type: 'ethernet', vlanId: '1' },
      { name: 'ether11', type: 'ethernet', vlanId: '1' },
      { name: 'ether12', type: 'ethernet', vlanId: '1' },
      { name: 'ether13', type: 'ethernet', vlanId: '1' },
      { name: 'sfp-sfpplus1', type: 'ethernet', vlanId: '1' },
      { name: 'sfp-sfpplus2', type: 'ethernet', vlanId: '1' },
      { name: 'sfp-sfpplus3', type: 'ethernet', vlanId: '1' },
      { name: 'sfp-sfpplus4', type: 'ethernet', vlanId: '1' },
    ]
  },
]

const incrementIP = (ip) => {
  // Convert each octet to its 8-bit representation and merge them into a single 32-bit integer
  const ipAsInt = ip.split('.').reduce((acc, octet) => (acc << 8) + parseInt(octet, 10), 0)

  // Increment the 32-bit integer
  const nextIpAsInt = ipAsInt + 1

  // Convert it back to an IPv4 string
  const nextIp = [
    (nextIpAsInt >>> 24) & 0xFF,
    (nextIpAsInt >>> 16) & 0xFF,
    (nextIpAsInt >>> 8) & 0xFF,
    nextIpAsInt & 0xFF
  ].join('.')

  return nextIp
}

const SiteCreate = () => {
  const [open, setOpen] = useState(true)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const [siteName, setSiteName] = useState()
  const [mastType, setMastType] = useState('')
  const [siteHeight, setSiteHeight] = useState()
  const [siteLatitude, setSiteLatitude] = useState()
  const [siteLongitude, setSiteLongitude] = useState()
  const [routerModel, setRouterModel] = useState('')
  const [routerInterfaces, setRouterInterfaces] = useState([])
  const [routerAddresses, setRouterAddresses] = useState([])
  const [dhcpServers, setDhcpServers] = useState([])
  const [aps, setAps] = useState([])

  const [activeStep, setActiveStep] = useState(0)

  // handle add new DHCP Server
  const handleAddDhcpServer = () => {
    setDhcpServers([
      ...dhcpServers,
      { name: '', interface: '' },
    ])
  }
  // handle add new Interface
  const handleAddInterface = () => {
    setRouterInterfaces([
      ...routerInterfaces,
      { name: '', vlanId: 1 },
    ])
  }
  // handle add new Interface
  const handleAddAddress = () => {
    setRouterAddresses([
      ...routerAddresses,
      {},
    ])
  }
  const handleCreateSite = () => {
    const newSite = {
      siteDetail: {
        name: siteName,
        type: mastType,
        height: siteHeight,
        latitude: siteLatitude,
        longitude: siteLongitude,
      },
      routerConfiguration: {
        interfaces: routerInterfaces,
        addresses: routerAddresses,
        dhcpServers: dhcpServers
      }
    }

    console.log(newSite)
    //siteCreator(newSite)
  }

  const steps = [
    {
      label: 'Details',
      contents: (
        <Grid container direction='column' sx={{ p: 1 }}>
          <Grid container item direction='row' justifyContent='space-between'>
            <Grid item xs sx={{ p: 1 }}>
              <TextField
                autoFocus
                autoComplete='off'
                fullWidth
                label='Site Name'
                value={siteName}
                onChange={(e) => setSiteName(e.target.value)}
              />
            </Grid>
          </Grid>
          <Grid container item direction='row' justifyContent='space-between'>
            <Grid item xs sx={{ p: 1 }}>
              <FormControl fullWidth>
                <InputLabel>Mast Type</InputLabel>
                <Select
                  value={mastType}
                  onChange={(e) => setMastType(e.target.value)}>
                  <MenuItem value={'Elevator'}>{'Elevator'}</MenuItem>
                  <MenuItem value={'Guyed'}>{'Guyed'}</MenuItem>
                  <MenuItem value={'Monopole'}>{'Monopole'}</MenuItem>
                  <MenuItem value={'Rooftop'}>{'Rooftop'}</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs sx={{ p: 1 }}>
              <TextField
                autoComplete='off'
                required
                fullWidth
                label='Height'
                value={siteHeight}
                onChange={(e) => setSiteHeight(e.target.value)}
              />
            </Grid>
          </Grid>
          <Grid container item direction='row' justifyContent='space-between'>
            <Grid item xs sx={{ p: 1 }}>
              <TextField
                autoComplete='off'
                required
                fullWidth
                label='Latitude'
                value={siteLatitude}
                onChange={(e) => setSiteLatitude(e.target.value)}
              />
            </Grid>
            <Grid item xs sx={{ p: 1 }}>
              <TextField
                autoComplete='off'
                required
                fullWidth
                label='Longitude'
                value={siteLongitude}
                onChange={(e) => setSiteLongitude(e.target.value)}
              />
            </Grid>
          </Grid>
        </Grid>
      ),
    },
    {
      label: 'Interface Configuration',
      contents: (
        <Grid container direction='column' sx={{ p: 1 }}>
          <Grid item xs sx={{ p: 1 }}>
            <FormControl fullWidth>
              <InputLabel>Router Model</InputLabel>
              <Select
                value={routerModel}
                onChange={(e) => {
                  mikrotikRouters.map(el => {
                    if (el.model === e.target.value) {
                      setRouterModel(el.model)
                      setRouterInterfaces(el.interfaces)
                    }
                  })
                }}>
                <MenuItem value={'1009'}>{'1009'}</MenuItem>
                <MenuItem value={'2004'}>{'2004'}</MenuItem>
                <MenuItem value={'2116'}>{'2116'}</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid container item direction='row'>
            {routerInterfaces.map((x, i) => {
              return (
                <InterfaceForm routerInterfaces={routerInterfaces} setRouterInterfaces={setRouterInterfaces} iface={x} ifaceIndex={i} />
              )
            })}
          </Grid>
          <Grid item xs sx={{ p: 1 }}>
            <Button
              fullWidth
              variant={'contained'}
              aria-label='Add Interface'
              onClick={handleAddInterface}
              endIcon={<AddIcon />}>
              Add Interface
            </Button>
          </Grid>
        </Grid>
      ),
    },
    {
      label: 'IP Configuration',
      contents: (
        <Grid container direction='column' sx={{ p: 1 }}>
          {routerAddresses.map((x, i) => (
            <IpAddressForm setRouterAddresses={setRouterAddresses} routerAddresses={routerAddresses} routerInterfaces={routerInterfaces} address={x} addressIndex={i} />
          ))}
          <Grid item xs sx={{ p: 1 }}>
            <Button
              fullWidth
              variant={'contained'}
              aria-label='Add Address'
              onClick={handleAddAddress}
              endIcon={<AddIcon />}>
              Add Address
            </Button>
          </Grid>
        </Grid>
      ),
    },
    {
      label: 'DHCP Servers',
      contents: (
        <Grid container direction='column' sx={{ p: 1 }}>
          {dhcpServers.map((x, i) => (
            <DhcpServerForm routerInterfaces={routerInterfaces} setDhcpServers={setDhcpServers} dhcpServers={dhcpServers} routerAddresses={routerAddresses} server={x} serverIndex={i} />
          ))}
          <Grid item sx={{ p: 1 }}>
            <Button fullWidth variant='contained' aria-label='Add DHCP Server' onClick={handleAddDhcpServer} endIcon={<AddIcon />}>
              Add DHCP Server
            </Button>
          </Grid>
        </Grid>
      ),
    },
    {
      label: 'AP Configuration',
      contents: (
        <Grid container direction='column' sx={{ p: 1 }}>
          {dhcpServers.map((x, i) => (
            <DhcpServerForm routerInterfaces={routerInterfaces} setDhcpServers={setDhcpServers} dhcpServers={dhcpServers} routerAddresses={routerAddresses} server={x} serverIndex={i} />
          ))}
          <Grid item sx={{ p: 1 }}>
            <Button fullWidth variant='contained' aria-label='Add DHCP Server' onClick={handleAddDhcpServer} endIcon={<AddIcon />}>
              Add DHCP Server
            </Button>
          </Grid>
        </Grid>
      ),
    },
    {
      label: 'Switch Configuration',
      contents: (
        <Grid container direction='column' sx={{ p: 1 }}>
          {dhcpServers.map((x, i) => (
            <DhcpServerForm routerInterfaces={routerInterfaces} setDhcpServers={setDhcpServers} dhcpServers={dhcpServers} routerAddresses={routerAddresses} server={x} serverIndex={i} />
          ))}
          <Grid item sx={{ p: 1 }}>
            <Button fullWidth variant='contained' aria-label='Add DHCP Server' onClick={handleAddDhcpServer} endIcon={<AddIcon />}>
              Add DHCP Server
            </Button>
          </Grid>
        </Grid>
      ),
    },
  ]

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const handleReset = () => {
    setActiveStep(0)
  }

  return (
    <Dialog open={open} onClose={null} fullScreen>
      <DialogTitle>New Site</DialogTitle>
      <DialogContent sx={{ p: 3 }}>
        <Box sx={{ p: 2, width: '100%' }}>
          <Stepper alternativeLabel activeStep={activeStep}>
            {steps.map((step, index) => {
              const stepProps = {}
              const labelProps = {}
              return (
                <Step key={step.label} {...stepProps}>
                  <StepLabel {...labelProps}>{step.label}</StepLabel>
                </Step>
              )
            })}
          </Stepper>
          {activeStep === steps.length ? (
            <React.Fragment>
              <Typography sx={{ mt: 2, mb: 1 }}>
                All steps completed - you&apos;re finished
              </Typography>
              <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Box sx={{ flex: '1 1 auto' }} />
                <Button onClick={handleReset}>Reset</Button>
              </Box>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Typography sx={{ mt: 2, mb: 1 }}>
                {steps[activeStep].contents}
              </Typography>
              <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Button
                  variant={'contained'}
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  sx={{ mr: 1 }}>
                  Back
                </Button>
                <Box sx={{ flex: '1 1 auto' }} />

                {activeStep === steps.length - 1 ? (
                  <>
                    <Button variant={'contained'} onClick={handleCreateSite}>
                      Submit
                    </Button>
                  </>
                ) : (
                  <Button variant={'contained'} onClick={handleNext}>
                    Next
                  </Button>
                )}
              </Box>
            </React.Fragment>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default SiteCreate
