import React, { useEffect, useState } from 'react'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import { Button, FormControl, Grid, IconButton, InputLabel, Paper, Select, TextField, MenuItem } from "@mui/material"
import DhcpNetworkForm from './DhcpNetworkForm'



const DhcpServerForm = ({ routerAddresses, routerInterfaces, setDhcpServers, dhcpServers, server, serverIndex }) => {

  const [dhcpNetworks, setDhcpNetworks] = useState([])

  useEffect(() => {
    const list = [...dhcpServers]
    list[serverIndex]['dhcpNetworks'] = dhcpNetworks
  }, [dhcpNetworks])

  const handleDhcpServers = (e, index) => {
    const { name, value } = e.target
    const list = [...dhcpServers]
    list[index][name] = value
    setDhcpServers(list)
    console.log(dhcpServers)
  }
  // handle remove DHCP Server
  const handleRemoveDhcpServer = (index) => {
    const list = [...dhcpServers]
    list.splice(index, 1)
    setDhcpServers(list)
  }
  // handle add new DHCP Network
  const handleAddDhcpNetwork = () => {
    setDhcpNetworks([
      ...dhcpNetworks,
      {},
    ])
  }

  return (
    <Grid item>
      <Paper sx={{ p: 1, position: 'relative' }}>
        <IconButton aria-label='delete' sx={{ position: 'absolute', top: 8, right: 8 }} onClick={() => handleRemoveDhcpServer(serverIndex)}>
          <DeleteIcon />
        </IconButton>
        <Grid container direction='row' justifyContent={'space-between'}>
          <Grid container item xs={6} direction='column'>
            <Grid item sx={{ p: 1 }}>
              <TextField
                size="small"
                fullWidth
                autoComplete='off'
                name='name'
                label='Name'
                type='text'
                variant="filled"
                value={server.name}
                onChange={(e) => handleDhcpServers(e, serverIndex)}
              />
            </Grid>
            <Grid item sx={{ p: 1 }}>
              <FormControl fullWidth size="small">
                <InputLabel>Interface</InputLabel>
                <Select name='interface' value={server.interface} onChange={(e) => handleDhcpServers(e, serverIndex)}>
                  {routerInterfaces?.map((i) => (
                    <MenuItem key={i} value={i.name}>
                      {i.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container item xs={6} direction='column'>
            {dhcpNetworks.map((y, j) => (
              <DhcpNetworkForm setDhcpNetworks={setDhcpNetworks} dhcpNetworks={dhcpNetworks} routerAddresses={routerAddresses} server={server} network={y} networkIndex={j} />
            ))}
            <Grid item sx={{ p: 1 }}>
              <Button fullWidth variant='contained' aria-label='Add DHCP Network' onClick={handleAddDhcpNetwork} endIcon={<AddIcon />}>
                Add DHCP Network
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  )
}

export default DhcpServerForm