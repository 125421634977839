import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  accountId: null,
  deviceHostName: null,
  dlSpeed: null,
  ulSpeed: null,
  lat: null,
  long: null,
  loading: false,
  error: null,
}

export const wsSonarSlice = createSlice({
  name: 'sonar',
  initialState,
  reducers: {
    resetSonar: () => initialState,
    requestSonar: (state) => {
      state.loading = true
    },
    receiveSonar: (state, action) => {
      state._id = action.payload._id
      state.accountId = action.payload.accountId
      state.accountName = action.payload.accountName
      state.deviceHostName = action.payload.deviceHostName
      state.dlSpeed = action.payload.dlSpeed
      state.ulSpeed = action.payload.ulSpeed
      state.lat = action.payload.lat
      state.long = action.payload.long
      state.loading = false
      state.error = null
    },
  },
})

export const { resetSonar, requestSonar, receiveSonar } =
  wsSonarSlice.actions

export default wsSonarSlice.reducer