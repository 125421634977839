import React from 'react'
import { Canvas } from '@react-three/fiber'
import { OrbitControls, Cylinder } from '@react-three/drei'

const App = () => {

  const calculateFresnelZone = () => {
    const speedOfLight = 3e8 // speed of light in m/s
    const freq = 5e9 // frequency in Hz
    const d1 = 1000 // distance of point 1 from common point in m
    const d2 = 1000 // distance of point 2 from common point in m
    const D = 2000 // total distance in m

    // Calculate the radius of the first Fresnel zone in meters
    return Math.sqrt((1 * speedOfLight / freq * d1 * d2) / D)
  }

  const radius = calculateFresnelZone()

  return (
    <Canvas camera={{ position: [0, 0, 3000] }}>
      <ambientLight />
      <pointLight position={[10, 10, 10]} />
      <Cylinder args={[radius, radius, 2000, 32]} wireframe>
        <meshBasicMaterial attach="material" color="green" />
      </Cylinder>
      <OrbitControls />
    </Canvas>
  )
}

export default App