import { Netmask } from 'netmask'

const createMikrotik = (
  bhNetworks,
  routeNetworks,
  fiberInterfaceName,
  fiberNet,
  siteName,
  managementVlan,
  managementNet,
  hsiVlan,
  cgNatNet,
  cgNatPublic,
  publicNet,
  loopbackNet
) => {
  let config = []
  const managementOcts = managementNet.base.split('.').slice(0, 3).join('.')
  const cgNatStartOcts =
    cgNatNet && cgNatNet.base.split('.').slice(0, 3).join('.')
  const publicStartOcts =
    publicNet && publicNet.base.split('.').slice(0, 3).join('.')

  const vlans = () => {
    let vlans = [
      '/interface vlan',
      `add interface=IdkBridge name=HSI vlan-id=${hsiVlan}`,
      `add interface=IdkBridge name=Management vlan-id=${managementVlan}`,
    ]
    bhNetworks.forEach((el, i) => {
      vlans.push(
        `add interface=IdkBridge name=BH${101 + i} vlan-id=${101 + i} comment="${el.remoteSite}"`
      )
    })
    return vlans
  }

  const bridges = [
    '/interface bridge',
    'add name=loopback',
    'add name=IdkBridge',
    '/interface bridge port',
    'add bridge=IdkBridge interface=sfp-sfpplus1',
    'add bridge=IdkBridge interface=ether7',
    'add bridge=IdkBridge interface=Management',
  ]

  const addresses = () => {
    let addresses = ['/ip address']
    addresses.push(
      `add address=${managementNet.first + '/' + managementNet.bitmask
      } interface=ManagementBridge network=${managementNet.base}`
    )
    cgNatNet &&
      addresses.push(
        `add address=${cgNatNet.first + '/' + cgNatNet.bitmask
        } interface=HSI network=${cgNatNet.base}`
      )
    cgNatNet &&
      addresses.push(
        `add address=${cgNatPublic.first + '/' + cgNatPublic.bitmask
        } interface=HSI network=${cgNatPublic.base}`
      )
    publicNet &&
      addresses.push(
        `add address=${publicNet.first + '/' + publicNet.bitmask
        } interface=HSI network=${publicNet.base}`
      )
    addresses.push(
      `add address=${loopbackNet.first + '/' + loopbackNet.bitmask
      } interface=loopback network=${loopbackNet.base}`
    )
    fiberNet &&
      addresses.push(
        `add address=${fiberNet.last + '/' + fiberNet.bitmask
        } interface=${fiberInterfaceName} network=${fiberNet.base}`
      )
    bhNetworks.forEach((el, i) => {
      addresses.push(
        `add address=${el.isFirst
          ? el.subnet.first
          : el.subnet.last + '/' + el.subnet.bitmask
        } interface=IdkBridge network=${el.subnet.base} comment="${el.remoteSite}"`
      )
    })
    return addresses
  }

  const ipPools = () => {
    let pools = ['/ip pool']
    pools.push(
      `add name=managementPool ranges=${managementOcts}.25-${managementNet.last}`
    )
    cgNatNet &&
      pools.push(`add name=HSIPool ranges=${cgNatStartOcts}.2-${cgNatNet.last}`)
    return pools
  }

  const dhcpServer = () => {
    let dhcpServers = ['/ip dhcp-server']
    cgNatNet &&
      dhcpServers.push(
        `add address-pool=HSIPool disabled=no interface=HSI name=HSIServer`
      )
    dhcpServers.push(
      `add address-pool=managementPool disabled=no interface=ManagementBridge name=ManagementServer`
    )
    return dhcpServers
  }

  const dhcpNetwork = () => {
    let dhcpNetworks = ['/ip dhcp-server network']
    dhcpNetworks.push(
      `add address=${managementNet.base + '/' + managementNet.bitmask
      } dns-server=1.1.1.1 gateway=${managementNet.first}`
    )
    cgNatNet &&
      dhcpNetworks.push(
        `add address=${cgNatNet.base + '/' + cgNatNet.bitmask
        } dns-server=1.1.1.1 gateway=${cgNatNet.first}`
      )
    publicNet &&
      dhcpNetworks.push(
        `add address=${publicNet.base + '/' + publicNet.bitmask
        } dns-server=1.1.1.1 gateway=${publicNet.first}`
      )
    return dhcpNetworks
  }

  const bgpNetwork = () => {
    let bgpNetworks = ['/routing bgp network']
    routeNetworks.forEach((el) => {
      bgpNetworks.push(`add network=${el}`)
    })
    return bgpNetworks
  }

  const bgpInstance = () => {
    let bgpInstances = ['/routing bgp instance']
    bgpInstances.push(
      `set default as=65000 redistribute-connected=yes redistribute-ospf=yes redistribute-static=yes router-id=${fiberNet.last}`
    )
    return bgpInstances
  }

  const bgpPeer = () => {
    let bgpPeer = ['/routing bgp peer']
    bgpPeer.push(
      `add name=Ideatek remote-address=${fiberNet.first} remote-as=27425`
    )
    return bgpPeer
  }

  const ospf = () => {
    let ospf = ['/routing ospf instance']
    ospf.push(
      `set [ find default=yes ] distribute-default=if-installed-as-type-1 redistribute-bgp=as-type-1 redistribute-connected=as-type-1 redistribute-static=as-type-1 router-id=${loopbackNet.first}`
    )
    ospf.push('/routing ospf network')
    routeNetworks.forEach((el) => {
      ospf.push(`add area=backbone network=${el}`)
    })
    return ospf
  }

  const misc = [
    '/ip dns set servers=1.1.1.1',
    `/user set [find name=${process.env.REACT_APP_ROUTER_USER}] password=${process.env.REACT_APP_ROUTER_PASS}`,
    `/system identity set name=${siteName}`,
  ]

  const firewallList = [
    '/ip firewall address-list',
    'add address=10.0.0.0/8 list=trusted',
    'add address=204.107.216.0/24 list=trusted',
    'add address=64.6.130.0/24 list=trusted',
    'add address=63.215.100.0/22 list=trusted',
    'add address=64.6.132.0/23 list=trusted',
    'add address=162.213.164.0/22 list=trusted',
    'add address=207.178.96.0/19 list=trusted',
    'add address=204.9.24.0/22 list=trusted',
    'add address=8.41.16.0/21 list=trusted',
    'add address=8.43.48.0/21 list=trusted',
    'add address=8.48.112.0/22 list=trusted',
    'add address=8.35.114.0/23 list=trusted',
    'add address=8.7.92.0/23 list=trusted',
    'add address=198.241.49.0/24 list=trusted',
    'add address=198.241.62.0/24 list=trusted',
    'add address=213.170.146.0/24 list=trusted',
    'add address=104.36.88.0/22 list=trusted',
  ]

  const firewallFilter = [
    '/ip firewall filter',
    'add action=drop chain=input comment="::: Drop untrusted INPUT WINBOX" dst-port=8791 protocol=tcp src-address-list=!trusted',
    'add action=drop chain=input comment="::: Drop untrusted INPUT API" dst-port=8728 protocol=tcp src-address-list=!trusted',
    'add action=drop chain=input comment="::: Drop untrusted INPUT SSH" dst-port=22 protocol=tcp src-address-list=!trusted',
    'add action=drop chain=input comment="::: Drop untrusted INPUT TELNET" dst-port=23 protocol=tcp src-address-list=!trusted',
    'add action=drop chain=input comment="::: Drop untrusted INPUT FTP" dst-port=21 protocol=tcp src-address-list=!trusted',
    'add action=drop chain=input comment="::: Drop untrusted INPUT API-SSL" dst-port=8729 protocol=tcp src-address-list=!trusted',
    'add action=drop chain=input comment="::: Drop untrusted INPUT HTTP" dst-port=80 protocol=tcp src-address-list=!trusted',
    'add action=drop chain=input comment="::: Drop untrusted INPUT HTTPS" dst-port=443 protocol=tcp src-address-list=!trusted',
  ]

  const firewallNat = () => {
    let firewallNatRules = []
    const toAddress = cgNatPublic.first
    firewallNatRules.push('/ip firewall nat')
    firewallNatRules.push(
      `add action=src-nat chain=srcnat protocol=!ospf src-address=10.105.0.0/16 to-addresses=${toAddress}`
    )
    firewallNatRules.push(
      `add action=src-nat chain=srcnat protocol=icmp src-address=${cgNatNet.base + '/' + cgNatNet.bitmask
      } to-addresses=${toAddress}`
    )
    firewallNatRules.push(
      `add action=jump chain=srcnat jump-target=xxx src-address=${cgNatStartOcts}.2-${cgNatStartOcts}.254`
    )
    for (let i = 0; i <= 16; i++) {
      firewallNatRules.push(
        `add action=jump chain=xxx jump-target=xxx-${i} src-address=${cgNatStartOcts}.${15 * i + 2
        }-${cgNatStartOcts}.${15 * i + 16 === 256 ? 254 : 15 * i + 16}`
      )
    }
    for (let i = 2; i <= 254; i++) {
      let m = i - 2
      let portBegin = 249 * m + (1026 + m)
      let portEnd = 249 * m + (1275 + m)
      let chain = Math.floor(m / 15)
      firewallNatRules.push(
        `add action=src-nat chain=xxx-${chain} protocol=tcp src-address=${cgNatStartOcts}.${i} to-addresses=${toAddress} to-ports=${portBegin}-${portEnd}`
      )
      firewallNatRules.push(
        `add action=src-nat chain=xxx-${chain} protocol=udp src-address=${cgNatStartOcts}.${i} to-addresses=${toAddress} to-ports=${portBegin}-${portEnd}`
      )
    }
    return firewallNatRules
  }

  let vlanLines = vlans()
  let addressLines = addresses()
  let natLines = firewallNat()
  let poolLines = ipPools()
  let dhcpServerLines = dhcpServer()
  let dhcpNetworkLines = dhcpNetwork()
  let bgpNetworkLines = fiberNet && bgpNetwork()
  let bgpInstanceLines = fiberNet && bgpInstance()
  let bgpPeerLines = fiberNet && bgpPeer()
  let ospfLines = ospf()
  config = bridges.concat(
    vlanLines,
    poolLines,
    addressLines,
    misc,
    dhcpServerLines,
    dhcpNetworkLines,
    fiberNet && bgpNetworkLines,
    fiberNet && bgpInstanceLines,
    fiberNet && bgpPeerLines,
    ospfLines,
    firewallList,
    firewallFilter,
    natLines
  )
  return config.filter(Boolean).join('\r\n')
}

export const createMikrotikConfig = (
  bhNetworks,
  fiberInterfaceName,
  fiberNet,
  siteName,
  managementVlan,
  managementNet,
  hsiVlan,
  cgNatNet,
  cgNatPublic,
  publicNet,
  loopbackNet
) => {
  const routeNetworks = [
    managementNet.base + '/' + managementNet.bitmask,
    loopbackNet.base + '/' + loopbackNet.bitmask,
  ]
  publicNet && routeNetworks.push(publicNet.base + '/' + publicNet.bitmask)
  fiberNet && routeNetworks.push(fiberNet.base + '/' + fiberNet.bitmask)
  cgNatPublic &&
    routeNetworks.push(cgNatPublic.base + '/' + cgNatPublic.bitmask)
  bhNetworks.forEach((el) => {
    routeNetworks.push(el.subnet.base + '/' + el.subnet.bitmask)
  })

  let file = createMikrotik(
    bhNetworks,
    routeNetworks,
    fiberInterfaceName,
    fiberNet,
    siteName,
    managementVlan,
    managementNet,
    hsiVlan,
    cgNatNet,
    cgNatPublic,
    publicNet,
    loopbackNet
  )
  let cfgFile = new Blob([file], { type: 'text/plain;charset=utf-8' })
  return cfgFile
}
