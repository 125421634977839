import React, { useState } from 'react'
import axios from 'axios'
import { Button, Box, Typography, CircularProgress } from '@mui/material'
import { CloudUpload as CloudUploadIcon } from '@mui/icons-material'

const FileUpload = () => {
  const [selectedFile, setSelectedFile] = useState(null)
  const [uploading, setUploading] = useState(false)
  const [uploadSuccess, setUploadSuccess] = useState(false)
  const [uploadError, setUploadError] = useState(false)

  const handleFileUpload = async (file) => {
    setUploading(true)
    setUploadSuccess(false)
    setUploadError(false)

    const formData = new FormData()

    const data = {
      name: file.name,
      size: file.size,
      type: file.type,
    }
    formData.append('data', JSON.stringify(data))
    formData.append('file', file)

    try {
      await axios.post('/api/files/firmware/upload', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })

      setUploading(false)
      setUploadSuccess(true)
    } catch (error) {
      setUploading(false)
      setUploadError(true)
    }
  }

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0])
  }

  const handleUploadClick = () => {
    if (!selectedFile) {
      alert('Please select a file to upload')
      return
    }

    handleFileUpload(selectedFile)
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Typography variant="h6">File Upload</Typography>
      <Box mt={2} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <input
          accept="*/*"
          id="contained-button-file"
          type="file"
          hidden
          onChange={handleFileChange}
        />
        <label htmlFor="contained-button-file">
          <Button
            component="span"
            variant="contained"
            color="primary"
            startIcon={<CloudUploadIcon />}
          >
            Choose File
          </Button>
        </label>
        {selectedFile && (
          <Typography variant="subtitle1" mt={1}>
            {selectedFile.name}
          </Typography>
        )}
      </Box>
      <Box mt={2}>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleUploadClick}
          disabled={uploading}
        >
          Upload
        </Button>
      </Box>
      {uploading && (
        <Box mt={2}>
          <CircularProgress />
        </Box>
      )}
      {uploadSuccess && (
        <Typography variant="subtitle1" color="success.main" mt={2}>
          File uploaded successfully.
        </Typography>
      )}
      {uploadError && (
        <Typography variant="subtitle1" color="error.main" mt={2}>
          File upload failed. Please try again.
        </Typography>
      )}
    </Box>
  )
}

export default FileUpload