import React from 'react';
import { AppBar, Box, Toolbar, Typography, Button } from '@mui/material'

const NetworkBar = ({newSite, setNewSite}) => {


  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar variant="dense">
          <Typography variant="h6" color="inherit" component="div" sx={{ flexGrow: 1 }}>
            Network
          </Typography>
          <Button
            color="inherit"
            onClick={() => setNewSite(!newSite)}
            >
              Add New Site
          </Button>
        </Toolbar>
      </AppBar>
    </Box>
  );
}

export default NetworkBar