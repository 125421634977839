import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import WirelessDeviceCard from '../Components/Network/LTUDashboard/WirelessDeviceCard'
import WirelessDetailCard from '../Components/Network/LTUDashboard/WirelessDetailCard'
import { useGetDevice } from '../hooks'
import { Grid, Paper } from '@mui/material'


const PtpScreen = () => {
  const { id } = useParams()
  const { data } = useSelector(
    (state) => state.wsDevice
  )

  useGetDevice(id)

  return (
    <Paper sx={{ p: 1 }}>
      <Grid container direction={'column'}>
        {data && (
          <Grid item container direction={'row'}>
            <Grid item xs={6} sm={3}>
              <WirelessDeviceCard data={data} />
            </Grid>
          </Grid>
        )}
      </Grid>
    </Paper>

  )
}

export default PtpScreen