import * as React from 'react'
import Box from '@mui/material/Box'
import Tab from '@mui/material/Tab'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import SitesResults from './Sites'
import DevicesResults from './Devices'
import SubscriberResults from './Subscribers'

const SearchTabs = ({ tab, setTab, handleClose, sites, devices, subscribers }) => {

  const handleChange = (event, newValue) => {
    setTab(newValue)
  }

  return (
    <Box sx={{ width: '100%', typography: 'body1' }}>
      <TabContext value={tab}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange} centered aria-label="lab API tabs example">
            <Tab label="Sites" value="1" />
            <Tab label="Devices" value="2" />
            <Tab label="Subscribers" value="3" />
          </TabList>
        </Box>
        <TabPanel value="1"><SitesResults handleClose={handleClose} sites={sites} /></TabPanel>
        <TabPanel value="2"><DevicesResults handleClose={handleClose} devices={devices} /></TabPanel>
        <TabPanel value="3"><SubscriberResults handleClose={handleClose} subscribers={subscribers} /></TabPanel>
      </TabContext>
    </Box>
  )
}

export default SearchTabs