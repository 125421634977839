import { Link } from 'react-router-dom'
import {
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider
} from '@mui/material'




const SubscriberResults = ({ handleClose, subscribers }) => {


  return (
    <List>
      {subscribers?.map((el) => {
        return (
          <Link
            key={el._id}
            style={{ textDecoration: 'inherit', color: 'inherit' }}
            to={`/subscriber/${el._id}`}
            onClick={handleClose}>
            <ListItem alignItems='flex-start'>
              <ListItemText
                primary={
                  <Typography
                    sx={{ display: 'inline' }}
                    component='span'
                    variant='body2'
                    color='text.primary'>
                    {el.name}
                  </Typography>
                }
              />
            </ListItem>
            <Divider component='li' />
          </Link>
        )
      })}
    </List>
  )
}

export default SubscriberResults