import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  processes: [],
  queueStatus: []
}

export const wsServerStatusSlice = createSlice({
  name: 'serverstatus',
  initialState,
  reducers: {
    resetServerStatus: () => initialState,
    receiveServerStatus: (state, action) => {
      state.processes = action.payload.processes
      state.queueStatus = action.payload.queueStatus
    },
  },
})

export const { resetServerStatus, receiveServerStatus } = wsServerStatusSlice.actions

export default wsServerStatusSlice.reducer